import React, { useState, useCallback, useEffect,useRef } from "react";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import { LicenseInfo } from '@mui/x-license-pro';
import { useDispatch, useSelector } from "react-redux";
import { GraphqlApi } from 'services/api';
import { logout, renewToken } from "./actions/userActions";
import { SESSION_WARNING_TIMEOUT, TOKEN_EXPIRATION_MIN, } from "./constants/configConstants";
import Cookies from "js-cookie";
import TopBar from "components/TopBar";
import Login from "pages/Login";
import NotFound from "./pages/NotFound";
import LoginFail from "./pages/LoginFail";
import LoginOk from "./pages/LoginOk";
import Dashboard from "pages/Dashboard"
import EditModel from "pages/EditModel";
import RegionalDashboard from "pages/RegionalDashboard";
import EditRegional from "pages/EditModel/editRegional";
import FilesDashboard from "pages/Files";
import AdminPanelTab from "pages/AdminPanelTab";
import BtpPanel from "pages/BtpPanel";
import { FilePicker } from 'pages/Files/FilePicker';
import ConfirmationDialog from "components/ConfirmationDialog";
import PermanentDrawer from './components/PermanentDrawer/PermanentDrawer'
import ProtectedRoute from "./pages/ProtectedRoute";
import GenericSnackbar from "components/GenericSnackbar";
import "./App.css";

//Expires: Jun 29, 2024
LicenseInfo.setLicenseKey('7f2acf9100b7de3d3db5bbaa5490c8c7Tz02OTc0NixFPTE3MTk2NzczNzYzODMsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=');

function App() {
  let interval;
  let logoutTimer;
  const history = useHistory();
  const dispatch = useDispatch();
  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false);
  const [isExitDialogLoading, setIsExitDialogLoading] = useState(false);
  const [openTokenWarning, setOpenTokenWarning] = useState(false);
  const userSignin = useSelector((state) => state.user);
  const [userDetails, setUserDetails] = useState('');
  const [remainingSecondsToken, setRemainingSecondsToken] = useState("");

  /* Fetch userInfo and expiration from state when user is logged in (Comment out these lines for local development) */
  const userInfo = userSignin && userSignin.userInfo ? userSignin.userInfo : Cookies.getJSON("userInfo");
  const expires = Cookies.get("expires");

  /* Use default user info and expiration when user is not logged in (For local development only, update with your own credentials) */
  // const userInfo = { userEmail: "abc@hp.com", userName: "abc" }
  // const expires = 0;

  const secondsToTime = secs => ({ h: Math.floor(secs / 3600), m: Math.floor(secs / 60) % 60, s: secs % 60 });

  /* useEffect hook to fetch user data when user is trying to login */
  useEffect(() => {
    const excludedPaths = ["/","/loginok"];
    if (userInfo) {
      GraphqlApi.query('kbUser')
        .then((res) => {
          dispatch({ type: 'KB_USER_DATA', payload: res.data.kbUserWithRoles });
          setUserDetails(res.data.kbUserWithRoles);
        }).catch((err) => {
          const message = err?.message || 'Error getting user details.';
          dispatch({ type: "USER_LOGIN_FAIL", payload: message })
          history.push('/login', { loginError: message })
        });
    } else if (!excludedPaths.includes(window.location.pathname)) {
      window.location.pathname = "/";
    }
  }, [userInfo?.userEmail]);


  /* Function to manage warning dialog closure and execute user logout (Specifically for Token Expiration). */
  const handleWarningDialogClose = () => {
    setOpenTokenWarning(false);
    clearInterval(interval);
    window.location.pathname = "/";
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    dispatch(logout());
  };

  /* Function to handle the logout dialog open */
  const handleLogoutDialogOpen = useCallback(() => {
    setIsExitDialogOpen(true);
  }, [setIsExitDialogOpen]);

  /* Function to handle the logout dialog close */
  const handleLogoutDialogClose = useCallback(() => {
    setIsExitDialogOpen(false);
    setIsExitDialogLoading(false);
  }, [setIsExitDialogOpen, setIsExitDialogLoading]);

  /* Function to handle user logout */
  const handleLogout = useCallback(() => {
    setIsExitDialogLoading(true);
    dispatch(logout());
    handleLogoutDialogClose();
  }, [dispatch, setIsExitDialogLoading, handleLogoutDialogClose]);

  /* Function to handle token renewal and session continuation (Exclusively for Token Expiration). */
  const handleRenewToken = () => {
    dispatch(renewToken());
    setOpenTokenWarning(false);
    clearInterval(interval);
  };

  /* useEffect hook to set the timer for user notification about token expiration */
  useEffect(() => {
    const expiration = new Date(expires * 1000 - TOKEN_EXPIRATION_MIN * 60000);
    const remainingTime = expiration.getTime() - new Date().getTime();
    if (remainingTime > 0) {
      let tsec = SESSION_WARNING_TIMEOUT;
      logoutTimer = setTimeout(() => {
        setOpenTokenWarning(true);
        interval = setInterval(() => {
          const time = secondsToTime(--tsec);
          const displayTime = time.m > 0 ? `${time.m + " Minutes " + time.s + " Seconds"}` : `${time.s + " Seconds"}`;
          setRemainingSecondsToken(displayTime);
          if (displayTime === "0 Seconds") handleWarningDialogClose();
        }, 1000);

      }, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
    // Clear on dispose
    return () => {
      clearTimeout(logoutTimer);
    };
  }, [expires, dispatch]);

  return (
    <Router>
      <div className="pcr-container">
        {window.location.pathname !== '/' && window.location.pathname !== "/login" ?
          <TopBar logout={handleLogoutDialogOpen} userDetails={userDetails} /> : null}

        <main className={window.location.pathname === "/" || window.location.pathname === "/login" ? 'pcr-login-page' : 'pcr-main'}>
          {window.location.pathname === "/" || window.location.pathname === "/login" ? null : <PermanentDrawer userDetails={userDetails} />}
          <GenericSnackbar />

          <div className={"pcr-landing-content"}>
            <Switch>
              <Route path={["/", "/login"]} exact={true} component={Login} />
              <Route path="/loginok" exact={true} component={LoginOk} />
              <Route path="/loginfail" exact={true} component={LoginFail} />
              <ProtectedRoute
                exact
                path="/dashboard"
                component={Dashboard}
                auth={userDetails}
              />
              <ProtectedRoute
                exact
                path="/files"
                component={FilesDashboard}
                auth={userDetails}
              />
              <ProtectedRoute
                exact
                path="/btp"
                component={BtpPanel}
                auth={userDetails}
              />
              <ProtectedRoute
                exact
                path="/files/:kmatID/:tab"
                component={FilePicker}
                auth={userDetails}
              />
              <ProtectedRoute
                exact
                path="/regional"
                component={RegionalDashboard}
                auth={userDetails}
              />
              <ProtectedRoute
                exact
                path="/admin/:tab"
                component={AdminPanelTab}
                auth={userDetails}
              />
              <ProtectedRoute
                path={["/model/:kmatId/:version"]}
                dataType="model"
                component={EditModel}
                auth={userDetails}
              />
              <ProtectedRoute
                path={"/admin/templates/:kmatId"}
                dataType="template"
                component={EditModel}
                auth={userDetails}
              />
              <ProtectedRoute
                path={["/regional/:kmatId"]}
                dataType="regional"
                component={EditRegional}
                auth={userDetails}
              />
              <Route component={NotFound} />
            </Switch>
          </div>
        </main>
      </div>

      {/* Logout Dialog */}
      {isExitDialogOpen &&
        <ConfirmationDialog
          open={isExitDialogOpen}
          title="Confirmation"
          content="Are you sure you want to logout?"
          onClose={handleLogoutDialogClose}
          loading={isExitDialogLoading}
          onConfirm={handleLogout}
          caption="Yes, Logout"
          bgColor="#BE1313"
        />}

      {/* Warning Dialog for token expiration  */}
      {openTokenWarning &&
        <ConfirmationDialog
          open={openTokenWarning}
          title="Warning (Token Expiration)"
          content={<>Your session will expire in <b>{remainingSecondsToken}.</b><br />Please click on "Continue" If you want to retake the session.</>}
          onClose={handleRenewToken}
          loading={isExitDialogLoading}
          onConfirm={handleWarningDialogClose}
          caption="Sign out"
          secondaryCaption="Continue Session"
          bgColor="#BE1313"
        />}
    </Router>
  );
}

export default App;
