const EditDot = () => (
  <span
    style={{
      height: "6px",
      width: "6px",
      backgroundColor: "red",
      borderRadius: "50%",
      display: "inline-block",
      position: "relative",
      top: "-7px",
    }}
  ></span>
);

export default EditDot;
