import { getTab } from "pages/Utils";
import { defaultState } from "./modelReducer";
import { CVGMGMT_TAB } from "constants/modelConstants";

/**
 * Higher order reducer for resetting model state
 * note: can be extended for general use on any reducer
 */
export const undoReducer = (baseReducer) => (state, action) => {
	const initialState = baseReducer(undefined, { type: '@@INIT' });

	const undoInitialState = {
		...initialState,
		undo: {}
	};
	state = state === undefined ? undoInitialState : state;

	switch (action.type) {

		case "REMEMBER_PROPERTY": {
			const choiceID = action.payload;
			const { activeTab, activeCVGTab } = state;
			const tab = getTab(activeTab === CVGMGMT_TAB ? activeCVGTab : activeTab);

			if (!choiceID || !state[tab]?.[choiceID]) return state;
			if (state.undo[tab] && state.undo[tab][choiceID]?.choiceID) return state;

			const newState = { ...state };
			const prevState = newState.undo?.editedProps?.length ? newState.undo.editedProps : [];
			const editedProps = [
				...prevState,
				`${tab}::${choiceID}`
			]


			newState.undo = {
				...newState.undo,
				editedProps,
				[tab]: {
					...newState.undo[tab],
					[choiceID]: {
						...newState[tab][choiceID],
						items: JSON.parse(JSON.stringify(state[tab][choiceID].items))
					}
				}
			}

			return { ...newState };
		}

		case "UNDO_PROPERTY": {
			const { choiceID, itemID, bulkEditedChoices } = action.payload;
			const { activeTab, activeCVGTab } = state;
			const tab = getTab(activeTab === CVGMGMT_TAB ? activeCVGTab : activeTab);

			// handle the 'Cancel' button functionality in bulk edit mode
			if (bulkEditedChoices?.length) {
				const newState = { ...state };
				const restoredChoices = bulkEditedChoices.reduce((acc, choiceID) => {
					acc[choiceID] = { ...state.undo[tab][choiceID], items: { ...state[tab][choiceID].items } };
					return acc;
				}, {})

				return {
					...newState,
					[tab]: { ...newState[tab], ...restoredChoices },
					undoState: true
				}
			}

			// do nothing if property doesn't exist, but clear component state
			if (!state.undo[tab] || !state.undo[tab][choiceID]|| (itemID && !state.undo[tab][choiceID].items[itemID])) return { ...state, undoState: true };

			const newState = { ...state };

			if (itemID) {
				newState[tab][choiceID].items[itemID] = {...newState.undo[tab][choiceID].items[itemID]};
				 const editedNodes= state.editedNodes.filter((id) => id !== itemID);
				return {
					...newState,
					undoState: true,
					editedNodes
				};
			}

			newState[tab][choiceID] = {
				...newState.undo[tab][choiceID],
				items: { ...newState.undo[tab][choiceID].items }
			};
			const editedNodes= state.editedNodes.filter((id) => id !== choiceID);

			return {
				...newState,
				undoState: true,
				editedNodes
			};
		}

		case 'CANCEL_STATE_CLEAR': {
			return {
				...state,
				undoState: false
			}
		}

		case 'CLEAR_UNDO_STATE': {
			return {
				...state,
				undo: {},
				undoState: false,
			}
		}

		case 'CLEAR_MODEL_STATE': {
			return {
				...defaultState,
				undo: {},
				undoState: false,
			}
		}

		default:
			return baseReducer(state, action);
	}
};
