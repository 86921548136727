import React, { useState } from "react";
import { batch, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { createWs, fetchData, updateWorkspaceVerion, } from "actions/modelActions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Popover from "@mui/material/Popover";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router";
import { Collapse } from '@mui/material';
import { clearModelState } from 'actions/undoActions';
import ConfirmationDialog from "components/ConfirmationDialog";
import { useStyles } from "./ModelHeaderHelpers";

const ModelField = ({ currentVersion, header, versions, kmatID }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [versionToChanged, setVersionToChanged] = useState(null);
  const [openVersionChange, setOpenVersionChange] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);


  const performAction = (value, kmatID) => {
    history.push({ pathname: "/model/" + kmatID + "/" + value });
    batch(() => {
      dispatch(updateWorkspaceVerion(kmatID, value));
      dispatch(clearModelState());
    });
  };

  const handleVersionChange = (value) => {
    setVersionToChanged(value);
    setOpenVersionChange(true);
  };

  const handleConfirmCreateVersion = () => {
    setOpenCreateDialog(false);
    dispatch(createWs(kmatID, currentVersion, history));
  };

  const handleConfirmVersionChange = () => {
    setOpenVersionChange(false);
    performAction(versionToChanged, kmatID);
  };

  const closeCreateDialogPopup = () => { setOpenCreateDialog(false) }

  const closeVersionChangePopup = () => { setOpenVersionChange(false) };

  const handleClick = (event) => { setAnchorEl(event.currentTarget) };

  const handlePopClose = () => { setAnchorEl(null) };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Grid item xs={3} key={header.primaryText}>
      <Paper elevation={0} className={classes.paper}>
        <span className="custom-hidden-button" aria-describedby={id} onClick={(e) => handleClick(e)} />
        <ListItem>
          <ListItemAvatar>{header.avatarSrc}</ListItemAvatar>
          <ListItemText
            primary={header.primaryText}
            secondary={versions?.find(({ version }) => version === currentVersion)?.name}
          />
          <Grid>
            <Grid className={classes.createButton}>
              <KeyboardArrowDownIcon />
              {open &&
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                className={classes.popOver}
              >
                <MenuItem style={{ borderBottom: '1px solid gray' }}>
                  <Grid container justifyContent="space-between" alignItems="center" spacing={2} onClick={() => setOpenCreateDialog(true)}>
                    <Grid item > Create a new draft</Grid>
                    <Grid item><AddIcon /></Grid>
                  </Grid>
                </MenuItem>
                {versions?.filter(({ isPrev }) => !isPrev).map(({ version, name }, index) => (
                  <MenuItem value={version} key={`${index} ${version}`} onClick={() => handleVersionChange(version)} >
                    {name}
                  </MenuItem>
                ))}
                <MenuItem onClick={() => setIsOpen(!isOpen)} className={classes.menuItem}>
                  Unpublished Drafts
                  {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                </MenuItem>
                <List style={!isOpen ? { marginTop: '-16px' } : {}}>
                  <Collapse in={isOpen}>
                    {versions?.filter(({ isPrev }) => isPrev).map(({ version, name }, index) => (
                      <MenuItem value={version} key={`${index} ${name}`} onClick={() => handleVersionChange(version)} >
                        {name}
                      </MenuItem>
                    ))}
                  </Collapse>
                </List>
              </Popover>}
            </Grid>

            {openCreateDialog &&
              <ConfirmationDialog
                width={500}
                open={openCreateDialog}
                title="Create a new draft."
                content=" Are you sure if you are going to update any of the template data (Care packs/Family/MANDA/Product Line/SPARES)"
                onClose={closeCreateDialogPopup}
                onConfirm={handleConfirmCreateVersion}
                caption="+ Create"
                bgColor="#0278AB"
              />}

            {openVersionChange &&
              <ConfirmationDialog
                open={openVersionChange}
                title="Exit Version"
                content="Current Workspace is not saved. Are you sure to exit the version?"
                onClose={closeVersionChangePopup}
                onConfirm={handleConfirmVersionChange}
                caption="Confirm"
                bgColor="#0278AB"
              />}
              
          </Grid>
        </ListItem>
      </Paper>
    </Grid>
  );
};

export default ModelField;
