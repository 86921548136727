export { rerouteCachedPath, rerouteFromRoot, useRerouteRole } from './RerouteUtil';

export const getTab = (activeTab, key) => {
  const tabsMap = {
    PM: { alias: 'dataPM', isAddChoiceEnabled: true },
    Misc: { alias: 'dataMisc', isAddChoiceEnabled: false },
    CarePack: { alias: 'dataCarePack', isAddChoiceEnabled: true },
    CustomService: { alias: 'dataCustomService', isAddChoiceEnabled: true },
    Spares: { alias: 'dataSpares', isAddChoiceEnabled: false },
    Deploy: { alias: 'dataDeploy', isAddChoiceEnabled: true },
    MandA: { alias: 'dataMandA', isAddChoiceEnabled: true },
    Regional: { alias: 'dataRegional', isAddChoiceEnabled: false },
    WWNA: { alias: 'dataWWNA', isAddChoiceEnabled: false },
    WWLA: { alias: 'dataWWLA', isAddChoiceEnabled: false },
    WWEU: { alias: 'dataWWEU', isAddChoiceEnabled: false },
    WWAP: { alias: 'dataWWAP', isAddChoiceEnabled: false },
    NA: { alias: 'dataNA', isAddChoiceEnabled: false },
    LA: { alias: 'dataLA', isAddChoiceEnabled: false },
    EU: { alias: 'dataEU', isAddChoiceEnabled: false },
    AP: { alias: 'dataAP', isAddChoiceEnabled: false },
    NAGPSA: { alias: 'dataNAGPSA', isAddChoiceEnabled: false },
    WW: { alias: 'dataWW', isAddChoiceEnabled: false },
     // CVGMGMT : 'cvgData',
  };
  if (key)
    return tabsMap[activeTab] && tabsMap[activeTab][key];
  else // if no key is sent , return the alias by default
    return tabsMap[activeTab]?.alias;
}