import React, { useState, useEffect, useCallback } from 'react'
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid';
import { refreshFiles, filterFiles, toggleIsFavorite } from 'actions/filesActions';
import { Star, StarBorder } from "@mui/icons-material";
import ActionButtons from '../ActionButtons';
import RoundedPagination from 'pages/Pagination';
import { container, dataGridStyles, renderSelectHeader, renderTextfieldHeader } from 'pages/DashboardHelpers';

const columnTypes = {
    isFavorite: {
        type: 'boolean',
        name: 'isFavorite',
        matchFields: ['isFavorite'],
    },
    kmatID: {
        type: 'text',
        name: 'kmatID',
        matchFields: ['kmatID'],
    },
    productLineName: {
        type: 'select',
        name: 'productLineName',
        matchFields: ['productLineName'],
    },
    name: {
        type: 'text',
        name: 'name',
        matchFields: ['name'],
    },
};

const defineColumns = ({ dispatch, filters, handleChange, productLines }) => [
    {
        headerName: 'Favorite',
        field: 'isFavorite',
        headerClassName: 'favorite-column-header',
        align: 'center',
        width: 60,
        sortable: false,
        renderCell: (params) => {
            const { kmatID, isFavorite } = params.row;
            const tooltipTitle = isFavorite ? "Remove from favorite" : "Add to favorite";

            return (
                <Tooltip title={tooltipTitle} placement="top">
                    <IconButton aria-label={tooltipTitle.toLowerCase()} size="small"
                        onClick={(e) => { e.stopPropagation(); dispatch(toggleIsFavorite(kmatID, isFavorite)); }} sx={{ color: "#FFBF00" }} >
                        {isFavorite ? <Star /> : <StarBorder />}
                    </IconButton>
                </Tooltip>
            );
        },
        renderHeader: () => {
            const isFavoriteFilterValue = filters[columnTypes.isFavorite.name]?.value ?? false;

            return (
                <div className="header-children">
                    <IconButton
                        aria-label={isFavoriteFilterValue ? "remove fagorite filter" : "add favorite filter"}
                        size="small"
                        onClick={() => handleChange(columnTypes.isFavorite, !isFavoriteFilterValue)}
                        sx={{ color: "#FFBF00", marginTop: '30px' }}
                    >
                        {isFavoriteFilterValue ? <Star /> : <StarBorder />}
                    </IconButton>
                </div>
            );
        },
    },
    {
        headerName: 'KMAT ID',
        field: 'kmatID',
        headerClassName: 'dashboard-data-grid-headers',
        flex: 1,
        sortable: false,
        renderCell: (params) => {
            return (
                <div>
                    <Typography variant='subtitle2'>{params.row.kmatID}</Typography>
                </div>
            );
        },
        renderHeader: () => (renderTextfieldHeader(columnTypes.kmatID, 'KMAT ID', filters, handleChange))
    },
    {
        headerName: 'Name',
        field: 'name',
        headerClassName: 'dashboard-data-grid-headers',
        headerAlign: 'left',
        flex: 1,
        sortable: false,
        renderCell: (params) => {
            return (
                <div>
                    <Typography variant='subtitle2'>{params.row.name}</Typography>
                </div>
            );
        },
        renderHeader: () => (renderTextfieldHeader(columnTypes.name, 'Product Name', filters, handleChange))
    },
    {
        headerName: 'Product Line',
        field: 'productLineName',
        headerClassName: 'dashboard-data-grid-headers',
        flex: 1,
        sortable: false,
        renderCell: (params) => {
            return (
                <div>
                    <Typography variant='subtitle2'>{params.row.productLineName}</Typography>
                </div>
            );
        },
        renderHeader: () => renderSelectHeader(columnTypes?.productLineName, filters, ' Product Line', handleChange, productLines),
    },
]

function FilesDashboard() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10);

    const { filteredProducts, filters, isLoading, productLines } = useSelector(state => state.files.models && state.files)

    useEffect(() => {
        dispatch(refreshFiles());
    }, [dispatch]);

    const handleChange = useCallback((col, value) => {
        dispatch(filterFiles({ ...col, searchText: value }))
    }, [dispatch]);

    return (
        <Box sx={container}>
            <ActionButtons title="PCX Files" />
            <DataGrid
                columns={defineColumns({ dispatch, filters, handleChange, productLines })}
                rows={filteredProducts}
                onRowClick={(params) => history.push(`/files/${params.id}/production`)}
                getRowId={(row) => row.kmatID}
                loading={isLoading}
                rowHeight={48}
                headerHeight={80}
                className='dashboard-data-grid'
                disableSelectionOnClick
                getRowClassName={(params) => `${params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'} clickable`}
                headerClassName='dashboard-grid-headers'
                initialState={{ pagination: { paginationModel: { page: 0, pageSize: 15 } } }}
                components={{ Pagination: RoundedPagination }}
                sx={dataGridStyles}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 50]}
                disableColumnFilter
                disableColumnMenu
            />
        </Box>
    )
}

export default withRouter(FilesDashboard);
