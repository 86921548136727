//last Workspace
export const LASTWORKSPACE_FETCH_REQUEST = "LASTWORKSPACE_FETCH_REQUEST";
export const LASTWORKSPACE_FETCH_FAIL = "LASTWORKSPACE_FETCH_FAIL";
export const LASTWORKSPACE_FETCH_SUCCESS = "LASTWORKSPACE_FETCH_SUCCESS";

//CREATE Workspace
export const CREATE_WORKSPACE_REQUEST = "CREATEWORKSPACE_FETCH_REQUEST";
export const CREATE_WORKSPACE_FAIL = "CREATEWORKSPACE_FETCH_FAIL";
export const CREATE_WORKSPACE_SUCCESS = "CREATEWORKSPACE_FETCH_SUCCESS";

//Publish
export const PUBLISH_VERSION_REQUEST = "PUBLISH_VERSION_REQUEST";
export const PUBLISH_VERSION_SUCCESS = "PUBLISH_VERSION_SUCCESS";
export const PUBLISH_VERSION_FAIL = "PUBLISH_VERSION_FAIL";

//Publish Region
export const PUBLISH_REGION_REQUEST = "PUBLISH_REGION_REQUEST";
export const PUBLISH_REGION_SUCCESS = "PUBLISH_REGION_SUCCESS";
export const PUBLISH_REGION_FAIL = "PUBLISH_REGION_FAIL";

//Fetch Model/Template/Regional
export const FETCH_REQUEST = "FETCH_REQUEST";
export const FETCH_FAIL = "FETCH_FAIL";
export const FETCH_SUCCESS = "FETCH_SUCCESS";

//Add Choice/Item
export const ADD_CHOICE = "ADD_CHOICE";
export const ADD_ITEM = "ADD_ITEM";

export const TAB_SELECTION = "TAB_SELECTION";
export const CVG_TAB_SELECTION = "CVG_TAB_SELECTION";

export const SHOW_GENERIC_SNACKBAR = "SHOW_GENERIC_SNACKBAR";

export const RESET_TREE = "RESET_TREE";
export const CANCEL_INIT = "CANCEL_INIT";
export const SAVE_INIT = "SAVE_INIT";
export const SYNTAX_CHECK_REQUIRED = "SYNTAX_CHECK_REQUIRED";
export const SYNTAX_CHECK_DONE = "SYNTAX_CHECK_DONE";

// common tabs used for dataType === 'model' and dataType === 'models.
export const PM_TAB = "PM";
export const MISC_TAB = "Misc";
export const CAREPACK_TAB = "CarePack";
export const CUSTOMSERVICE_TAB = "CustomService";
export const MANDA_TAB = "MandA";
export const DEPLOY_TAB = "Deploy";
export const SPARES_TAB = "Spares";
export const CVGMGMT_TAB = "CVGMGMT";

// tab used for dataType === 'model'.
export const REGIONAL_TAB = "Regional";

// tabs used for dataType === 'regional'.
export const WWNA_TAB = "WWNA";
export const WWLA_TAB ="WWLA";
export const WWEU_TAB ="WWEU";
export const WWAP_TAB = "WWAP";
export const NA_TAB ="NA";
export const LA_TAB = "LA";
export const EU_TAB = "EU";
export const NAGPSA_TAB = "NAGPSA";
export const AP_TAB = "AP";
export const WW_TAB = "WW";

// Bulk Edit
export const DEFAULT_CONDITION = "defaultCondition";
export const FILTER_CONDITION = "filterCondition";
export const DEFAULT_CONDITION_BULK_EDIT = "defaultConditionBulkEdit";
export const FILTER_CONDITION_BULK_EDIT = "filterConditionBulkEdit";
export const REPLACE_DEFAULT_CONDITION = "replaceDefaultCondition";
export const REPLACE_FILTER_CONDITION = "replaceFilterCondition";

export const IS_ADD_CHOICE_ENABLED = "isAddChoiceEnabled";

export const modalTitle = {
  [DEFAULT_CONDITION]: 'Edit Default Condition',
  [FILTER_CONDITION]: 'Edit Filter Condition',
  [DEFAULT_CONDITION_BULK_EDIT]: 'Bulk Edit Default Condition',
  [FILTER_CONDITION_BULK_EDIT]: 'Bulk Edit Filter Condition',
  [REPLACE_DEFAULT_CONDITION]: 'Find And Replace Default Condition',
  [REPLACE_FILTER_CONDITION]: 'Find And Replace Filter Condition',
};

export const TABS = [
  { id: PM_TAB, title: "Hardware (PM)" },
  { id: MISC_TAB, title: "Miscellaneous" },
  { id: CAREPACK_TAB, title: "Care packs Services" },
  { id: CUSTOMSERVICE_TAB, title: "Configuration Services" },
  { id: MANDA_TAB, title: "Monitor And Accessories" },
  { id: DEPLOY_TAB, title: "Deployment Services" },
  { id: SPARES_TAB, title: "Spares" },
  { id: CVGMGMT_TAB, title: "CVG Management" },
  { id: REGIONAL_TAB, title: "Regional data" },
];

export const STEP_NAMES = TABS.map((tab) => tab.id);

export const REGIONAL_TABS = [
  { id: WWNA_TAB, title: WWNA_TAB },
  { id: WWLA_TAB, title: WWLA_TAB },
  { id: WWEU_TAB, title: WWEU_TAB },
  { id: WWAP_TAB, title: WWAP_TAB },
  { id: NA_TAB, title: NA_TAB },
  { id: LA_TAB, title: LA_TAB },
  { id: EU_TAB, title: EU_TAB },
  { id: AP_TAB, title: AP_TAB },
  { id: NAGPSA_TAB, title: NAGPSA_TAB },
  { id: CVGMGMT_TAB, title: "CVG Management" },
  { id: WW_TAB, title: "WW Data" },
];

export const STEP_NAMES_REGIONAL_TABS = REGIONAL_TABS.map((tab) => tab.id);

export const CVG_TABS = [
  { id: PM_TAB, title: "Hardware" },
  { id: MISC_TAB, title: "Miscellaneous" },
  { id: CAREPACK_TAB, title: "Care packs Services" },
  { id: CUSTOMSERVICE_TAB, title: "Configuration Services" },
  { id: MANDA_TAB, title: "Monitor And Accessories" },
  { id: DEPLOY_TAB, title: "Deployment Services" },
  { id: SPARES_TAB, title: "Spares" },
];

export const CVG_REGIONAL_TABS = [
  { id: WWNA_TAB, title: WWNA_TAB },
  { id: WWLA_TAB, title: WWLA_TAB },
  { id: WWEU_TAB, title: WWEU_TAB },
  { id: WWAP_TAB, title: WWAP_TAB },
  { id: NA_TAB, title: NA_TAB },
  { id: LA_TAB, title: LA_TAB },
  { id: EU_TAB, title: EU_TAB },
  { id: AP_TAB, title: AP_TAB },
  { id: NAGPSA_TAB, title: NAGPSA_TAB },
];

export const ITEMEDIT_LABELS = {
  [PM_TAB]: {
    ItemPartNumber: "Part#",
    VcItemID: "Value ID",
    ChoiceItemDesc: "Part Description (VC)",
    ItemRuleHint: "Config Rules & Base unit checks",
    ItemFilterCondition: "Filter Condition (When to offer for selection)",
    ItemDefaultCondition: "Default Condition (When auto selected)",
    ItemCvg: "CVG",
    ItemCounterCvg: "Counter CVG",
    DefaultComponentCondition: "Defaulting Component",
    DefaultComponentType: "Pre-selection Type",
  },
  [REGIONAL_TAB]: {
    PartNumber: "Part#",
    ValueId: "Value ID",
    Description: " Description (VC)",
    FilterCondition: "Filter Condition (When to offer for selection)",
    DefaultCondition: "Default Condition (When auto selected)",
    Cvg: "CVG",
    CounterCvg: "Counter CVG",
  },
  [MISC_TAB]: {
    ItemPartNumber: "Part#",
    VcItemID: "Value ID",
    ChoiceItemDesc: "Part Description (VC)",
    ItemRuleHint: "Config Rules & Base unit checks",
    ItemFilterCondition: "Filter Condition (When to offer for selection)",
    ItemDefaultCondition: "Default Condition (When auto selected)",
    ItemCvg: "CVG",
    ItemCounterCvg: "Counter CVG",
    DefaultComponentCondition: "Defaulting Component",
    DefaultComponentType: "Pre-selection Type",
  },
  [CAREPACK_TAB]: {
    ItemPartNumber: "Part#",
    VcItemID: "Value ID",
    ChoiceItemDesc: "Part Description (VC)",
    ItemRuleHint: "Config Rules",
    ItemFilterCondition: "Filter Condition (When to offer for selection)",
    ItemDefaultCondition: "Default Condition (When auto selected)",
    ItemCvg: "CVG",
    ItemCounterCvg: "Counter CVG",
    DefaultComponentCondition: "Defaulting Component",
    DefaultComponentType: "Pre-selection Type",
  },
  [CUSTOMSERVICE_TAB]: {
    ItemPartNumber: "Item Part#",
    VcItemID: "Value ID",
    ChoiceItemDesc: "SAP description (30 characters max)",
    ItemRuleHint: "Config Rules",
    ItemFilterCondition: "Filter Condition (When to offer for selection)",
    ItemDefaultCondition: "Default Condition (When auto selected)",
    ItemCvg: "CVG",
    ItemCounterCvg: "Counter CVG",
    DefaultComponentCondition: "Defaulting Component",
    DefaultComponentType: "Pre-selection Type",
  },
  ACC: {
    ItemPartNumber: "Part#",
    VcItemID: "Value ID",
    ChoiceItemDesc: "Part Description (VC)",
    ItemRuleHint: "Config Rules",
    ItemFilterCondition: "Filter Condition (When to offer for selection)",
    ItemDefaultCondition: "Default Condition (When auto selected)",
    ItemCvg: "CVG",
    ItemCounterCvg: "Counter CVG",
    DefaultComponentCondition: "Defaulting Component",
    DefaultComponentType: "Pre-selection Type",
  },
  [DEPLOY_TAB]: {
    ItemPartNumber: "Part#",
    VcItemID: "Value ID",
    ChoiceItemDesc: "Part Description (VC)",
    ItemRuleHint: "Config Rules",
    ItemFilterCondition: "Filter Condition (When to offer for selection)",
    ItemDefaultCondition: "Default Condition (When auto selected)",
    ItemCvg: "CVG",
    ItemCounterCvg: "Counter CVG",
    DefaultComponentCondition: "Defaulting Component",
    DefaultComponentType: "Pre-selection Type",
  },
  [SPARES_TAB]: {
    ItemPartNumber: "Part#",
    VcItemID: "Value ID",
    ChoiceItemDesc: "Part Description (VC)",
    ItemRuleHint: "Config Rules",
    ItemFilterCondition: "Filter Condition (When to offer for selection)",
    ItemDefaultCondition: "Default Condition (When auto selected)",
    ItemCvg: "CVG",
    ItemCounterCvg: "Counter CVG",
    DefaultComponentCondition: "Defaulting Component",
    DefaultComponentType: "Pre-selection Type",
  },
  [MANDA_TAB]: {
    ItemPartNumber: "Part#",
    VcItemID: "Value ID",
    ChoiceItemDesc: "Part Description (VC)",
    ItemRuleHint: "Config Rules",
    ItemFilterCondition: "Filter Condition (When to offer for selection)",
    ItemDefaultCondition: "Default Condition (When auto selected)",
    ItemCvg: "CVG",
    ItemCounterCvg: "Counter CVG",
    DefaultComponentCondition: "Defaulting Component",
    DefaultComponentType: "Pre-selection Type",
  },
};

// export const ROUTE_CAPTIONS = [
//   { name: "dashboard", caption: "Dashboard", isPassQueryParams: false },
//   { name: "modelsearch", caption: "Model Search", isPassQueryParams: true },
//   {
//     name: "productline",
//     caption: "Product Line Selection",
//     isPassQueryParams: false,
//   },
//   { name: "loginfail", caption: "Login Failed", isPassQueryParams: false },
// ];
