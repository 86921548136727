import { React } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText"
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import ModelField from "./ModelField";
import productLine from "assets/i/productLine.svg";
import version from "assets/i/version.svg";
import Img from "components/Img";
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import WorkSpaceButton from "components/WorkSpaceButtons/WorkSpaceButton";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { retrieveBackgroundColor } from "pages/Dashboard/columnsConfig";
import { useStyles } from "./ModelHeaderHelpers";
import WorkSpaceButtonRegional from "components/WorkSpaceButtons/WorkSpaceButtonRegional";

const KmatBox = (props) => {
  const { kmat, dataType, currentVersion, versions, activeTab } = props;
  const classes = useStyles();
  const kmatStatus = kmat.status;
  const publishedVersion = currentVersion?.split(".")[1] === "1";


  const headerCard = [
    {
      primaryText: "Product Line",
      secondaryText: kmat?.lineName,
      avatarSrc: <Img src={productLine} className={classes.cardView} />,
      show: true,
      isClickable: false
    },
    {
      primaryText: "Product Name",
      secondaryText: kmat.productName,
      avatarSrc: (
        <DesktopWindowsOutlinedIcon htmlColor="#0278AB" className={classes.cardView} />
      ),
      show: true,
      isClickable: false,
    },
    {
      primaryText: "KMAT-ID",
      secondaryText: kmat.kmatID,
      avatarSrc: (
        <GradeOutlinedIcon htmlColor={kmat.isReleased ? "#0278AB" : "green"} className={classes.cardView} />
      ),
      show: dataType !== "template",
      isClickable: false,
    },
    {
      primaryText: "PM File",
      secondaryText: kmat.pmFile,
      avatarSrc: <DescriptionOutlinedIcon htmlColor="#0278AB" fontSize='large' className={classes.cardView} />,
      customGrid: 3,
      show: dataType !== 'regional',
      isClickable: false,
    },
    {
      primaryText: "Version",
      avatarSrc: <Img src={version} className={classes.cardView} />,
      renderComponent: (header) => (
        <ModelField
          key={"version"}
          caption="Version"
          currentVersion={currentVersion}
          header={header}
          kmatID={kmat.kmatID}
          versions={versions}
        />
      ),
      show: dataType === 'model',
      isClickable: true,
    },
  ];

  return (
    <div className={classes.root}>
      <Grid container className={classes.gridContainer}>
        <Grid item className={classes.gridItem}>
          <Typography variant="h5" className={classes.title}>{dataType === 'template' ? "Template" : "KB Model"} {kmat.kmatID}</Typography>
          {kmatStatus &&
            <Chip
              size="small"
              style={{
                background: retrieveBackgroundColor(kmatStatus),
                color: ['Updates available', 'In progress', 'Published'].includes(kmatStatus) ? '#ffffff' : 'inherit',
                width: 'fit-content'
              }}
              label={kmatStatus}
            />
          }
          {publishedVersion ? (
            <Chip label="Production Draft" color="success" size="small" />
          ) : (
            <Chip label="Draft Version" className={classes.chipColor} size="small" />
          )}
        </Grid>
        {dataType === "regional" ? <WorkSpaceButtonRegional activeTab={activeTab} kmatId={kmat.kmatID} /> :
          <WorkSpaceButton kmatId={kmat.kmatID} kmat={kmat} dataType={dataType} currentVersion={currentVersion} />}
      </Grid>
      <Grid container spacing={3}>
        {headerCard.map((header, index) => {
          return (
            header.show && (
              header.isClickable
                ?
                header.renderComponent(header)
                :
                <Grid item xs={header.customGrid || 2} key={index + "-grid-element"}>
                  <Paper elevation={0} className={`${classes.paper} ${header.isClickable && 'paper-click-element'}`} style={header.isClickable ? { cursor: 'pointer' } : {}}>
                    <ListItem>
                      <ListItemAvatar>{header.avatarSrc}</ListItemAvatar>
                      <ListItemText
                        primary={header.primaryText}
                        secondary={header.secondaryText}
                      />
                    </ListItem>
                  </Paper>
                </Grid>
            )
          );
        })}
      </Grid>
    </div>
  );
};

export default KmatBox;
