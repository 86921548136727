import { useState, useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useGlobalStore } from 'hooks/useGlobalStore';
import { updateChoiceItemFields } from 'actions/modelActions';
import ErrorToolTip from "../../Common/ErrorToolTip";
import { getTab } from "pages/Utils";
import { useSelector } from 'react-redux';
import ConfirmationDialog from "components/ConfirmationDialog";
import { useStyles } from "./styles";

const defaultMessages = { condition: "", description: "" };

export default function Message({ choiceID, itemID, tabName }) {
  const classes = useStyles();
  const dataTabName = getTab(tabName);
  const choiceData = useSelector(state => state.models)[dataTabName][choiceID];
  const invalidConditions = useSelector(state =>
    itemID
      ? state.models.syntaxCheck[dataTabName][choiceID]?.items[itemID]?.MessageCondition
      : state.models.syntaxCheck[dataTabName][choiceID]?.MessageCondition
  );

  const choiceDataString = JSON.stringify(choiceData);
  const getMessagesSource = useMemo(() => {
    const choiceDataObject = JSON.parse(choiceDataString);
    const fromStore = itemID ? choiceDataObject.items[itemID] : choiceDataObject;
    return fromStore?.messages?.length ? fromStore : { messages: [defaultMessages] }
  }, [choiceDataString, itemID]);

  const [itemWithMessages, setMessages] = useGlobalStore(
    getMessagesSource,
    updateChoiceItemFields,
    { choiceID, itemID, tabCategory: tabName }
  );

  const [deleteIndex, setDeleteIndex] = useState(null);

  const handleModalClose = () => setDeleteIndex(null);

  const handleClickDelete = () => {
    setMessages((prevState) => ({ ...prevState, messages: prevState.messages.filter((_, i) => i !== deleteIndex), edited: true }));
    handleModalClose();
  };

  const handleUpdateMsg = useCallback((e, idx, type) => {
    setMessages((prevState) => ({ ...prevState, messages: prevState.messages.map((message, index) => index === idx ? { ...message, [type]: e.target.value } : message), edited: true }));
  }, [setMessages])

  const messagesLength = itemWithMessages?.messages?.length > 1;
  const msgToDelete = itemWithMessages?.messages[deleteIndex]?.condition;

  return (
    <>
      <Grid>
        <Box className={classes.root}>

          {itemWithMessages?.messages?.map((msg, index) => {
            const messageCondition = getMessagesSource.messages[index]?.condition || msg.condition;

            return (<Box key={index}>
              <TextField
                className={classes.messageWidth}
                variant="filled"
                margin="dense"
                multiline
                required
                label="Message Condition"
                placeholder="Message Condition"
                value={msg.condition}
                onChange={(e) => handleUpdateMsg(e, index, 'condition')}
                InputProps={{
                  classes: { input: classes.textarea },
                  disableUnderline: true
                }}
              />
              {(msg.condition && msg.description) && messagesLength && <DeleteIcon className={classes.deleteIconButton} style={{ cursor: 'pointer' }} onClick={() => { setDeleteIndex(index) }} />}
              {invalidConditions?.[messageCondition] && (<ErrorToolTip data={invalidConditions[messageCondition]} />)}
              <TextField
                className={classes.messageWidth}
                variant="filled"
                margin="dense"
                required
                multiline
                label="Message Description"
                placeholder="Message Description"
                value={msg.description}
                onChange={(e) => handleUpdateMsg(e, index, 'description')}
                InputProps={{
                  classes: { input: classes.textarea },
                  disableUnderline: true
                }}
              />
            </Box>
          )})}

          <Button
            variant="text"
            
            className={classes.addMsgButton}
            onClick={() => setMessages((prevState) => ({...prevState, messages: [...prevState.messages, defaultMessages], edited: true }))}
          >
            <AddIcon />
            Add New Message
          </Button>
        </Box>
        {deleteIndex !== null &&
          <ConfirmationDialog
            open={deleteIndex !== null}
            title="Delete Message"
            content={<>You are about to delete "<b>{msgToDelete}</b>" message condition.<br /> Are you sure you want to delete it?</>}
            onClose={handleModalClose}
            onConfirm={handleClickDelete}
            caption="Delete"
            bgColor="#BE1313"
          />}
      </Grid>
    </>
  );
}
