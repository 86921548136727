export const updateRegionalItem = `mutation($model : UpdateItemsRequest!){
    updateRegionalItems(input : $model) {
        kmatID
        modVer
        itemID
        partDescription
        filterCondition
        defaultCondition
        counterCvg
        cvg
        defaultComponents
        messages
        obsoleteOn
        tabCategory
      }
  }`
