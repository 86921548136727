import { useSelector } from 'react-redux';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ITEMEDIT_LABELS } from 'constants/modelConstants';
import ErrorToolTip from 'pages/Common/ErrorToolTip';
import StatusChip from '../ChoiceEdit/StatusChip';
import DuplicatePartDescriptionsTooltip from '../DuplicatePartDescriptionsTooltip';
import { updateChoiceItemFields } from 'actions/modelActions';
import useGlobalStore from 'hooks/useGlobalStore';
import { getTab } from 'pages/Utils';
import { useStyles } from './styles';
import { useAutoFocus } from '../IndexHelpers';
import { useRef } from 'react';


const ItemSettings = ({ itemID, choiceID, tabName }) => {
  const classes = useStyles();

  const dataTabName = getTab(tabName);
  let data = useSelector(state => state.models[dataTabName][choiceID]?.items[itemID])
  const invalidConditions = useSelector(state => state.models.syntaxCheck[dataTabName][choiceID]?.items[itemID])
  const [itemData, setItemData] = useGlobalStore(data, updateChoiceItemFields, { choiceID, itemID, tabCategory: tabName });
  const statusChip = itemData?.status && JSON.parse(itemData.status)?.status;

  const invalidFilterConditions = invalidConditions?.FilterCondition;
  const invalidDefaultConditions = invalidConditions?.DefaultCondition;
  const invalidCVGConditions = invalidConditions?.CounterCVG;
  const descriptionLengtErrors = invalidConditions?.descriptionLengtErrors;

  const textFieldRef = useRef(null);
  useAutoFocus(textFieldRef);

  const handleOnChange = (event, field) => {
    let newVal;
    if (event.target?.value?.startsWith('\n')) 
      return;
    if (field === 'obsoleteOn') {
      newVal = event;
    }
    else {
      newVal = event.target.value;
    }

    if (field === 'partDescription' && newVal.length > 250) {
      newVal = newVal.slice(0, 250);
    }

    setItemData((prevData) => ({
      ...prevData,
      [field]: newVal,
      edited: true
    }));
  };
  const partDescriptionLength = itemData?.isSystemChoice ? 0 : itemData?.partDescription?.length;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={3}>
        <TextField
          disabled
          label={ITEMEDIT_LABELS[tabName]["ItemPartNumber"]}
          id="ItemPartNumber"
          variant="filled"
          margin="dense"
          InputProps={{ disableUnderline: true }}
          value={itemData?.partNumber || ""}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          disabled
          label={ITEMEDIT_LABELS[tabName]["VcItemID"]}
          id="VcItemID"
          variant="filled"
          margin="dense"
          InputProps={{ disableUnderline: true }}
          value={itemData?.isSystemChoice ? "" : itemData?.valueID}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="ChoiceItemDesc"
          variant="filled"
          margin="dense"
          label={ITEMEDIT_LABELS[tabName]["ChoiceItemDesc"]}
          InputProps={{ disableUnderline: true, classes: { input: classes.disabledFilledInput } }}
          disabled={itemData?.isSystemChoice}
          value={itemData?.isSystemChoice ? "" : itemData?.partDescription}
          onChange={(event) => handleOnChange(event, "partDescription")}
        />
        <div className={classes.partDesc}>
          <Typography><DuplicatePartDescriptionsTooltip itemData={itemData} /></Typography>
                  <Typography>
                      <span style={{ size: "smaller", color: !itemData?.isSystemChoice && (descriptionLengtErrors || itemData?.partDescription?.length > 30) ? "red" : "inherit" }}>{partDescriptionLength} chars.</span>
                  </Typography>
        </div>
      </Grid>
      <Box className={classes.subtitle}>Config Rules & Base unit checks
        <StatusChip data={itemData} />
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            disabled
            margin="dense"
            id="ItemRuleHint"
            variant="filled"
            multiline
            placeholder="Config Rules & Base unit checks"
            label="Config Rules & Base unit checks"
            InputProps={{
              classes: { input: classes.textarea },
              disableUnderline: true
            }}
            value={itemData?.configRules || ""}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography className={classes.subtitle}> Filter Condition</Typography>
          <TextField
            inputRef={textFieldRef}
            id="ItemFilterCondition"
            variant="filled"
            margin="dense"
            multiline
            minRows={3}
            label={ITEMEDIT_LABELS[tabName]["ItemFilterCondition"]}
            placeholder="FilterCondition"
            InputProps={{
              classes: { input: classes.textarea },
              disableUnderline: true
            }}
            autoFocus= {true}
            value={itemData?.filterCondition || ""}
            onChange={(event) => handleOnChange(event, "filterCondition")}
          />
          {invalidFilterConditions?.length > 0 && (<ErrorToolTip data={invalidFilterConditions} />)}
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.subtitle}> Default Condition</Typography>
          <TextField
            id="ItemDefaultCondition"
            variant="filled"
            margin="dense"
            minRows={3}
            label={ITEMEDIT_LABELS[tabName]["ItemDefaultCondition"]}
            placeholder="DefaultCondition"
            multiline
            InputProps={{
              classes: { input: classes.textarea },
              disableUnderline: true
            }}
            disabled={itemData?.isSystemChoice}
            value={itemData?.defaultCondition || ""}
            onChange={(event) => handleOnChange(event, "defaultCondition")}
          />
          {invalidDefaultConditions?.length > 0 && (<ErrorToolTip data={invalidDefaultConditions} />)}
        </Grid>
      </Grid>
      <Typography className={classes.subtitle}> CVG</Typography>
      <Grid container>
        <Grid item xs={6}>
          <TextField
            disabled
            id="ItemCvg"
            variant="filled"
            margin="dense"
            label={ITEMEDIT_LABELS[tabName]["ItemCvg"]}
            placeholder="CVG"
            multiline
            InputProps={{
              classes: { input: classes.textarea },
              disableUnderline: true,
            }}
            value={itemData?.cvg || ""}
            onChange={(event) => handleOnChange(event, "cvg")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="ItemCounterCvg"
            variant="filled"
            margin="dense"
            disabled={itemData?.isSystemChoice}
            label={ITEMEDIT_LABELS[tabName]["ItemCounterCvg"]}
            placeholder="CounterCVG"
            multiline
            InputProps={{
              classes: { input: classes.textarea },
              disableUnderline: true
            }}
            value={itemData?.counterCvg || ""}
            onChange={(event) => handleOnChange(event, "counterCvg")}
          />
          <Typography className={classes.subtitle}>
            <span style={{ marginTop: "3px" }}>{invalidCVGConditions?.length > 0 && (<ErrorToolTip data={invalidCVGConditions} />)}</span>
          </Typography>
        </Grid>
      </Grid>

      {statusChip === "obsolete" && (
        <Grid item xs={3}>
          <Typography className={classes.subtitle}>Obsolete Item</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Removing Date"
              variant="outlined"
              InputProps={{ disableUnderline: true }}
              value={itemData?.obsoleteOn}
              onChange={event => handleOnChange(event, 'obsoleteOn')}
              renderInput={(params) => (
                <TextField {...params} variant="filled" margin="dense" />
              )}
            />
          </LocalizationProvider>
        </Grid>
      )}
    </Grid>
  );
};

export default ItemSettings;
