import {
  LASTWORKSPACE_FETCH_REQUEST,
  LASTWORKSPACE_FETCH_FAIL,
  LASTWORKSPACE_FETCH_SUCCESS,
  CREATE_WORKSPACE_REQUEST,
  CREATE_WORKSPACE_SUCCESS,
  PUBLISH_VERSION_REQUEST,
  PUBLISH_VERSION_SUCCESS,
  PUBLISH_VERSION_FAIL,
  CREATE_WORKSPACE_FAIL,
  TAB_SELECTION,
  SHOW_GENERIC_SNACKBAR,
  PUBLISH_REGION_SUCCESS,
  PUBLISH_REGION_FAIL,
  PUBLISH_REGION_REQUEST,
  CANCEL_INIT,
  SYNTAX_CHECK_REQUIRED,
  SYNTAX_CHECK_DONE,
  CVG_TAB_SELECTION,
  CVGMGMT_TAB,
  PM_TAB,
  WWNA_TAB,
  RESET_TREE,
  SAVE_INIT,
  FETCH_REQUEST,
  FETCH_FAIL,
  FETCH_SUCCESS,
  ADD_CHOICE,
  ADD_ITEM,
} from "constants/modelConstants";
import {
  CONTENT_KEY,
  getActiveDataSet,
  getInitialSyntaxCheck,
  mapToChoiceConditionNames,
  mapToItemConditionNames,
  mapToItemDescriptionNames,
  processSavedChoice,
  processSavedItem
} from "./modelUtils";
import { getTab } from "pages/Utils";

export const defaultState = {
  activeCVGTab: PM_TAB,
  activeTab: PM_TAB,
  cancelInit: false,
  currentVersion: null,
  dataType: '', // 'model', 'templates', 'regional'
  deletedChoice: { title: "" },
  genericSnackbarMessage: "",
  isResetTree: false,
  kmat: null, // master data for the selected model
  kmatId: null, // KMAT_ID
  lastWorkspace: null,
  loading: false,
  modVer: null,
  publishLoading: false,
  saveInit: false,
  showGenericSnackbar: false,
  showSnackbar: false,
  snackbarSeverity: "",
  syntaxCheck: { ...getInitialSyntaxCheck()},
  syntaxCheckDone: false,
  syntaxCheckRequired: false,
  validateWorkspace: null,
  editedNodes: [],
  newlyCreatedChoiceOrItem: {},
  isErrorOnValidate: {},
  selectedItem :{},
  expandActions: {},
};

const tabsData = {
  PM: "dataPM",
  Misc: "dataMisc",
  CarePack: "dataCarePack",
  CustomService: "dataCustomService",
  MandA: "dataMandA",
  Deploy: "dataDeploy",
  Spares: "dataSpares",
  Regional: "dataRegional",
  WWNA: 'dataWWNA',
  WWLA: 'dataWWLA',
  WWEU: 'dataWWEU',
  WWAP: 'dataWWAP',
  NA: 'dataNA',
  LA: 'dataLA',
  EU: 'dataEU',
  AP: 'dataAP',
  NAGPSA: 'dataNAGPSA',
  WW: 'dataWW',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    // TODO Remove deprecated LAST_WORKSPACE and refactor the logic.
    case LASTWORKSPACE_FETCH_REQUEST:
      return { ...state, loading: true, error: null };
       // TODO Remove deprecated LAST_WORKSPACE and refactor the logic.
    case LASTWORKSPACE_FETCH_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
        lastWorkspace: action.payload.data,
        currentVersion: action.payload.data,
        kmatId: action.payload.kmat,
      };
       // TODO Remove deprecated LAST_WORKSPACE and refactor the logic.
    case LASTWORKSPACE_FETCH_FAIL:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    case CREATE_WORKSPACE_REQUEST:
      return { ...state, loading: true, error: null };
    case CREATE_WORKSPACE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case PUBLISH_VERSION_REQUEST:
      return { ...state, loading: false, error: null, publishLoading: true };
    case PUBLISH_VERSION_SUCCESS:
      return {
        ...state,
        loading: false,
        publishLoading: false,
        error: null,
        modVer: action.payload.data,
        validateWorkspace: action.payload.clearValidation,
        currentVersion: action.payload.data,
      };
    case PUBLISH_VERSION_FAIL:
      return { ...state, loading: false, error: null, publishLoading: false };

    case CREATE_WORKSPACE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        modVer: action.payload.data,
        currentVersion: action.payload.data,
        kmatId: action.payload.kmat,
      };  

    case FETCH_REQUEST:
      return { ...state, loading: true, error: null };
    case CVG_TAB_SELECTION:
      return {
        ...state,
        activeCVGTab: action.payload,
      };

    case FETCH_SUCCESS:
      const processedDataset = getActiveDataSet(state, action);
      const { dataType, desc, kmat } = action.payload;
      return {
        ...state,
        importModel: false,
        loading: false,
        desc: desc,
        kmat: kmat,
        //activeTab: payload.activeTab,
        dataType: dataType,
        activeCVGTab: dataType === "regional" ? WWNA_TAB : PM_TAB,
        ...processedDataset,
      };

    case FETCH_FAIL:
      return {
        ...state,
        importModel: false,
        loading: false,
        dataPM: null,
        error: action.payload,
      };

    case TAB_SELECTION: {
      return {
        ...state,
        activeTab: action.payload,
        newlyCreatedChoiceOrItem: {},
      };
    }

    case SHOW_GENERIC_SNACKBAR: {
      return {
        ...state,
        showGenericSnackbar: action.payload.showGenericSnackbar,
        genericSnackbarMessage: action.payload.genericSnackbarMessage,
        snackbarSeverity: action.payload.snackbarSeverity,
      };
    }
    case PUBLISH_REGION_REQUEST:
      return { ...state, loading: false, error: null };
    case PUBLISH_REGION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        validateWorkspace: action.payload.clearValidation,
      };
    case PUBLISH_REGION_FAIL:
      return { ...state, loading: false, error: action.payload };

    case "DELETE_ITEM": {
      const { choiceID, itemID } = action.payload
      const data = { ...state[getTab(state.activeTab)][choiceID] };
      const { [itemID]: deletedItem, ...remainingItems } = data.items;

      if (data && data.items[itemID]) {
        delete data.items[itemID];

        return {
          ...state,
          [getTab(state.activeTab)]: {
            ...state[getTab(state.activeTab)],
            [choiceID]: {
              ...data,
              items: remainingItems,
            },
          },
          deletedChoice: { ...state.deletedChoice, title: action.payload.itemID },
        };
      }

      return state;
    }

    case "DELETE_CHOICE": {
      const choiceID = action.payload;
      const { [choiceID]: deletedChoice, ...remainingChoices } = state[tabsData[state.activeTab]];
      // TODO: save changes to undo
      // deletedChoices: { }

      return {
        ...state,
        [tabsData[state.activeTab]]: { ...remainingChoices },
        deletedChoice: { ...state.deletedChoice, title: action.payload },
      };
    }

    case RESET_TREE: {
      return {
        ...state,
        isResetTree: !state.isResetTree,
        selectedItem : {},
        expandActions: {}
      }
    }

    case "WORKSPACE_CHOICE_SAVE_SUCCESS": {
      return {
        ...state,
        saveInit: false,
      };
    }

    case 'UPDATE_ITEM_FIELDS': {
      const [fields, { choiceID, itemID, tabCategory }] = action.payload
      const tab = getTab(tabCategory);
      let editedNodes = [ ...state.editedNodes ];
      if (fields && fields.edited) {
        editedNodes.push(itemID || choiceID);
      };
      // in bulk edit mode 'fields' is an array of 'choices'. Each 'choice' has a property 'items': array of edited items; 
      if (Array.isArray(fields)) {
        const newState = { ...state };

        fields.forEach((choice) => {
          const { items, ...rest } = choice;
          const itemsObject = items.reduce((obj, item) => {
            obj[item.itemID] = { ...item, isChecked: Boolean(state[tab][choice.choiceID].items[item.itemID].isChecked) };
            return obj
          }, {})

          newState[tab][choice.choiceID] = {
            ...rest,
            items: { ...newState[tab][choice.choiceID].items, ...itemsObject },
            isChecked: Boolean(state[tab][choice.choiceID].isChecked),
            isExpanded: Boolean(state[tab][choice.choiceID].isExpanded),
          } 
        });

        return {
          ...newState
        };
      }

      if (!choiceID || !fields || !state[tab]?.[choiceID]) return state;

      if (itemID) {
        const newChoiceState = { ...state };
        if (itemID === state.deletedChoice.title) {
          return {
            ...state,
          };

        }

        newChoiceState[tab][choiceID].items[itemID] = { 
          ...newChoiceState[tab][choiceID].items[itemID], 
          ...fields, 
          isChecked: Boolean(state[tab][choiceID].items[itemID]?.isChecked) }

        return {
          ...newChoiceState,
          editedNodes
        }
      }

      const { items, ...restFields } = fields;
      const updatedItems = Object.keys(items ?? {}).reduce((acc, itemID) => {
        acc[itemID] = { ...items[itemID], isChecked: Boolean(state[tab][choiceID].items[itemID]?.isChecked) };
        return acc;
      }, {});

      return {
        ...state,
        [tab]: {
          ...state[tab],
          [choiceID]: { 
            ...state[tab][choiceID], 
            ...restFields, 
            items: {  ...state[tab][choiceID]?.items, ...updatedItems},
            isChecked: Boolean(state[tab][choiceID].isChecked),
            isExpanded: Boolean(state[tab][choiceID].isExpanded) 
          }
        },
        editedNodes
      }
    }

    case CANCEL_INIT: {
      return {
        ...state,
        cancelInit: action.payload,
      }
    }

    case SAVE_INIT: {
      return {
        ...state,
        saveInit: action.payload,
      };
    }
   
    case "SAVE_SUCCESS": {
      const choicesWithUpdatedCVGs = [];
      const isOnCVGTab = state.activeTab === CVGMGMT_TAB;
      const { updatedChoices, updatedItems } = action.payload;
      const updatedState = { ...state };

      updatedChoices?.forEach(choice => {
        const { tabCategory, choiceID} = choice;
        const modelDataTab = getTab(tabCategory);
        const { description, status, configRules } = state[modelDataTab][choiceID];

        updatedState[modelDataTab][choiceID] = {
          ...state[modelDataTab][choiceID],
          ...processSavedChoice(choice, { description, status, configRules }, state.dataType ),
          edited: false,
        };
      });

      updatedItems?.forEach(item => {
        const { tabCategory, itemID } = item;
        const modelDataTab = getTab(tabCategory);
        const parentId = Object.values(state[modelDataTab]).find(choice => choice.items[itemID])?.choiceID;
        const { description, partNumber, status, region } = state[modelDataTab][parentId].items[itemID];

        if (parentId) {
          updatedState[modelDataTab][parentId].items[itemID] = {
            ...state[modelDataTab][parentId].items[itemID],
            ...processSavedItem(item, { description, partNumber, status, region }),
            edited: false,
          };

          if (isOnCVGTab && choicesWithUpdatedCVGs.every((choice) => choice.choiceID !== parentId && choice.modelDataTab !== modelDataTab)) {
            choicesWithUpdatedCVGs.push({ choiceID: parentId, modelDataTab: modelDataTab });
          } 
        }
      });

      choicesWithUpdatedCVGs.forEach(({ choiceID, modelDataTab }) => 
        updatedState[modelDataTab][choiceID].allowedCVGs = 
          [...new Set(Object.values(updatedState[modelDataTab][choiceID].items).map((item) => item.cvgs).flat())].sort().join(',')
      );

      return {
        ...updatedState,
        syntaxCheckRequired: true,
        editedNodes: [],
      };
    }
    
    case SYNTAX_CHECK_REQUIRED: {
      return {
        ...state,
        syntaxCheckRequired: action.payload,
      }
    }

    case SYNTAX_CHECK_DONE: {
      return {
        ...state,
        syntaxCheckDone: action.payload,
      }
    }

    case ADD_CHOICE: {
      const { choiceID, tabCategory } = action.payload;
      const tab = getTab(tabCategory);

      return {
        ...state,
        [tab]: {
          [choiceID]: {
            ...action.payload
          },
          ...state[tab],
        },
        newlyCreatedChoiceOrItem: action.payload,
      }
    }

    case ADD_ITEM: {
      const { choiceID, tabCategory, itemID, ...rest } = action.payload;
      const tab = getTab(tabCategory);

      const updateTab = (targetTab) => ({
        ...state[targetTab],
        [choiceID]: {
          ...state[targetTab][choiceID],
          isExpanded: true,
          items: {
            [itemID]: {
              tabCategory,
              itemID,
              ...rest
            },
            ...state[targetTab][choiceID]?.items,
          },
        }
      });

      return {
        ...state,
        [tab]: updateTab(tab),
        newlyCreatedChoiceOrItem: action.payload,
      };
    }

    case 'SYNTAX_CHECK_SUCCESS':
      const syntaxCheck = getInitialSyntaxCheck();

      const { invalidChoiceConditions, invalidItemConditions, invalidDescriptions, ...rest } = action.payload;

      invalidChoiceConditions?.forEach((condition, index, conditions) => {
        const { tabCategory, choiceId } = condition;
        const dataTab = getTab(tabCategory);

        if (syntaxCheck[dataTab][choiceId]) 
          return;

        syntaxCheck[dataTab][choiceId] = {
          ...mapToChoiceConditionNames(conditions.slice(index).filter((condition) => condition.tabCategory === tabCategory && condition.choiceId === choiceId)),
          items: {},
        }
      });

      invalidItemConditions?.forEach((condition, index, conditions) => {
        const { tabCategory, choiceId, choiceItemId } = condition;
        const dataTab = getTab(tabCategory);

        if (syntaxCheck[dataTab][choiceId]?.items[choiceItemId])
          return;

        const data = mapToItemConditionNames(conditions.slice(index).filter((condition) => condition.tabCategory === tabCategory && condition.choiceId === choiceId && condition.choiceItemId === choiceItemId ));

        syntaxCheck[dataTab][choiceId]
          ? syntaxCheck[dataTab][choiceId].items[choiceItemId] = data
          : syntaxCheck[dataTab][choiceId] = { items: { [choiceItemId]: data } }                    
      });

      invalidDescriptions?.forEach((descriptionError, index, descriptions) => {
        const { tabCategory, choiceId, choiceItemId } = descriptionError;
        const dataTab = getTab(tabCategory);

        if (syntaxCheck[dataTab][choiceId]?.items[choiceItemId]?.["PartDescription"])
          return;

        const data = mapToItemDescriptionNames(descriptions.slice(index).filter((description) =>  description.tabCategory === tabCategory && description.choiceId === choiceId && description.choiceItemId === choiceItemId));

        syntaxCheck[dataTab][choiceId]
          ? syntaxCheck[dataTab][choiceId].items[choiceItemId]
            ? syntaxCheck[dataTab][choiceId].items[choiceItemId]["PartDescription"] = data.PartDescription
            : syntaxCheck[dataTab][choiceId].items[choiceItemId] = data
          : syntaxCheck[dataTab][choiceId] = { items: { [choiceItemId]: data } };
      })

      Object?.entries(syntaxCheck)?.forEach(([dataTab, choices]) => syntaxCheck[dataTab].hasError = Object.values(choices).length > 0)

      return {
        ...state,
        syntaxCheck: {
          ...syntaxCheck,
          publishingRights: { ...rest },
          lengths: { 
            invalidChoiceConditionsLength: invalidChoiceConditions?.length ?? 0,
            invalidDescriptionsLength: invalidDescriptions?.length,
            invalidItemConditionsLength: invalidItemConditions?.length,
          }
        }
      }

    case "SET_IS_ERROR_ON_VALIDATE":
      return {
        ...state,
        isErrorOnValidate: action.payload,
      };

      case "REMEMBER_TREE_EXPANSION":
        return {
          ...state,
          expandActions: {
            ...state.expandActions,
            [state.activeTab]: action.payload
          }
        };
  
        case "SELECTED_ITEM":
          return {
            ...state,
            selectedItem: {
              ...state.selectedItem,
              [state.activeTab]: action.payload
            }
      };
    default:
      return state;
  }
};
