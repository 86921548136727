import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import ConfirmationDialog from "components/ConfirmationDialog";
import useKeypress from "hooks/useKeypress";

const ActionableButtons = ({ isSaving, handleSaveClick, handleCancelClick, allowCtrlPlusS }) => {
  const [isCancelDialogOpen, setCancelDialogOpen] = useState(false);

  const handleOpenCancelDialog = () => { setCancelDialogOpen(true) };

  const handleCloseCancelDialog = () => { setCancelDialogOpen(false) };

  const handleConfirmCancel = () => {
    setCancelDialogOpen(false);
    handleCancelClick();
  };

  useKeypress("s", handleSaveClick, allowCtrlPlusS);
  useKeypress("Escape", handleOpenCancelDialog, true);

  return (
    <div style={{ display: "inline-flex", gap: "10px" }}>

      {isCancelDialogOpen &&
        <ConfirmationDialog
          open={isCancelDialogOpen}
          title="Discard changes"
          content={<>Are you sure you want to discard the changes you've made?</>}
          onClose={handleCloseCancelDialog}
          onConfirm={handleConfirmCancel}
          caption="Discard"
          bgColor="#BE1313"
        />}

      <Button variant="outlined" className='cancel-button' onClick={handleOpenCancelDialog} >
        Cancel
      </Button>
      <Button variant="outlined" className='add-Button' onClick={handleSaveClick}>
        {isSaving ? "Saving..." : "Save"}
      </Button>
    </div>
  );
};

export default ActionableButtons;
