import { useState } from "react";

//Material UI
import { IconButton, Tooltip } from "@mui/material";
import { getTab } from "pages/Utils";
//Material UI Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

import AddNewChoiceDialog from "components/AddNewChoiceDialog/AddNewChoiceDialog"

import '../ReactCheckboxTree/ReactCheckboxTree.css';
import SearchBarTree from "./SearchBarTree";
import { IS_ADD_CHOICE_ENABLED } from "constants/modelConstants";
import useKeypress from "hooks/useKeypress";

export default function CheckBoxTreeTopBar(props) {

    const [isAllExpanded, setIsAllExpanded] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogClose = () => {
        setDialogOpen(false);
    };


    const onExpandCollapseClick = () => {
        setIsAllExpanded(!isAllExpanded);
        props.onExpandCollapseClick(!isAllExpanded);
    }

    const onIconClick = (event) => {
        setDialogOpen(!dialogOpen);
    }

    useKeypress("c", onIconClick, getTab(props.activeTab, IS_ADD_CHOICE_ENABLED));

    return (
        <div className='controls-container'>
            <div>
                <Tooltip title={isAllExpanded ? 'Collapse All' : 'Expand All'} placement="top">
                    <IconButton onClick={onExpandCollapseClick} style={{ padding: '12px 0' }}>
                        {isAllExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </Tooltip>
            </div>

            <div className='search-bar'>
                <SearchBarTree onSearch={props.onSearch} dataType={props.dataType} activeTab={props.activeTab} />
            </div>

            {getTab(props.activeTab, IS_ADD_CHOICE_ENABLED) && <div className='crud-bar'>
                <Tooltip title={dialogOpen ? null : 'Add new choice'} placement="top">
                    <IconButton onClick={(event) => onIconClick(event)}>
                        <AddBoxOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </div>}

            {dialogOpen && <AddNewChoiceDialog
                dialogOpen
                handleDialogClose={handleDialogClose}
                kmatId={props.kmatId}
                currentVersion={props.currentVersion}
                activeTab={props.activeTab}
                dataType={props.dataType}
                onSelect={(choice) => props.onNewAddition(choice)}
            />}

        </div>
    )
}