export const createChoiceNew = `mutation($model : CreateChoiceRequest!){
    createChoice(input : $model) {
        kmatID,
        modVer,
        choiceID,
        description,
        dataType,
        isHidden,
        isTemplate,
        isManual,
        tabCategory,
      }
  }`
