export const createRegionalItem = `mutation($model : CreateItemRequest!){
    createRegionalItem(input : $model) {
        kmatID,
        modVer,
        choiceItemID,
        choiceID,
        description,
        partNumber,
        valueID,
        tabCategory
      }
  }`
