import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import Dashboard from '@mui/icons-material/Dashboard';
import SouthAmerica from '@mui/icons-material/SouthAmerica';
import SouthAmericaTwoTone from '@mui/icons-material/SouthAmericaTwoTone';
import AdminPanelSettingsOutlined from '@mui/icons-material/AdminPanelSettingsOutlined';
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings';
import FolderOpen from '@mui/icons-material/FolderOpen';
import Folder from '@mui/icons-material/Folder';
import CalendarViewMonth from '@mui/icons-material/CalendarViewMonth';
import CalendarViewMonthTwoTone from '@mui/icons-material/CalendarViewMonthTwoTone';
import ProductLogo from 'assets/icons/ProductLogo.png';
import Logo from 'assets/hp_black_logo.png';
import './PermanentDrawer.css';

const PermanentDrawer = (props) => {
    const history = useHistory();
    const currentLocation = useLocation();
    const hasRole = (role) => props?.userDetails?.roles?.some(r => r.id === role)

    const renderListItem = (icon, activeIcon, text, location, key) => (
        <ListItem key={key} className='paper-list-item' onClick={() => history.push(`/${location}`)}>
            <IconButton className={`${currentLocation.pathname.includes(location) ? 'currently-active' : ''}`} sx={{ borderRadius: '50px' }}>
                {currentLocation.pathname.includes(location) ? activeIcon : icon}
            </IconButton>
            <Typography variant='subtitle2' className='paper-list-item-text' >{text}</Typography>
        </ListItem>
    );

    return currentLocation.pathname !== '/' && currentLocation.pathname !== '/login' && (
        <Drawer classes={{ paper: 'permanent-drawer-paper' }} variant='permanent' anchor='left'>
            <Toolbar className='drawer-toolbar'><img src={ProductLogo} alt='logo' height={50} /></Toolbar>
            <Divider />
            <List className='paper-list'>
                {hasRole('standard') && renderListItem(<DashboardOutlined sx = {{fontSize : "28px"}} />, <Dashboard sx = {{fontSize : "28px"}} />, 'Dashboard', 'dashboard', 'Dashboard')}
                {hasRole('regional') && renderListItem(<SouthAmerica sx = {{fontSize : "28px"}} />, <SouthAmericaTwoTone sx = {{fontSize : "28px"}} />, 'Regional Dashboard', 'regional', 'Regional')}
                {hasRole("admin") && renderListItem(<AdminPanelSettingsOutlined sx = {{fontSize : "28px"}} />, <AdminPanelSettings sx = {{fontSize : "28px"}} />, 'Admin Panel', 'admin/users', 'Admin Panel')}
                {hasRole('pcx_files') && renderListItem(<FolderOpen sx = {{fontSize : "28px"}} />, <Folder sx = {{fontSize : "28px"}} />, 'PCX Files', 'files', 'Files Panel')}
                {hasRole('btp') && renderListItem(<CalendarViewMonth sx = {{fontSize : "28px"}} />, <CalendarViewMonthTwoTone sx = {{fontSize : "28px"}} />, 'BTP', 'btp', 'Btp Panel')}
            </List>
            <img src={Logo} className='paper-list-logo' alt='hp-logo' height={37} width={37} />
        </Drawer>
    );
}

export default PermanentDrawer;