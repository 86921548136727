import React from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import SyncIcon from "@mui/icons-material/Sync";
import { Star } from "@mui/icons-material";
import { clearTemplatesFilters, refreshTemplates } from "actions/templatesActions";
import { clearFilesFilters, handleFavoriteDownload, refreshFiles } from "actions/filesActions";
import { clearRegionalsFilters, refreshRegionals } from "actions/regionalsActions";
import { boxStyle, iconButtonStyle, iconButtonSubmit } from "pages/DashboardHelpers";
import { clearBtpsFilters, getBtpStatus, refreshBtps, triggerBtp } from "actions/btpAction";
import { clearDashboardFilters } from "actions/dashboardActions";
import { snackBar } from "actions/modelActions";


export default function ActionButtons({ title, checkedRows, handleNavRefresh, Api }) {
  const { filteredProducts } = useSelector(state => state.files.models && state.files);
  const getBTPStatus = useSelector((state) => state.btps.getBTPStatus);
  const disableSubmit = getBTPStatus?.isRunning || !checkedRows?.length;
  const disableDownload = !filteredProducts?.some(product => product.isFavorite);
  const dispatch = useDispatch();

  const actionMapping = {
    "Templates": { clear: clearTemplatesFilters, refresh: refreshTemplates },
    "PCX Files": { clear: clearFilesFilters, refresh: refreshFiles, download: handleFavoriteDownload },
    "Regionals": { clear: clearRegionalsFilters, refresh: refreshRegionals },
    "BTP": { clear: clearBtpsFilters, refresh: refreshBtps },
    "Environment to Refresh": { refresh: getBtpStatus, submit: triggerBtp },
    "New Models and Models with Updates": { clear: clearDashboardFilters, refresh: handleNavRefresh },
  }

  const actions = actionMapping[title];

  const handleRefresh = () => {
    !handleNavRefresh ? dispatch(actions.refresh(Api)) : actions.refresh(true)
    dispatch(snackBar(true, "Refresh Successful", 'success'));
  }

  const handleDownload = () => {
    dispatch(actions.download());
  }

  const handleClearFilters = () => {
    dispatch(actions.clear());
  }

  const handleSubmit = () => {
    dispatch(actions.submit(checkedRows, Api));
  }

  return (
    <Box sx={boxStyle}>
      <Typography variant="h5">{title}</Typography>
      <div style={{ display: "flex", gap: "1rem" }}>
        {actions?.download && (
          <IconButton color="primary" disableRipple onClick={handleDownload} sx={iconButtonStyle} disabled={disableDownload}>
            Download &nbsp; <Star style={{ color: "#FFBF00" }} />
          </IconButton>
        )}
        {!actions?.submit && (
          <IconButton color="primary" disableRipple onClick={handleClearFilters} sx={iconButtonStyle} >
            Clear Filters &nbsp; <FilterAltOffIcon />
          </IconButton>
        )}
        <IconButton color="primary" disableRipple onClick={handleRefresh} sx={iconButtonStyle}>
          Refresh &nbsp; <SyncIcon />
        </IconButton>
        {actions?.submit && (
          <Button variant="contained" size="large" disableRipple onClick={handleSubmit} sx={iconButtonSubmit} disabled={disableSubmit}>
            Submit
          </Button>
        )}
      </div>
    </Box>
  );
}