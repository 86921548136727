import makeStyles from '@mui/styles/makeStyles';
import { TableCell, TableRow } from "@mui/material";
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontWeight: "bold",
    padding: "6px !important",
    fontSize: "15px !important",
    fontFamily: "Forma DJR Micro !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "13px !important",
    padding: "8px 3px !important",
    fontFamily: "Forma DJR Micro !important"
  },
}));

export const useRowStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 0px 2px gray",
    webkitBoxShadow: "0px 0px 2px gray",
    mozBoxShadow: "0px 0px 2px gray",
    border: "1px solid lightGrey",
    borderRadius: "10px !important",
    marginTop: "20px",
    display: "flex",
    flexDirection: "column"
  },
  filledTextField: {
    width: "700px",
    justifyContent: "center",
    margin: "10px 0",
    "& .MuiFilledInput-root": {
      border: "1px solid lightGrey",
      backgroundColor: theme.palette.common.white,
      borderRadius: "12px",
      resize: "vertical",
      "&:hover": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    "& .MuiFilledInput-root.Mui-focused": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  outlinedTextField: {
    justifyContent: "center",
    "& .MuiOutlinedInput-multiline": {
      padding: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "9px 19px 9px 9px !important",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.common.white,
      borderRadius: "5px",
      resize: "vertical",
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
      backgroundColor: theme.palette.grey[100],
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.grey[400]}`,
      },
    },
  },
  saveButton: {
    marginLeft: "13px !important",
    borderRadius: "10px !important",
  },
  priorityIcon: {
    color: "#FDFEFE",
    borderRadius: "50%",
    backgroundColor: "#BE1313"
  },
  textField: {
    wordBreak: "break-word",
  },
  textarea: {
    resize: "vertical",
    fontSize: "13px",
  },
  accordionDetails: {
    padding: "0px 8px !important",
    marginTop : "6px"
  },
  tableHeader: {
    position: "sticky",
    top: 0,
    backgroundColor: "#fff",
    zIndex: 1,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const style = {
  top: '50%',
  left: '50%',
  position: 'fixed',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: 4,
  borderRadius: "10px"
};
