import React from 'react'
import clsx from 'clsx'

function Img({ src, onClick, alt = '', className, style, width, height }) {
  const r = React.createRef()
  React.useEffect(() => {
    if (width) {
      r.current.style.width = `${width}px`
    }
    if (height) {
      r.current.style.height = `${height}px`
    }
    r.current.style['background-size'] = 'contain'
    r.current.style['background-repeat'] = 'no-repeat'
    r.current.style['background-image'] = `url(${src})`
  })
  return (
    <div
      title={alt}
      ref={r}
      style={style}
      onClick={onClick}
      className={clsx('mimg', className)}
    />
  )
}
export default Img
