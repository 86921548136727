import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Badge, IconButton, TextField } from "@mui/material";
import { alpha } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    width: "100%",
    minHeight: "850px",
    flexGrow: 1,
    marginTop: "20px",
    '& .MuiFilledInput-root': {
      border: '1px solid #ABB2B9',
      boxShadow: "0.5px 0.5px #ABB2B9",
      fontFamily: "Forma DJR Micro !important",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "400 !important",
      borderRadius: 12,
      backgroundColor: 'transparent',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        borderColor: theme.palette.primary.main,
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      },
    },
  },
  root2: {
    padding: 0,
    minHeight: "850px",
    width: "100%",
    flexGrow: 1,
  },
  treeview: {
    flexGrow: 1,
    maxHeight: "100%",
    width: "100%",
    display: "flex",
  },
  tableRow: {
    border: "1px solid darkgray",
    padding: "0 5px",
    ".MuiTableRow-root.MuiTableRow-hover&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "lightblue",
      "& > .MuiTableCell-root": {
        color: "black",
      },
    },
  },
  hoverable: {
    border: "1px solid darkgray",
    "&:hover": {
      color: `${theme.palette.primary.normal}`,
      cursor: `pointer`,
    },
  },
  brand: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    borderBottom: "2px solid lightgray",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
    height: "100%",
    minHeight: "400px",
    width : "100%",
    borderRadius: "16px"
  },
  badge: {
    top: 0,
    right: 0,
    transform: "scale(1) translate(50%, -50%)",
    transformOrigin: "100% 0%",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "95%",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 400,
    fontFamily: "Forma DJR Micro !important",
    flex: "1",
    marginRight: "5px",
    marginBottom: "5px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  treeItemContainer: {
    padding: 0,
    margin: 0,
    listStyle: "none",
    display: "flex",
    justifyContent: "space-between",
  },
  cvg: {
    flexGrow: 1,
    display: "flex",
    padding: "7px 5px 4px 5px !important",
    borderRadius: "8px",
    marginLeft: "13px",
    marginRight: "22px",
    "& > span": {
      textDecoration: "none",
      color: "#333",
    },
    "&.selected": {
      backgroundColor: "rgba(2, 120, 171, 0.1) !important",
      fontWeight: "bold",
    },
    "&.disabled": {
      color: "gray",
    },
    "&:hover, &:focus": {
      cursor: "pointer",
      backgroundColor: "lightgray",
      "&.selected": {},
    },
  },
  popperContainer: {
    margin: 0,
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 315px)",
    minHeight: "100%",
  },
  popperHeader: {
    order: 1,
    display: "flex",
    flexShrink: 0,
    flexBasis: "30px",
    fontSize: "1.2em",
    marginBottom: "20px",
    alignItems: "center",
  },
  publishLoading: {
    position: "fixed", 
    top: "50%", 
    left: "50%", 
    zIndex: "99"
  },
  popperList: {
    flexGrow: 1,
    height: "calc(100vh - 315px)",
    maxHeight: "100%",
    overflowX: "hidden",
    overflowY: "scroll",
    width: "100%",
    overscrollBehavior: "contain",
    transition: "none",
    order: 2,
    scrollbarWidth: "thin" /*Firefox*/,
    // width: "calc(100% - 2px)",
    "&::-webkit-scrollbar": {
      width: "15px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgb(179, 178, 178)",
      borderRadius: "20px",
      border: "1px solid rgb(179, 178, 178)",
    },
  },
  popperFooter: {
    order: 3,
    display: "flex",
    flexDirection: "row",
    flexShrink: 0,
    alignItems: "flex-end",
    flexBasis: "30px",
    padding: "0px 0px 6px",
  },
  titleDescription: {
    display: "inline-flex",
    flex: 1,
    gap: "6px",
    fontSize: "xx-large",
    paddingLeft: "8px",
  },
  subTitle: {
    padding: "15px 2px 12px 2px",
    fontSize: "12px",
    fontFamily: "Forma DJR Micro !important",
    color: "#F0F3F4",
    marginTop: "9px",
    marginLeft: "9px",
  },
  actionableButtons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px !important",
  },
  keyboardIcon: {
    position: "relative",
    top: "20px",
    left: "5px",
    cursor: "pointer"
  },
  choiceText: {
    flexGrow: 1,
    padding: "5px",
    fontFamily: "Forma DJR Micro !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    lineHeight: "20px !important",
    color: "#000000"
  },
  titleHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100% !important",
    marginTop: "10px"
  },
  editTitle: {
    marginTop : "8px",
    marginBottom: "5px",
    fontSize: "36px",
    lineHeight: "32px",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    color: "#212121",
  },
  status: {
    border: "1px solid #1C7A17",
    background: "#f50057",
    borderRadius: "24px",
    padding: "5px 8px 4px 8px",
    fontSize: "12px",
    fontFamily: "Forma DJR Micro !important",
    lineHeight: "16px",
    color: "#FFFFFF",
    marginTop: "9px",
    marginBottom: "20px",
  },
  cvgEditor: {
    marginTop: "8px",
    fontSize: "36px",
    fontFamily: "Forma DJR Micro !important",
    lineHeight: "32px",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    marginBottom: "5px"
  },
  noItem: {
    borderBottom: '1px solid #ADADAD',
    borderTop: '1px solid #ADADAD',
    color: theme.palette.text.primary,
    fontSize: "20px",
    marginBottom: "15px",
    marginTop: "11px",
    padding: "20px 0px 15px 0px",
    textAlign: 'center'
  },
  viewBtn : {
    backgroundColor: "#1976d2",
     "&:hover": {
      backgroundColor: "#1976d2",
     }
  }
}));

export const CustomSearchField = withStyles({
  root: {
    '& .MuiInputBase-root': {
      borderRadius: '12px',
      height: '51px',
      width: '100%',
    },
    flex: "1",
    marginTop: "8px",
    marginLeft: "5px",
    marginRight: "10px",
    position: 'relative',
  },
})(TextField);

export const searchFieldProps = {
  id: "searchfield",
  name: "searchfield",
  variant: "outlined",
};

export const CustomSearch = ({ value, handleClearSearch, ...props }) => {
  return (
    <CustomSearchField
      {...props}
      {...searchFieldProps}
      value={value}
      style={props.style ? props.style : { width: "220px" }}
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <>
            {value ? (
              <IconButton style={{ position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }} onClick={handleClearSearch} >
                <ClearIcon />
              </IconButton>
            ) : (
              <SearchIcon style={{ marginRight: '8px', color: 'rgba(0, 0, 0, 0.54)' }} />
            )}
          </>
        ),
      }}
    />
  );
};

export function useAutoFocus(textFieldRef) {
  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
      const length = textFieldRef.current.value?.length;
      textFieldRef.current.setSelectionRange(length, length);
    }
  }, [textFieldRef]);
}

export const CountBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "lightGrey",
    right: 20,
    top: 12,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "4px 9px 2px 9px !important",
    fontFamily: "Forma DJR Micro !important",
    overlap: "rectangular"
  },
}))(Badge);
