import Tooltip from "@mui/material/Tooltip";
import { Badge } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

export const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
            width: "-webkit-fill-available",
        },
        "& .MuiInputBase-input.Mui-disabled": {
            color: `${theme.palette.primary.main}`,
            fontSize: "18px",
        },
        "& .MuiOutlinedInput-inputMarginDense": {
            color: "black",
        },
        "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense": {
            padding: "8px 0",
        },
    },
    paper: {
        height: "100%",
        maxHeight: "100%",
        overflow: "hidden",
        marginTop: "7px",
        zIndex: "1000",
    },
    cvg: {
        flexGrow: 1,
        display: "flex",
        margin: "1px",
        padding : "9px 6px 5px 6px !important",
        fontSize: "16px",
        fontFamily: "Forma DJR Micro !important",
        fontWeight: 400,
        color: "black !important",
        lineHeight: "27px",
        marginLeft: "20px",
        marginRight: "20px",
        "& > span": {
            textDecoration: "none",
            color: "#333",
        },
        "&.selected": {
            backgroundColor: "rgba(2, 120, 171, 0.1)",
            borderRadius: "8px",
            color: "#014667 !important",
        },
        "&.disabled": {
            color: "gray",
        },
        "&:hover, &:focus": {
            cursor: "pointer",
            backgroundColor: "lightgray",
            "&.selected": {},
        },
    },
    popperContainer: {
        margin: 0,
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
    },
    popperHeader: {
        order: 1,
        display: "flex",
        alignSelf: "stretch",
        fontFamily: "Forma DJR UI",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "24px !important",
        lineHeight: "32px !important",
        marginLeft: "15px",
        marginRight: "20px",
        marginBottom: "10px",
        alignItems: "center",
    },
    popperList: {
        flexGrow: 1,
        height: "calc(100vh - 60px)",
        maxHeight: "100%",
        overflowX: "hidden",
        overflowY: "scroll",
        width: "100%",
        overscrollBehavior: "contain",
        transition: "none",
        order: 2,
        fontFamily: "Forma DJR Micro !important",
        scrollbarWidth: "thin" /*Firefox*/,
        "&::-webkit-scrollbar": {
            width: "15px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgb(179, 178, 178)",
            borderRadius: "50px",
            border: "1px solid rgb(179, 178, 178)",
        },
    },
    popperFooter: {
        order: 3,
        display: "flex",
        flexDirection: "row",
        flexShrink: 0,
        alignItems: "flex-end",
        flexBasis: "30px",
        padding: "0px 0px 6px",
    },
    icon: {
        position: "relative",
        float: "right",
        top: "5px",
        right: "20px",
        cursor: "pointer",
    },
    cellContent: {
        width: "384px",
        height: "80px",
        justifyContent: "center",
        [`& fieldset`]: {
            borderRadius: 10,
            paddingTop: 20,
            paddingBottom: 15,
        },
    },
    saveButton: {
        marginRight: "13px !important",
        borderRadius: "10px !important",
        padding: "7px 17px 7px 17px !important",
    },
    noItem: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    cvgSelection: {
        flexGrow: 1,
        display: "flex",
        alignItems: "flex-start",
    },
    cvgName: {
        width: "440px",
        overflowWrap: "break-word",
    },
    duplicateError: {
        color: "red",
        marginLeft: "5px !important",
        marginBottom: "5px !important",
        height: "30px !important"
    },
    treeLabel: {
        padding: "5px 0px",
    }
}));

export const style = {
    top: "50%",
    left: "50%",
    position: "fixed",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
};

export const CountBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: "lightGrey",
        right: 9,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: "4px 7px",
        fontFamily: "HPSimplified_Rg, Arial, Helvetica, sans-serif",
        color: "black !important",
        overlap: "rectangular"
    },
}))(Badge);

export const ItemTooltip = withStyles({
    tooltip: {
        color: "black",
        backgroundColor: "cyan",
        fontSize: "0.8rem",
    },
})(Tooltip);
