import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteChoice } from 'actions/modelActions';
import { snackBar } from "actions/modelActions";
import ConfirmationDialog from "components/ConfirmationDialog";


const ChoiceDelete = ({ tabCategory, choiceID, nodes, onHandleCb, handleeDeleteClose, kmatId, currentVersion }) => {
  const dispatch = useDispatch();
  const [openDelete, setOpenDelete] = useState(true);
  const deleteChoiceId = choiceID?.replace("-", " ");

  const handleDeleteClose = () => {
    setOpenDelete(false);
    handleeDeleteClose(false);
  };

  const handleDeleteChoice = () => {
    deleteChoice({ kmatID: kmatId, modVer: currentVersion, choiceID, tabCategory })
      .then((data) => {
        if (data.deleteChoice === null) {
          const currentIndex = nodes.items.findIndex(choice => choice.id === choiceID);
          handleDeleteClose();
          dispatch({ type: "DELETE_CHOICE", payload: choiceID });
          dispatch(snackBar(true, `Choice "${choiceID}" has been removed from KB Model`, 'success'));
          onHandleCb(nodes.items[currentIndex + 1] ?? nodes.items[currentIndex - 1] ?? null);
        }
      })
      .catch((error) => dispatch(snackBar(true, error.message)))
  };

  return (
    <>
      <ConfirmationDialog
        open={openDelete}
        title="Confirm Delete Choice"
        content={<>You are about to delete<b> '{deleteChoiceId}' </b> choice? Are you sure you want to delete it?</>}
        onClose={handleDeleteClose}
        onConfirm={handleDeleteChoice}
        caption="Delete Choice"
        bgColor="#BE1313"
      />
    </>
  );
};

export default ChoiceDelete;
