export const kbUser = `query {
    kbUserWithRoles{
        firstName
      lastName
      role
      email
        roles {
        id
        name
      }
    }
}`