import axios from 'axios';
import { GRAPHQL_BASE_URL, GRAPHQL_SECONDARY_URL } from 'constants/userConstants';
import { getCookie, clearAllCookies } from "utils/function-utils";
import { queries } from "./Queries/index"
/**
 * new http/s request instance
 */
const apiInstance = axios.create({
  baseURL: 'REST is now deprecated. We use GraphqlApi'
});

/**
 * inject token
 */
apiInstance.interceptors.request.use((config) => {
  // nprogress.start();
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${getCookie('access_token') || null}`;
  return config;
}, () => {
  // nprogress.done();
  return Promise.reject(new Error('Api request create failed'));
});

/**
 * interceptor errors
 */
apiInstance.interceptors.response.use((rsp) => {
  return rsp;
}, (error) => {
  let errMsg = 'Cannot request to api server';
  if (error.response && error.response.data && error.response.data.message) {
    const message = error.response.data.message;
    if (Array.isArray(message)) {
      errMsg = message[0].message;
    } else {
      errMsg = error.response.data.message;
    }
    return Promise.resolve();
  }
  if (error.response.status === 401 && window.location.pathname !== '/') {
    clearAllCookies();
    setTimeout(() => {
      window.location.reload();
    })
  }
  return Promise.reject(new Error(errMsg));
});

const createGraphqlInstance = (baseURL) => {
  const instance = axios.create({ baseURL });

  instance.interceptors.request.use((config) => {
    // nprogress.start();
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${getCookie('access_token') || null}`;

    return config;
  }, () => {
    // nprogress.done();
    return Promise.reject(new Error('Api request create failed'));
  });

  instance.interceptors.response.use((rsp) => {
    if (rsp.data.errors) {
      if (rsp.data.errors.some((error) => error.message.includes('Not allowed'))) {
        clearAllCookies();
        setTimeout(() => {
          window.location.href = window.location.origin + '/login'
        });
        return Promise.reject(new Error('Not allowed'));
      } else if (rsp.data.errors) {
        const errors = rsp.data.errors.reduce((acc, error) => {
          acc = `${acc} ${error.message}`
          return acc
        }, '')
        return Promise.reject(new Error(errors))
      }
    }
    return rsp.data
  }, (error) => {
    let errMsg = 'Cannot request to api server';

    if (error.response.status === 401) {
      if (error.response.data) {
        const message = error.response.data.message;
        if (Array.isArray(message)) {
          errMsg = message[0].message;
        } else {
          errMsg = error.response.data.message;
        }
      }
      clearAllCookies();
      setTimeout(() => {
        window.location.href = window.location.origin + '/login'
      });
      return Promise.reject(new Error(`${errMsg} - Redirecting to Login Page`));
    }
  });

  return instance;
};

const createGraphqlApi = (instance) => ({
  query: (queryName, args = {}) => {
    return instance.post('model', { query: queries[queryName], variables: { ...args } })
  }
});

export const GraphqlApi = createGraphqlApi(createGraphqlInstance(GRAPHQL_BASE_URL));
export const GraphqlApiSecondary = createGraphqlApi(createGraphqlInstance(GRAPHQL_SECONDARY_URL));

export const Api = apiInstance;
