import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: '1px solid #ADADAD',
    marginBottom: "15px",
    paddingTop: "15px"
  },
}));

export default function TabPanel(props) {
  const { setEditTab } = props
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onTabChange(newValue);
  };

  useEffect(() => {
    setEditTab(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <div className={classes.root}>
      <Tabs value={value} onChange={handleChange} aria-label="styled tabs" indicatorColor="primary" textColor="primary" className={classes.tabsText}>
        <Tab className={classes.tabs} label="CVGs  ➡ Items" />
        <Tab className={classes.tabs} label="Items ➡ CVGs" />
        <Tab className={classes.tabs} label="Tree View" />
      </Tabs>
    </div>
  );
}
