import moment from 'moment';
import { Typography, IconButton, Tooltip, Button } from '@mui/material';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { renderSelectHeader, renderTextfieldHeader } from 'pages/DashboardHelpers';

const colorTypes = {
    'Published': '#0278ab',
    'Updates available': '#d06702',
    'In progress': '#ff0000',
    'New': '#1c7a17',
    'In validation': '#fff44f',
    'Validated': '#f4c430',
}

export function retrieveBackgroundColor(modelStatus) {
    let formatedModelStatus = modelStatus.toLowerCase();
    if (formatedModelStatus.startsWith('in validation'))
        formatedModelStatus = 'In validation';

    if (formatedModelStatus.startsWith('validated'))
        formatedModelStatus = 'Validated';

    return colorTypes[formatedModelStatus.replace(/^\w/, (char) => char.toUpperCase())];
}

export const importModelColumns = (handleImportModelFromDialog) => [
    {
        headerName: 'Effective Date',
        field: 'fileDate',
        headerClassName: 'dashboard-data-grid-headers',
        headerAlign: 'left',
        flex: 1,
        sortable: false,
        renderHeader: () => {
            return (
                <div className='header-children'>
                    <Typography variant='subtitle2' sx={{ marginBottom: "5px" }}>  Effective Date  </Typography>
                </div>
            )
        },
        renderCell: (params) => {
            console.log("params", params);
            return (
                <div className='last-column'>
                    <div>
                        <Typography className='effective-date-in-imports' variant='subtitle2'>{params.row.fileDate}{' - '}{moment(params.row.fileDate).fromNow()}{', v'}</Typography>
                        <Typography className='effective-date-in-imports file-name' sx={{ fontSize: "11px" }}>{params.row.fileName}</Typography>
                    </div>
                    <div>
                        <Button
                            variant="text"
                            size="small"
                            className='update-model-btn'
                            disabled={params.row.disabled}
                            onClick={() => handleImportModelFromDialog(params.row)}
                        >
                            Import
                        </Button>
                    </div>
                </div>
            );
        }
    }
]

export const columnTypes = {
    kmatID: {
        type: 'text',
        name: 'kmatID',
        matchFields: ['kmatID']
    },
    status: {
        type: 'text',
        name: 'status',
        matchFields: ['status']
    },
    productLineName: {
        type: 'select',
        name: 'productLineName',
        matchFields: ['productLineName']
    },
    name: {
        type: 'text',
        name: 'name',
        matchFields: ['name']
    },
    productLineID: {
        type: 'text',
        name: 'productLineID',
        matchFields: ['assignedToEmail', 'assignedToName']
    },
    fileDate: {
        type: 'text',
        name: 'fileDate',
        matchFields: ['fileDate', 'fileName']
    }
}

export const getColumns = (filters, handleChange, statuses, productLines, handlePopup, userEmail, role) => [
    {
        headerName: 'KMAT ID',
        field: 'kmatID',
        headerClassName: 'dashboard-data-grid-headers',
        flex: 0.08,
        sortable: false,
        renderCell: ({ row }) => {
            const lastDraft = row.actions.lastDraft?.version;
            return (
                <div>
                    <Typography variant='subtitle2'>{row.kmatID}{lastDraft && <span className='version-on-kmat-field'>{' v'}{lastDraft}</span>}</Typography>
                </div>
            );
        },
        renderHeader: () => renderTextfieldHeader(columnTypes.kmatID, 'KMAT ID', filters, handleChange),
    },
    {
        headerName: 'Status',
        field: 'status',
        headerClassName: 'dashboard-data-grid-headers',
        headerAlign: 'left',
        flex: 0.08,
        sortable: false,
        renderCell: (params) => {
            return (
                <div className='row-status'>
                    <div className='dot' style={{ backgroundColor: `${retrieveBackgroundColor(params.row.status)}` }}></div>
                    <Typography variant='subtitle2'>
                        {params.row.status.charAt(0).toUpperCase() + params.row.status.slice(1)}
                    </Typography>
                    {params.row.imports && Object.keys(params.row.imports).length > 0 && <div className='imports-count'>{Object.keys(params.row.imports).length}</div>}
                </div>
            );
        },
        renderHeader: () => renderSelectHeader(columnTypes?.status, filters, ' Status', handleChange, statuses),
    },
    {
        headerName: 'Product Line',
        field: 'productLineName',
        headerClassName: 'dashboard-data-grid-headers',
        headerAlign: 'left',
        flex: 0.1,
        sortable: false,
        renderCell: (params) => {
            return (
                <div>
                    <Typography variant='subtitle2'>{params.row.productLineName}</Typography>
                </div>
            );
        },
        renderHeader: () => renderSelectHeader(columnTypes?.productLineName, filters, 'Product Line', handleChange, productLines),
    },
    {
        headerName: 'Product Name',
        field: 'name',
        headerClassName: 'dashboard-data-grid-headers',
        headerAlign: 'left',
        flex: 0.1,
        sortable: false,
        renderCell: (params) => {
            return (
                <div>
                    <Typography variant='subtitle2'>{params.row.name}</Typography>
                </div>
            );
        },
        renderHeader: () => renderTextfieldHeader(columnTypes.name, 'Product Name', filters, handleChange),
    },
    {
        headerName: 'Authored by',
        field: 'productLineID',
        headerClassName: 'dashboard-data-grid-headers',
        headerAlign: 'left',
        flex: 0.1,
        sortable: false,
        renderCell: (params) => {
            return (
                <div>
                    <Typography variant='subtitle2'>{params.row.assignedToName}</Typography>
                    <Typography className='file-name' sx={{ fontSize: "12px" }}>{params.row.assignedToEmail}</Typography>
                </div>
            );
        },
        renderHeader: () => renderTextfieldHeader(columnTypes.productLineID, 'Authored By', filters, handleChange),
    },
    {
        headerName: 'Program Matrix version',
        field: 'fileDate',
        headerClassName: 'dashboard-data-grid-headers',
        headerAlign: 'left',
        flex: 0.3,
        sortable: false,
        renderHeader: () => renderTextfieldHeader(columnTypes.fileDate, ' Program Matrix version', filters, handleChange),
        // DashboardPopOver
        renderCell: (params) => {
            return (
                <div className='last-column'>
                    <div className='effective-date-data'>
                        <Tooltip title={params.row.fileName || ''} placement='bottom-start' >
                            <Typography className='effective-date-data' variant='subtitle2'>{params.row.fileName}</Typography>
                        </Tooltip>
                        {moment(params.row.fileDate).isValid() &&
                            <Typography className='file-name effective-date-data' sx={{ fontSize: "12px" }}>
                                {moment(params.row.fileDate).format('LLLL')}{' - '}{moment(params.row.fileDate).fromNow()}
                            </Typography>}
                    </div>
                    <IconButton
                        onClick={(e) => handlePopup(e, { id: `buttons-popover-${params.row.kmatID}`, userEmail, role, row: params.row })}
                        aria-describedby={`buttons-popover-${params.row.kmatID}`}
                        style={{ outline: '1px solid #0278AB', borderRadius: '6px', padding: '2px 4px' }}
                    >
                        <MoreHorizOutlinedIcon size='large' htmlColor="#0278AB" sx={{ padding: '0' }} />
                    </IconButton>
                </div>
            );
        }
    },
];