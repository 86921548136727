import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { FormControl, TextField, Typography } from "@mui/material";
import { CVG_REGIONAL_TABS, CVG_TABS } from "constants/modelConstants";
import "./EditModel.scss";
import { useStyles, CountBadge, CustomSearch } from "./IndexHelpers";
import MenuItem from '@mui/material/MenuItem';
import { cvgTabSelection } from "actions/modelActions";
import useKeypress from "hooks/useKeypress";

const CvgIndex = ({ setActiveCvgNode, activeCvgNode, cvgDatasetValues, cvgsFilter, setCvgsFilter, activeCVGTab, dataType }) => {
	const tabs = dataType === "regional" ? CVG_REGIONAL_TABS : CVG_TABS;
	const classes = useStyles();
	const dispatch = useDispatch();
	const searchRef = useRef();
	useKeypress("f", () => { searchRef.current.focus() }, true);

	const handleCvgIdChange = (e) => {
		setCvgsFilter('');
		setActiveCvgNode({ ...activeCvgNode, [activeCVGTab]: cvgDatasetValues[0] });
		dispatch(cvgTabSelection(e.target.value));
	};

	const handleClearSearch = () => {
		setCvgsFilter('');
		setActiveCvgNode({ ...activeCvgNode, [activeCVGTab]: cvgDatasetValues[0] });
	};

	const handleChangeSearch = (newCvgsFilter) => {
		if (newCvgsFilter === "") return handleClearSearch();

		const nodes = cvgDatasetValues.filter((dataset) => dataset?.allowedCVGs.toLowerCase().includes(newCvgsFilter.toLowerCase()));
		setCvgsFilter(newCvgsFilter);
		setActiveCvgNode({ ...activeCvgNode, [activeCVGTab]: nodes[0] });
	}

	return (
		<div className={classes.popperContainer}>
			<div className={classes.popperHeader}>
				<FormControl className={classes.formControl}>
					<TextField
						select
						value={activeCVGTab}
						variant="filled"
						margin="dense"
						label="Category"
						sx = {{'& .MuiFilledInput-root': {height:"51px"},'& .MuiSelect-select':{marginTop:"5px"},'& .MuiSelect-icon':{marginTop:"5px"}}}
						InputProps={{disableUnderline: true }}
						onChange={(e) => handleCvgIdChange(e)}
					>
						{(tabs).map((tab) => (
							<MenuItem value={tab.id} id={tab.id} key={tab.id}>
								{tab.title}
							</MenuItem>
						))}
					</TextField>
				</FormControl>
				<CustomSearch
					placeholder="Search CVGs.."
					value={cvgsFilter}
					inputRef={searchRef}
					handleClearSearch={handleClearSearch}
					onChange={(e) => handleChangeSearch(e.target.value)}
				/>
			</div>
			<div id="split-button-menu" className={classes.popperList}>
				{cvgDatasetValues?.length ? (
					(cvgsFilter ? cvgDatasetValues.filter((dataset) => dataset?.allowedCVGs.toLowerCase().includes(cvgsFilter.toLowerCase())) : cvgDatasetValues).map((choice) => (
						<div
							key={choice.choiceID}
							className={clsx(classes.cvg, { selected: choice.choiceID === activeCvgNode[activeCVGTab]?.choiceID })}
							onClick={() => { setActiveCvgNode({ ...activeCvgNode, [activeCVGTab]: choice }) }}
						>
							<Typography className={classes.choiceText}>{choice.name}</Typography>
							<CountBadge badgeContent={Object.values(choice.items).length} />
						</div>
					))
				) : (
					<div>No data found!</div>
				)}
			</div>
		</div>
	);
};

CvgIndex.propTypes = {
	setActiveCvgNode: PropTypes.func.isRequired,
	activeCvgNode: PropTypes.object.isRequired,
	cvgDatasetValues: PropTypes.array.isRequired,
	cvgsFilter: PropTypes.string.isRequired,
	setCvgsFilter: PropTypes.func.isRequired,
}

export default CvgIndex;
