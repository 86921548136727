import React, { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const LocationEffect = () => {
    const currentLocation = useLocation();
    const [currentPageName, setCurrentPageName] = useState('');

    useEffect(() => {
        if (currentLocation) {
            const pageLocation = currentLocation.pathname.split("/")[1];
            const pageMappings = {
                "User-access-management": "Admin Panel",
                "Ui-specs": "UI-Specs",
                "Files": "PCX Files",
                "Regional": "Regionals",
                "Btp": "BTP",
            };
            const pageName = pageMappings[pageLocation] || pageLocation.charAt(0).toUpperCase() + pageLocation.slice(1);
            setCurrentPageName(pageName);
        }
    }, [currentLocation]);

    return (
        currentLocation.pathname !== '/' && currentLocation.pathname !== "/login" && <div >{currentPageName}</div>
    )
};

export default LocationEffect;
