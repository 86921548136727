import React, { useState, useEffect, useCallback } from 'react'
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import  Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { DataGrid} from '@mui/x-data-grid';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { refreshRegionals, filterRegionals } from 'actions/regionalsActions';
import { clearModelState } from 'actions/undoActions';
import ActionButtons from '../ActionButtons';
import { container, dataGridStyles, renderTextfieldHeader } from 'pages/DashboardHelpers';
import RoundedPagination from 'pages/Pagination';

function RegionalDashboard() {
    const history = useHistory();
    const [pageSize, setPageSize] = useState(10);
    const dispatch = useDispatch();

    const { filters, filteredRegionals, isLoading } = useSelector(state => state.regionals.filteredRegionals && state.regionals)

    useEffect(() => {
        dispatch(refreshRegionals());
    }, [dispatch]);

    const handleChange = useCallback((col, value) => {
        dispatch(filterRegionals({ ...col, searchText: value }))
    }, [dispatch]);

    const editRegionals = (rowDetails) => {
        history.push({ pathname: "/regional/" + rowDetails.kmatID });
        dispatch(clearModelState());
    }

    const columnTypes = {
        kmatID: {
            type: 'text',
            name: 'kmatID',
            matchFields: ['kmatID']
        },
        name: {
            type: 'text',
            name: 'name',
            matchFields: ['name']
        },
    };

    const columns = [
        {
            headerName: 'KMAT ID',
            field: 'kmatID',
            headerClassName: 'dashboard-data-grid-headers',
            flex: 0.4,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div>
                        <Typography variant='subtitle2'>{params.row.kmatID}</Typography>
                    </div>
                );
            },
            renderHeader: () => renderTextfieldHeader(columnTypes.kmatID, 'KMAT ID', filters, handleChange),
        },
        {
            headerName: 'Regional Name',
            field: 'name',
            headerClassName: 'dashboard-data-grid-headers',
            headerAlign: 'left',
            flex: 0.4,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div>
                        <Typography variant='subtitle2'>{params.row.name}</Typography>
                    </div>
                );
            },
            renderHeader: () => renderTextfieldHeader(columnTypes.name, 'Regional Name', filters, handleChange),
            
        },
        {
            headerName: '',
            field: 'actions',
            headerClassName: 'dashboard-data-grid-headers',
            headerAlign: 'left',
            flex: 0.2,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className='header-actions'>
                        <IconButton className='edit-regionals-button' disableRipple onClick={() => editRegionals(params.row)} >
                            <DriveFileRenameOutlineIcon />
                        </IconButton>
                    </div>
                );
            }
        },
    ]

    return (
        <Box sx={container}>
            <ActionButtons title="Regionals" />
            <DataGrid
                columns={columns}
                onRowClick={(params) => editRegionals(params.row)}
                rows={filteredRegionals}
                getRowId={(row) => row.kmatID}
                loading={isLoading}
                rowHeight={48}
                headerHeight={80}
                className='dashboard-data-grid'
                disableSelectionOnClick
                getRowClassName={(params) => `${params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'} clickable`}
                headerClassName='dashboard-grid-headers'
                initialState={{pagination: { paginationModel: { page: 0, pageSize: 15 } },}}
                components={{ Pagination: RoundedPagination }}
                sx={dataGridStyles}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 50]}
                disableColumnFilter
                disableColumnMenu
            />
        </Box>
    )
}

export default withRouter(RegionalDashboard);
