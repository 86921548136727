export const dashboard = `query {
  dashboard {
    role,
    productModels {
      kmatID
      lastDraft
      status
      productLineID
      productLineName
      name
      assignedToName
      assignedToEmail
      isReleased
      fileName
      fileDate
      imports {
          recordID
          fileName
          fileDate
          disabled
      }
      actions {
        import {
            disabled
        }
        productionDraft {
            disabled
            version
        }
        lastDraft {
            disabled
            version
        }
        publish {
            disabled
            version
            kind
        }
        pcxFiles {
            disabled
        }
        update {
            disabled
        }
      }
    },
    productLines {
        id
        name
        count
    },
    users {
        firstName
        lastName
        email
        role
    },
    statuses
  }
}`
