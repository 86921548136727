import React, { useEffect } from "react";
import Row from "./Row";
import useGlobalStore from "hooks/useGlobalStore";
import { updateChoiceItemFields } from "actions/modelActions";

const MultiItemEdit = ({ setIsEfficientlyEditedInBulkMode, BulkEditData, tabName }) => {
  const [selectedChoices, setSelectedChoices] = useGlobalStore(BulkEditData, updateChoiceItemFields, { tabCategory: tabName});
  const isEfficientlyEdited = selectedChoices.some((choice) => choice.items.some((item) => item.edited));

  useEffect(() => {
    if (isEfficientlyEdited)
      setIsEfficientlyEditedInBulkMode(true)
  }, [isEfficientlyEdited, setIsEfficientlyEditedInBulkMode]);

  return (
    <>
      {selectedChoices.map((row) => (
        <Row
          key={row.choiceID}
          row={row}
          handleDataUpdate={setSelectedChoices}
          tabName={tabName}
        />
      ))}
    </>
  );
};

export default MultiItemEdit;
