import { useState } from "react";
import ChoiceTabPanel from 'components/ChoiceTabPanel';
import { Grid } from "@mui/material";
import Messages from "../Tabs/Messages";
import ChoiceSettings from '../Tabs/ChoiceSettings'
import Comments from '../Tabs/Comments';

const ChoiceEdit = ({ data }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabs = [
    <ChoiceSettings
      key={`editChoice-settings-${data.choiceID}`}
      choiceID={data.choiceID}
      tabName={data.tabCategory}
    />,
    <Messages
      key={`editChoice-messages-${data.choiceID}`}
      choiceID={data.choiceID}
      tabName={data.tabCategory}
    />,
    <Comments
      key={`editChoice-comments-${data.choiceID}`}
      choiceID={data.choiceID}
      tabName={data.tabCategory}
    />
  ]

  return !data || data?.dataType === "SYS" ? null : (
    <>
      <Grid>
        <ChoiceTabPanel
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          choiceID={data.choiceID}
          tabName={data.tabCategory}
          hasComments={Boolean(data.comments?.length)} 
        />
      </Grid>

      {tabs[activeTabIndex]}
    </>
  );
};

export default ChoiceEdit;
