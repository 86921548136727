import { useEffect,useRef, useState } from "react";

import { CustomSearch, searchFieldProps } from 'pages/EditModel/IndexHelpers';
import "../ReactCheckboxTree/ReactCheckboxTree.css";
import useKeypress from "hooks/useKeypress";

export default function SearchBarTree(props) {
  const [searchKeyword, setSearchKeyword] = useState("");
  const searchRef = useRef();
  const onSearch = (text) => {
    setSearchKeyword(text);
    props.onSearch(text); 
  };

  useEffect(() => {
    setSearchKeyword("");
    props.onSearch("")
  }, [props.activeTab])

  const handleClearSearch = () => {
    setSearchKeyword("");
    props.onSearch("");

  };

  useKeypress("f", () => { searchRef.current.focus() }, true);

  return (
    <CustomSearch {...searchFieldProps}
      placeholder={
        props.dataType !== "regional"
          ? "Search Choice, Items, Labels, Regions"
          : "Search Choice, Items"
      }
      className='searchBar'
      value={searchKeyword}
      inputRef={searchRef}
      style={{ width: '100%', marginTop: "0px" }}
      handleClearSearch={handleClearSearch}
      onChange={(e) => onSearch(e.target.value)}
    />
  );
}
