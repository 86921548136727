import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ErrorIcon from '@mui/icons-material/Error';
import { useSelector } from 'react-redux';
import { getTab } from 'pages/Utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: '1px solid #ADADAD',
    marginTop: "8px",
    marginBottom: "15px",
  }
}));

export default function ChoiceTabPanel({ activeTabIndex, setActiveTabIndex, choiceID, tabName, hasComments }) {
  const hasMessagesErros = useSelector(state => Boolean(state.models.syntaxCheck[getTab(tabName)][choiceID]?.MessageCondition));
  const classes = useStyles();
  const handleChange = (event, newIndex) => {
    setActiveTabIndex(newIndex)
  };

  const tabs = [
    { label: 'Choice Settings', id: 'choice-settings' },
    { label: 'Messages', id: 'messages', icon: hasMessagesErros },
    { label: hasComments ? <b style={{ color: "#2e2e2e" }}>User Comments</b> : "User Comments", id: 'user-comments' },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.style1}>
        <Tabs value={activeTabIndex} onChange={handleChange} aria-label="styled tabs" indicatorColor="primary" textColor="primary" >
          {tabs.map((tab, id) => (
            <Tab className={classes.tabs} key={id} icon={tab.icon ? <ErrorIcon /> : null} iconPosition='end' label={tab.label} />
          ))}
        </Tabs>
      </div>
    </div>
  );
}
