import { REFRESH_REGIONALS, CLEAR_REGIONALS_FILTERS, FILTER_REGIONALS, } from 'constants/regionalsConstants';
import { GraphqlApi } from 'services/api';
import { snackBar } from './modelActions';

export const refreshRegionals = () => async (dispatch) => {
    try {
        const res = await GraphqlApi.query('regionals');
        dispatch({ type: REFRESH_REGIONALS, payload: res?.data?.regionals })
    } catch (error) {
        dispatch({ type: REFRESH_REGIONALS, payload: null })
        dispatch(snackBar(true, error.message, "error"));
    }
}

export const clearRegionalsFilters = () => (dispatch) => {
    dispatch({ type: CLEAR_REGIONALS_FILTERS })
}

export const filterRegionals = (payload) => (dispatch) => {
    dispatch({ type: FILTER_REGIONALS, payload })
}
