import { useSelector } from 'react-redux';
import { Grid, TextField, Typography, Box } from '@mui/material';
import ErrorToolTip from 'pages/Common/ErrorToolTip';
import { updateChoiceItemFields } from 'actions/modelActions';
import useGlobalStore from 'hooks/useGlobalStore';
import { getTab } from 'pages/Utils';
import { useStyles } from 'pages/EditModel/Tabs/styles';
import DuplicatePartDescriptionsTooltip from 'pages/EditModel/DuplicatePartDescriptionsTooltip';

const RegionalItemEdit = ({ itemID, choiceID, tabName  }) => {
  const classes = useStyles();
  const dataTabName = getTab(tabName);
  let data = useSelector(state => state.models[dataTabName][choiceID]?.items[itemID])
  const invalidConditions = useSelector(state => state.models.syntaxCheck[dataTabName][choiceID]?.items[itemID])
  const [itemData, setItemData] = useGlobalStore(data,updateChoiceItemFields, { choiceID, itemID, tabCategory: data.tabCategory });
  const invalidFilterConditions = invalidConditions?.FilterCondition;
  const partDescriptionErrors = invalidConditions?.PartDescription;

  const handleOnChange = (event, field) => {
    let newVal;
      newVal = event.target.value;
    

    if (field === 'partDescription' && newVal.length > 250) {
      newVal = newVal.slice(0, 250);
    }

    setItemData((prevData) => ({
      ...prevData,
      [field]: newVal,
      edited: true
    }));
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };



  return (
    <Grid container className={classes.root}>
        <Grid container>
          <Grid item xs={3}>
          <TextField
          disabled
          label="Part#"
          id="ItemPartNumber"
          variant="filled"
          margin="dense"
          InputProps={{ disableUnderline: true }}
          value={itemData?.partNumber || ""}
        />
          </Grid>
          <Grid item xs={3}>
          <TextField
          disabled
          label="Value ID"
          id="VcItemID"
          variant="filled"
          margin="dense"
          InputProps={{ disableUnderline: true }}
          value={itemData?.isSystemChoice ? "" : itemData?.valueID || ""}
        />
          </Grid>
          <Grid item xs={6}>
          <TextField
          id="ChoiceItemDesc"
          variant="filled"
          margin="dense"
          label="Part Description (VC)"
          InputProps={{ disableUnderline: true }}
          disabled={itemData?.isSystemChoice}
          value={itemData?.partDescription || ""}
          onChange={(event) => handleOnChange(event, "partDescription")}
        />
            <div className={classes.partDesc}>
              <Box>
              <DuplicatePartDescriptionsTooltip
               itemData={itemData}
                /></Box>
              <Typography>
                {partDescriptionErrors?.length || itemData?.partDescription?.length > 30 ? (
                  <span style={{ fontSize: "smaller", color: "red" }}>{itemData?.partDescription?.length} chars.</span>
                ) : (
                  <span style={{ fontSize: "smaller" }}>{itemData?.partDescription?.length} chars.</span>
                )}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.subtitle}> Filter Condition</Typography>
          <TextField
            id="ItemFilterCondition"
            variant="filled"
            margin="dense"
            multiline
            minRows={3}
            label="Filter Condition (When to offer for selection)"
            InputProps={{classes: { input: classes.textarea }, disableUnderline: true }}
            value={itemData?.filterCondition || ""}
            onKeyPress={(event) => handleEnter(event)}
            onChange={(event) => handleOnChange(event, "filterCondition")}
          />
          <Box>{invalidFilterConditions?.length > 0 && (<ErrorToolTip data={invalidFilterConditions} />)}</Box>
        </Grid>
        </Grid>
        <Typography className={classes.subtitle}> CVG</Typography>
        <Grid container>
          <Grid item xs={12}>
          <TextField
            disabled
            id="ItemCvg"
            variant="filled"
            margin="dense"
            label="CVG"
            multiline
            InputProps={{ classes: { input: classes.textarea }, disableUnderline: true, }}
            value={itemData?.cvg || ""}
          />
          </Grid>
        </Grid>
    </Grid>
  );
};

export default RegionalItemEdit;