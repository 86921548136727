import React, { useState } from "react";
import { Grid } from "@mui/material";
import Messages from "../Tabs/Messages"
import PreSelectedItems from "../Tabs/PreSelectedItems"
import ItemsTabPanel from "components/ItemsTabPanel";
import ItemSettings from "../Tabs/ItemSettings";
import { Comments } from '../Tabs/Comments';

const ItemEdit = ({ tabName, data }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabs = [
    <ItemSettings
      key={`itemsettings-${data.itemID}-${data.parentId}-${tabName}`}
      itemID={data.itemID}
      choiceID={data.parentId}
      tabName={tabName}
    />,
    <PreSelectedItems
      key={`preselected-${data.itemID}-${data.parentId}-${tabName}-${JSON.stringify(data.defaultComponents)}`}
      choiceID={data.parentId}
      isSystemChoice={data.isSystemChoice}
      itemID={data.itemID}
      tabName={tabName}
    />,
    <Messages
      key={`messages-${data.itemID}-${data.parentId}-${tabName}-${JSON.stringify(data.messages)}`}
      itemID={data.itemID}
      choiceID={data.parentId}
      tabName={tabName}
    />,
    <Comments
      key={`comments-${data.itemID}-${data.parentId}-${tabName}-${JSON.stringify(data.comments)}`}
      itemID={data.itemID}
      choiceID={data.parentId}
      tabName={tabName}
    />
  ]

  return (
    <>
      <Grid>
        <ItemsTabPanel
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          choiceID={data.parentId}
          itemID={data.itemID}
          tabName={tabName}
          hasComments={Boolean(data.comments?.length)}
        />
      </Grid>
      {tabs[activeTabIndex]}
    </>
  );
};

export default ItemEdit;
