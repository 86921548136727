import {
    REFRESH_REGIONALS,
    CLEAR_REGIONALS_FILTERS,
    FILTER_REGIONALS,
} from 'constants/regionalsConstants';
import { applyFilters } from 'pages/Utils/FilterUtil';

const initRegionals = {
    regionals: [],
    filters: {},
    filteredRegionals: [],
    isLoading: true
}

export const regionalsReducer = (state = initRegionals, { type, payload }) => {
    switch (type) {
        case REFRESH_REGIONALS: {
            const newData = {regionals: payload} || initRegionals;
            const newState = {
                ...newData,
                filters: state.filters,
                isLoading: false
            };

            const itemsToFilter = payload?.length ? payload : [];
            const filteredRegionals = applyFilters(Object.values(state.filters), itemsToFilter)

            return {
                ...newState,
                filteredRegionals
            };
        }

        case CLEAR_REGIONALS_FILTERS: {
            return {
                ...state,
                filters: {},
                filteredRegionals: state.regionals
            };
        }

        case FILTER_REGIONALS: {
            const { name, searchText, matchFields, type } = payload;
            const filters = {
                ...state.filters,
                [name]: {
                    value: searchText,
                    type,
                    matchFields
                }
            }
            
            const filteredRegionals = applyFilters(Object.values(filters), state.regionals)

            return {
                ...state,
                filters,
                filteredRegionals
            };
        }

        default: return state;
    }
}

export default regionalsReducer;