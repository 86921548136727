import React, { useEffect, useState } from "react";
import { CircularProgress } from '@mui/material';
import "./styles.css";

export default function StatusInfo({ loading, loadingMessage, error, message }) {
  const [seconds, setSeconds] = useState(1);

  useEffect(() => {
    let interval = null;
    if (loading) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 300);
    } else if (!loading && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [loading, seconds]);

  if (loading) {
    return (
      <div className="info">
        <div id="output">{loadingMessage}</div>
        <CircularProgress />
      </div>
    )
  }
  if (error) {
    return <div className="info error">{error} !</div>
  }
  return <div className="info message">{message}</div>
}
