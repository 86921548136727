import { CLEAR_DASHBOARD_FILTERS, FILTER_DASHBOARD, REFRESH_DASHBOARD } from '../constants/dashboardConstants'
import { GraphqlApi } from 'services/api';
import { snackBar } from './modelActions';

export const refreshDashboard = () => async (dispatch) => {
    try {
        const res = await GraphqlApi.query('dashboard');
        dispatch({ type: REFRESH_DASHBOARD, payload: res?.data?.dashboard })
    } catch (error) {
        dispatch({ type: REFRESH_DASHBOARD, payload: null })
        dispatch(snackBar(true, error.message, "error"));
    }
}

export const updateModel = (rowDetails, productLineForUpdModel, productNameForUpdModel, usersForUpdModel, isRelease) => (dispatch) => {
    GraphqlApi.query("updateModel", {
        model: {
            kmatID: rowDetails.kmatID,
            productLineID: productLineForUpdModel,
            productName: productNameForUpdModel,
            assignedTo: usersForUpdModel,
            isReleased: isRelease
        },
    }).then(() => {
        dispatch(refreshDashboard());
        dispatch(snackBar(true, "Model Updated Successfully", "success"));
    }).catch((error) => {
        dispatch(snackBar(true, error.message, "error"));
    });
}

export const importModel = (recordID, kmatID, history) => async (dispatch) => {
    try {
        const res = await GraphqlApi.query("importModelV2", { model: { recordID: recordID, kmatID: kmatID } });
        history.push({ pathname: "/model/" + res?.data.importPM.kmatID + "/" + res.data.importPM.modVer });
    } catch (error) {
        dispatch(snackBar(true, error.message, "error"));
    }
}

export const clearDashboardFilters = () => (dispatch) => {
    dispatch({ type: CLEAR_DASHBOARD_FILTERS })
}

export const filterDashboard = (payload) => (dispatch) => {
    dispatch({ type: FILTER_DASHBOARD, payload })
}
