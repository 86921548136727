import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteItem } from 'actions/modelActions';
import { snackBar } from "actions/modelActions";
import ConfirmationDialog from "components/ConfirmationDialog";

const ItemsDeleteDialog = (props) => {
  const { itemID, node, choice, onHandleCb, dataType, kmatId, currentVersion } = props;
  const dispatch = useDispatch();
  const [openDelete, setOpenDelete] = useState(true);
  const deletedPartNumber = itemID.replace("-", " ");

  const handleDeleteClose = () => {
    setOpenDelete(false);
    props.setIsDeleteClicked(false);
  };

  const handleDeleteItem = () => {
    const query = `delete${dataType === "regional" ? "Regional" : ""}Item`;
    deleteItem({ kmatID: kmatId, modVer: currentVersion, itemID, query: query })
      .then((data) => {
        if (data?.[query] === null) {
          const choiceItems = Object.values(choice.items);
          const currentIndex = choiceItems.findIndex((item) => item.id === itemID);

          handleDeleteClose();
          onHandleCb(choiceItems[currentIndex + 1] ?? choiceItems[currentIndex - 1] ?? choice);
          dispatch({ type: "DELETE_ITEM", payload: { choiceID: node.parentId, itemID } });
          dispatch(snackBar(true, `Part Number "${itemID}" has been removed from KB Model`, 'success'));
        }
      })
      .catch((error) => dispatch(snackBar(true, error.message)))
  }

  return (
    <ConfirmationDialog
      open={openDelete}
      title="Confirm Delete Item"
      content={<>You are about to delete<b> '{deletedPartNumber}' </b> item? Are you sure you want to delete it?</>}
      onClose={handleDeleteClose}
      onConfirm={handleDeleteItem}
      caption="Delete Item"
      bgColor="#BE1313"
    />
  );
};

export default ItemsDeleteDialog;
