import React, { useState } from "react";
import { useSelector } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import { REGIONAL_TAB, TABS } from "constants/modelConstants";
import { Grid, Tab, Tabs } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import PropTypes from "prop-types";
import { getTab } from "pages/Utils";
import ConfirmationDialog from "components/ConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  tabBorder: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  activeDot: {
    height: "20px",
    width: "20px",
    marginLeft: "5px",
  },
  dot: {
    height: "16px",
    width: "16px",
    marginLeft: "5px",
  }
}));

function StepperPanel({ onActiveStep, isRestrictedStepChanging, handleCancelClick }) {
  const [nextStepIndex, setNextStepIndex] = useState(null);
  const classes = useStyles();

  const navigateToNextStep = (step) => { onActiveStep(step) };

  const handleOnClose = () => { setNextStepIndex(null) }

  const handleSelectTab = (event, stepIndex) => {
    if (isRestrictedStepChanging) {
      return setNextStepIndex(stepIndex);
    }
    navigateToNextStep(stepIndex)
  };

  const handleConfirm = () => {
    handleCancelClick();
    setNextStepIndex(null);
    navigateToNextStep(nextStepIndex)
  }

  const { activeTab, dataCarePack, dataCustomService, dataDeploy, dataMandA, dataMisc, dataPM, dataSpares, kmat, syntaxCheckDone, syntaxCheck } = useSelector((state) => state.models)

  const isUpdatedTabData = (tab) => {
    const tabData = {
      PM: dataPM,
      Misc: dataMisc,
      CarePack: dataCarePack,
      CustomService: dataCustomService,
      Spares: dataSpares,
      MandA: dataMandA,
      Deploy: dataDeploy,
    };

    const isTabUpdated = tabData[tab] || [];
    return Object.values(isTabUpdated).some((res) => res.status !== "{}");
  };

  const iterableTabs = React.useMemo(() => {
    const indexRegionalTab = TABS.findIndex(tab => tab.id === REGIONAL_TAB);
    return kmat.lineID === "TEMPLATE" ? TABS.slice(0, indexRegionalTab) : TABS
  },
    [kmat.lineID]
  );

  return (
    <div style={{ marginBottom: "20px" }}>

      {nextStepIndex !== null &&
        <ConfirmationDialog
          open={nextStepIndex !== null}
          title="Discard changes"
          content={<>Are you sure you want to discard the changes you've made?</>}
          onClose={handleOnClose}
          onConfirm={handleConfirm}
          caption="Discard"
          bgColor="#BE1313"
        />}

      <Grid container className={classes.tabBorder}>
        <Tabs
          value={iterableTabs.findIndex(tab => tab.id === activeTab)}
          onChange={handleSelectTab}
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
        >
          {iterableTabs?.map(
            ({ id, title }) => {
              const tabData = syntaxCheck[getTab(id)]
              const isTabUpdated = isUpdatedTabData(id);
              return (
                <Tab
                  key={title}
                  iconPosition="end"
                  icon={syntaxCheckDone && tabData?.hasError ? (
                    <ErrorIcon
                      className={activeTab === id ? classes.activeDot : classes.dot}
                    />
                  ) : null}
                  label={isTabUpdated ? <b style={{ color: "#616161" }}>{title}</b> : title}
                />
              );
            }
          )}
        </Tabs>
      </Grid>
    </div>
  );
}

StepperPanel.propTypes = {
  onActiveStep: PropTypes.func.isRequired,
  isRestrictedStepChanging: PropTypes.bool.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
}

export default StepperPanel;
