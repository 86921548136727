import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
	Box,
	CircularProgress,
	Grid,
	Paper,
} from "@mui/material";
import { cvgTabSelection, fetchData, tabSelection, updateCancelInit, updateSaveInit, syntaxCheck, snackBar } from "actions/modelActions";
import RegionalStepperPanel from "../../components/StepperPanel/regionalIndex";
import StatusInfo from "components/StatusInfo";
import { STEP_NAMES_REGIONAL_TABS, SYNTAX_CHECK_REQUIRED, SYNTAX_CHECK_DONE, CVGMGMT_TAB, WWNA_TAB, WW_TAB } from "constants/modelConstants";
import KmatBox from "./ModelHeader/KmatBox";
import ActionableButtons from "./ActionableButtons";
import "./EditModel.scss";
import { useStyles } from "./IndexHelpers";
import { getTab } from "pages/Utils";
import CVGEdit from "./CVGEdit/CVGEdit";
import RegionalItemEdit from "pages/RegionalModel/RegionalItem";
import RegionalChoice from "pages/RegionalModel/RegionalChoice";
import { rememberProperty, undoProperty } from "actions/undoActions";
import { StaticData } from "./Tabs/StaticData";
import CvgIndex from "./cvgIndex";
import Label from "./label";
import CheckBoxTreeContainer from "components/ReactCheckboxTree/CheckBoxTreeContainer";
import { useCallback } from "react";

const EditRegional = (props) => {
	const { dataType } = props;
	const kmatId = props.match.params.kmatId;
	const classes = useStyles();
	const dispatch = useDispatch();
	const models = useSelector((state) => state.models);
	const {
		activeTab,
		currentVersion,
		error,
		kmat,
		loading,
		publishLoading,
		syntaxCheckRequired,
        activeCVGTab,
        dataWWNA
	} = models;
	const activeDataSet = models[getTab(activeTab)];
	const activeCvgDataSet = models[getTab(activeCVGTab)];
	const datasetValues = activeDataSet && Object.values(activeDataSet);
	const cvgDatasetValues = activeCvgDataSet && Object.values(activeCvgDataSet);
	const [isSaving, setIsSaving] = useState(false);
	const [cvgsFilter, setCvgsFilter] = useState("");
	const [activeNode, setActiveNode] = useState({
    WWNA: null,
    WWLA: null,
    WWEU: null,
    WWAP: null,
    NA: null,
    LA: null,
    EU: null,
    AP: null,
    NAGSPA: null,
    WW: null,
    CVGMGMT: null,
	});


	const editTitle  = useMemo(() => {
		if (activeTab === CVGMGMT_TAB) return "CVG Editor";

		const activeNodeContent = activeNode[activeTab];

		if (!activeNodeContent) return null;
		if (activeNodeContent.itemID) return 'Item Details';
		if (activeNodeContent.dataType === "SYS") return 'System Choice';

		return 'Choice Details';
	}, [activeNode, activeTab]);


	const onNodeSelect = useCallback((n) => {
		setActiveNode((prevState) => ({ ...prevState, [activeTab]: n }))
		if (n) {
		[n.choiceID, n.parentId].forEach(id => {
			if (id && id !== "0") {
			  dispatch(rememberProperty(id));
			}
		  });
		}
	},[activeTab])

	const createTreeItems = () => {
		if (!kmat) return;

		if (activeTab === CVGMGMT_TAB) {
			setActiveNode({ ...activeNode, [activeCVGTab]: cvgDatasetValues?.[0] || null });
		} else {
			setActiveNode({ ...activeNode, [activeTab]: datasetValues?.[0] || null });
		}
	};


	const handleSaveClick = async () => {
		setIsSaving(true);
		dispatch(updateSaveInit(true));
		setIsSaving(false);
	};




  const handleCancelClick = () => {
	const tab = activeTab === CVGMGMT_TAB ? activeCVGTab : activeTab;
	const itemID = activeNode[tab]?.itemID;
	const choiceID = itemID ? activeNode[tab].parentId : activeNode[tab]?.id;
		// executed when items are bulk edited
		if (!itemID && !choiceID && activeTab !== CVGMGMT_TAB) {
			dispatch(updateCancelInit(true));
			return
		}
		dispatch(undoProperty({ itemID, choiceID }));
	};
	const handleActiveStep = (tabIndex) => {
		dispatch(tabSelection(STEP_NAMES_REGIONAL_TABS[tabIndex]));
		setActiveNode((prevActiveNode) => {
			const newActiveTab = STEP_NAMES_REGIONAL_TABS[STEP_NAMES_REGIONAL_TABS[tabIndex] === CVGMGMT_TAB ? 0 : tabIndex];
			return ({ ...prevActiveNode, [newActiveTab]: Object.values(models[getTab(newActiveTab)])?.[0] || null })
		});
		setCvgsFilter("")
		dispatch(cvgTabSelection(WWNA_TAB));
		dispatch(snackBar(false, ""));
	};

	useEffect(() => {
		if (syntaxCheckRequired) {
			batch(() => {
				dispatch({ type: SYNTAX_CHECK_DONE, payload: true });
				dispatch({ type: SYNTAX_CHECK_REQUIRED, payload: false });
				dispatch(syntaxCheck(kmatId, null));
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentVersion, dataType, dispatch, kmatId, syntaxCheckRequired]);

	// Selecting the first choice from 'WWNA' tab, when the model is fetched or the version is changed.
	// Selecting the first choice from 'activeCVGTab' tab, when the 'CVGMGMT' tab is selected.
	useLayoutEffect(() => {
		createTreeItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [kmat, activeCVGTab]);

	useEffect(() => {
		batch(() => {
			dispatch(fetchData(kmatId, "1.1", dataType));
      		dispatch(tabSelection(WWNA_TAB));
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, kmatId, dataType]);


  const isOnCVGTab = activeTab === CVGMGMT_TAB;
  const areActionableButtonsVisible = (isOnCVGTab && Object.entries(activeNode[activeCVGTab]?.items ?? {}).length > 0) || (!isOnCVGTab && activeNode[activeTab]?.dataType !== "SYS");

	const renderItemEdit = () => {
		if (activeTab === CVGMGMT_TAB && Object.entries(activeNode[activeCVGTab]?.items ?? {}).length) {
            return <CVGEdit key={`displayEditCVG-${activeNode[activeCVGTab]}${JSON.stringify(activeNode[activeCVGTab])}`} choiceID={activeNode[activeCVGTab]?.choiceID} activeCVGTab={activeCVGTab} cvgsFilter={cvgsFilter} />;
        }

		if (!activeNode[activeTab]) {
			return <div className={classes.noItem}>No data found!</div>;
		}
		if (activeNode[activeTab].itemID) {
			return (
				<RegionalItemEdit
					key={`displayEdit-${activeTab}-${activeNode[activeTab].id}`}
					tabName={activeTab}
					data={activeNode[activeTab]}
                    itemID={activeNode[activeTab].itemID}
                    choiceID={activeNode[activeTab].parentId}
				/>
			);
		}
		return (
			<RegionalChoice
				key={`displayChoiceEdit-${activeTab}-${activeNode[activeTab].choiceID}`}
				data={activeNode[activeTab]}
			/>
		);
	}; 


  

	return loading || error || !dataWWNA ? (
    <StatusInfo
      loading={loading || !datasetValues?.length}
      error={error}
      loadingMessage={"Loading.... please wait"}
    />
  ) : (
    <div className={classes.root}>
      {publishLoading && (
        <Box
          style={{ position: "fixed", top: "50%", left: "50%", zIndex: "99" }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
	  <KmatBox kmat={kmat} dataType={dataType} activeTab = {activeTab} />
      <RegionalStepperPanel 
	  onActiveStep={handleActiveStep}
	  handleCancelClick={handleCancelClick} 
	  />
      {activeTab === WW_TAB 
      	? (<StaticData datasetValues={datasetValues} />)
		  : (<Grid container spacing={1} className={classes.root2} style={{ display: "flex" }}>
			  <Grid item sm={12} md={4} style={{ display:  "flex", flexDirection: "column" }}>
				  <Paper className={classes.paper}>
					  {activeTab === CVGMGMT_TAB 
									? <CvgIndex activeCVGTab={activeCVGTab} dataType={dataType} activeNode={activeNode} cvgDatasetValues={cvgDatasetValues} cvgsFilter={cvgsFilter} setActiveNode={setActiveNode} setCvgsFilter={setCvgsFilter} />
						  : <>
							  <div className={classes.treeview}>
								  <CheckBoxTreeContainer 
                                              checked={() => {}}
											  dataType={dataType}
											  activeDataSet={activeDataSet}
											  activeTab={activeTab}
											  onSelect={onNodeSelect}
											  currentVersion={currentVersion} 
											  kmatId = {kmat.kmatID}
                  />
							  </div>						 
						  </>
					  }
				  </Paper>
			  </Grid>

			  <Grid item sm={12} md={8}>
				  <Paper className={classes.paper} style={{ marginLeft: "15px" }}>
				  <Label editTitle={editTitle} activeNode={activeNode} />
					  {renderItemEdit()}
					  <div className={classes.actionableButtons}>
						  {areActionableButtonsVisible && (
							  <ActionableButtons isSaving={isSaving} handleSaveClick={handleSaveClick} handleCancelClick={handleCancelClick} />
						  )}
					  </div>
				  </Paper>
			  </Grid>
		  </Grid>)
	  }
    </div>
  );
};

export default withRouter(EditRegional);