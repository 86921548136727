import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { snackBar } from 'actions/modelActions';

export default function GenericSnackbar() {
    const dispatch = useDispatch();
    const { showGenericSnackbar, genericSnackbarMessage, snackbarSeverity } = useSelector((state) => state.models);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(snackBar(false, ''));
    };

    return (
        <div>
            <Snackbar
                open={showGenericSnackbar}
                autoHideDuration={6000}
                onClose={handleClose}
                message={genericSnackbarMessage}
                TransitionComponent={Slide}
            >
                <Alert onClose={handleClose} severity={snackbarSeverity} sx={{ width: '100%', borderRadius: "15px", marginLeft: "150px", backgroundColor: "black", color: "white", ...(snackbarSeverity === "success" && { ".MuiAlert-icon": { color: "#2e7d32" } }) }}>
                    {genericSnackbarMessage}
                </Alert>
            </Snackbar>
        </div >
    );
}