export const getNewModels = `query  {
  getNewModels {
    productModels {
      recordID
      kmatID
      productName
      productLineID
      status
      fileLastModDate
      lastUpdFileName
      disabled
    },
    productLines {
        id
        name
        count
        isTemplate
    },
    role,
    totalRecords
  }
}`

