import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useStyles } from "../IndexHelpers";
import { StyledTableCell, StyledTableRow, useRowStyles } from "../MultiItemEdit/MultiItemEditHelpers";
import { REGIONAL_TAB } from "constants/modelConstants";
import { useSelector } from "react-redux";

export const StaticData = ({ datasetValues }) => {
  const { paper } = useStyles();
  const { root, accordionDetails } = useRowStyles();
  const isOnRegionalTab = useSelector((state) => state.models.activeTab === REGIONAL_TAB);

  return (
    <Table aria-label="collapsible table">
      <TableBody>
        <TableRow>
          <TableCell>
            <Paper className={paper} style={{ marginLeft: "15px" }}>
              {datasetValues?.map((row) => {
                const choiceID = row.choiceID;

                return (
                  <Table key={choiceID} style={{maxWidth: '100%'}}>
                    <TableBody>
                      <TableRow>
                        <TableCell className={root}>
                          <Accordion sx={{ boxShadow: "none" }}>
                            <AccordionSummary aria-controls={`choice id ${choiceID} content`} expandIcon={<KeyboardArrowDownIcon />} id={choiceID}>
                              {row.name}
                            </AccordionSummary>
                            <AccordionDetails className={accordionDetails}>
                              <TableContainer component={Paper} style={{ margin: "5px 0px 8px 0px" }}>
                                <Table aria-label="customized table">
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell width={5} />
                                      <StyledTableCell width={50}>Part#</StyledTableCell>
                                      <StyledTableCell width={50}>Value Id</StyledTableCell>
                                      <StyledTableCell width={300}>Description</StyledTableCell>
                                      <StyledTableCell width={100}>Filter Condition</StyledTableCell>
                                      <StyledTableCell width={100}>CVG</StyledTableCell>
                                      {isOnRegionalTab && <StyledTableCell width={50}>Region</StyledTableCell>}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {Object.values(row.items).map((item) => (
                                      <StyledTableRow key={item.itemID}>
                                        <StyledTableCell />
                                        <StyledTableCell>{item.partNumber}</StyledTableCell>
                                        <StyledTableCell>{item.isSystemChoice ? null : item.valueID}</StyledTableCell>
                                        <StyledTableCell>{item.description}</StyledTableCell>
                                        <StyledTableCell>{item.filterCondition}</StyledTableCell>
                                        <StyledTableCell>{item.cvg}</StyledTableCell>
                                        {isOnRegionalTab && <StyledTableCell>{item.comments}</StyledTableCell>}
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </AccordionDetails>
                          </Accordion>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                );
              })}
            </Paper>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
