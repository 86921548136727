import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    popOver: {
      "& .MuiPopover-paper": {
        padding: "10px 0px",
        textAlign: "center",
        minWidth: theme.spacing(30),
      },
    },
    root: {
      flexGrow: 1,
      marginBottom: "15px",
      padding: "8px 0px 8px 0px",
      "& 	.MuiChip-root": {
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 400,
        fontFamily: "Forma DJR Micro !important",
        marginBottom: "3px",
        width: "109px",
      }
    },
    title: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 400,
      fontStyle: "normal"
    },
    cardView: {
      height: "35px",
      width: "40px",
    },
    gridContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    gridItem: {
      display: "flex",
      alignItems: "center",
      columnGap: "9px",
    },
    chipColor: {
      backgroundColor: "#edbb02 !important",
      color: "black !important",
    },
    createButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      padding: theme.spacing(0.5),
      textAlign: "left",
      color: theme.palette.text.primary,
      display: "flex",
      alignItems: "center",
      marginTop: "10px",
      height: "60px",
      borderRadius: "12px",
      position: 'relative',
    },
    menuItem: {
      paddingTop: '10px',
      borderTop: '1px solid gray',
      justifyContent: "space-between"
    },
    publish: {
      backgroundColor: " #0096d6 !important",
      color: "#ffffff !important",
      height: "43px",
      borderRadius: "11px !important",
      marginRight: "10px",
      padding :"8px 12px 8px 18px",
      "&:disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.12) !important",
        color: "rgba(0, 0, 0, 0.26) !important"
      },
    },
    moreIcon: {
      borderRadius: "10px",
      borderColor: "#0278AB",
      height: "39px"
    },
    checkIcon: {
      color: "#228B22 !important",
      backgroundColor: "#E8E9EB",
      cursor: "pointer",
      borderRadius: "11px",
      margin: " 0 5px",
      padding: " 8px 20px 8px 20px",
      "&:hover": {
        backgroundColor: "#E8E9EB"
      },
  
    },
    check: {
      marginLeft: "5px",
    },
  }));


  export const publishMethods = {
    canPublishValidation: 'Validate',
    canPublishProduction: 'Production',
    canPublishScit: 'SCIT',
    canPublishPilot: 'Pilot'
  }