import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import { withRouter } from 'react-router-dom';
import { GraphqlApi } from 'services/api';
import {
    Box,
    Button,
    Typography
} from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import "./ui-specs.css";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { snackBar } from "actions/modelActions";

const consumerColumns = [
    {
        field: "id",
        flex: 1,
        headerName: "Consumer Choice IDs",
        sortable: false,
        headerAlign: "left"
    }
];

const commercialColumns = [
    {
        field: "id",
        flex: 1,
        headerName: "Commercial Choice IDs",
        sortable: false,
        headerAlign: "left"
    }
];

const printColumns = [
    {
        field: "id",
        flex: 1,
        headerName: "Print Choice IDs",
        sortable: false,
        headerAlign: "left"
    }
]

function updateRowPosition(initialIndex, newIndex, rows) {
    return new Promise((resolve) => {
        const rowsClone = [...rows];
        const row = rowsClone.splice(initialIndex, 1)[0];
        rowsClone.splice(newIndex, 0, row);
        resolve(rowsClone);
    });
}

function UISpecs() {
    const [commercialData, setCommercialData] = useState([]);
    const [consumerData, setConsumerData] = useState([]);
    const [printData, setPrintData] = useState([]);
    const [formattedCommercialData, setFormattedCommercialData] = useState([]);
    const [formattedConsumerData, setFormattedConsumerData] = useState([]);
    const [formattedPrintData, setFormattedPrintData] = useState([]);
    const [arrayOfRowIDsForConsumer, setArrayofRowIDsForConsumer] = useState([]);
    const [arrayOfRowIDsForCommercial, setArrayofRowIDsForCommercial] = useState([]);
    const [arrayofRowIDsforPrint, setArrayofRowIDsForPrint] = useState([]);
    const [value, setValue] = useState('consumer');
    const [shouldBeDisabled, setShouldBeDisabled] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        GraphqlApi.query('choiceSequence')
            .then((res) => {
                res.data.choiceSequence.forEach(eachSeq => {
                    if (eachSeq.family === 'CONS') {
                        setConsumerData(eachSeq.choiceIDs);
                        setArrayofRowIDsForConsumer(eachSeq.choiceIDs);
                    } else if (eachSeq.family === 'COMM') {
                        setCommercialData(eachSeq.choiceIDs);
                        setArrayofRowIDsForCommercial(eachSeq.choiceIDs);
                    } else {
                        setPrintData(eachSeq.choiceIDs);
                        setArrayofRowIDsForPrint(eachSeq.choiceIDs);
                    }
                })
            }).catch((error) => {
                dispatch(snackBar(true, error.message));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFormattedConsumerData(consumerData.map(eachRow => {
            return {
                id: eachRow
            }
        }));
    }, [consumerData]);

    useEffect(() => {
        setFormattedCommercialData(commercialData.map(eachRow => {
            return {
                id: eachRow
            }
        }));
    }, [commercialData]);

    useEffect(() => {
        setFormattedPrintData(printData.map(eachRow => {
            return {
                id: eachRow
            }
        }));
    }, [printData]);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleRowOrderChangeForConsumer = async (params) => {
        const newRows = await updateRowPosition(
            params.oldIndex,
            params.targetIndex,
            formattedConsumerData,
        );

        setArrayofRowIDsForConsumer(newRows.map(eachRow => {
            return eachRow.id
        }));
        setFormattedConsumerData(newRows);
        setShouldBeDisabled(false);
    };

    const handleRowOrderChangeForCommercial = async (params) => {
        const newRows = await updateRowPosition(
            params.oldIndex,
            params.targetIndex,
            formattedCommercialData,
        );

        setArrayofRowIDsForCommercial(newRows.map(eachRow => {
            return eachRow.id
        }));
        setFormattedCommercialData(newRows);
        setShouldBeDisabled(false);
    };

    const handleRowOrderChangeForPrint = async (params) => {
        const newRows = await updateRowPosition(
            params.oldIndex,
            params.targetIndex,
            formattedPrintData,
        );

        setArrayofRowIDsForPrint(newRows.map(eachRow => {
            return eachRow.id
        }));
        setFormattedPrintData(newRows);
        setShouldBeDisabled(false);
    };

    const handleUpdateChoiceSequence = () => {
        GraphqlApi.query('updateChoiceSequence', {
            model: [
                {
                    family: 'CONS',
                    choiceIDs: arrayOfRowIDsForConsumer,
                },
                {
                    family: 'COMM',
                    choiceIDs: arrayOfRowIDsForCommercial
                },
                {
                    family: 'PRINT',
                    choiceIDs: arrayofRowIDsforPrint
                }
            ]
        }).then(() => {
            window.location.reload();
        }).catch((error) => {
            dispatch(snackBar(true, error.message));
        });
    }

    return (
        <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
            <Box sx={{ display: "flex", marginBottom: "15px", marginTop: "35px" }}>
                <Typography variant='h5'>
                    UI-Specs
                </Typography>
                <Button
                    variant="outlined"
                    disabled={shouldBeDisabled}
                    sx={{ marginLeft: "35px", borderRadius: "8px" }}
                    onClick={() => handleUpdateChoiceSequence()}>
                    Update
                </Button>
            </Box>
            <Box sx={{ width: '50%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange}>
                            <Tab label="CONSUMER" value="consumer" />
                            <Tab label="COMMERCIAL" value="commercial" />
                            <Tab label="PRINT" value="print" />
                        </TabList>
                    </Box>
                    <TabPanel value="consumer">
                        <DataGridPro
                            columns={consumerColumns}
                            rows={formattedConsumerData}
                            getRowId={(row) => row.id}
                            loading={consumerData.length === 0}
                            rowHeight={38}
                            rowReordering
                            onRowOrderChange={handleRowOrderChangeForConsumer}
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnMenu
                            sx={{
                                "& .MuiDataGrid-columnSeparator": {
                                    display: "none"
                                },
                                '& .MuiDataGrid-cell': {
                                    border: 'none',
                                },
                                '& .MuiDataGrid-columnHeaderTitleContainer': {
                                    display: 'inline'
                                },
                                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus .MuiDataGrid-columndHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                    outline: 'none !important',
                                }
                            }}
                        />
                    </TabPanel>
                    <TabPanel value="commercial">
                        <DataGridPro
                            columns={commercialColumns}
                            rows={formattedCommercialData}
                            getRowId={(row) => row.id}
                            loading={commercialData.length === 0}
                            rowHeight={38}
                            rowReordering
                            onRowOrderChange={handleRowOrderChangeForCommercial}
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnMenu
                            sx={{
                                "& .MuiDataGrid-columnSeparator": {
                                    display: "none"
                                },
                                '& .MuiDataGrid-cell': {
                                    border: 'none',
                                },
                                '& .MuiDataGrid-columnHeaderTitleContainer': {
                                    display: 'inline'
                                },
                                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus .MuiDataGrid-columndHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                    outline: 'none !important',
                                }
                            }}
                        />
                    </TabPanel>
                    <TabPanel value="print">
                        <DataGridPro
                            columns={printColumns}
                            rows={formattedPrintData}
                            getRowId={(row) => row.id}
                            loading={printData.length === 0}
                            rowHeight={38}
                            rowReordering
                            onRowOrderChange={handleRowOrderChangeForPrint}
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnMenu
                            sx={{
                                "& .MuiDataGrid-columnSeparator": {
                                    display: "none"
                                },
                                '& .MuiDataGrid-cell': {
                                    border: 'none',
                                },
                                '& .MuiDataGrid-columnHeaderTitleContainer': {
                                    display: 'inline'
                                },
                                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus .MuiDataGrid-columndHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                    outline: 'none !important',
                                }
                            }}
                        />
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    )
}

export default withRouter(UISpecs);
