import React from "react";
import { useSelector } from "react-redux";
import ErrorToolTip from "../Common/ErrorToolTip";
import { getTab } from "pages/Utils";

const DuplicatePartDescriptionsTooltip = ({ itemData }) => {
  const parentId = itemData?.parentId;
  const tabCategory = itemData?.tabCategory;
  const itemID = itemData?.itemID;

  const partDescriptionErrors = useSelector((state) => itemID && parentId && tabCategory
  ? state.models.syntaxCheck[getTab(tabCategory)][parentId]?.items[itemID]?.PartDescription 
  : null
)

  if (!itemData?.parentId) return null;

  const hasDuplicatedDescription = partDescriptionErrors?.find((descriptionError) => descriptionError.key === "duplicateDescription");
  const hasInvalidDescription = partDescriptionErrors?.find((descriptionError) => descriptionError.key === "invalidDescription");

  return hasDuplicatedDescription
    ? (<ErrorToolTip data={[hasDuplicatedDescription]} descrError />)
    : hasInvalidDescription
      ? (<ErrorToolTip data={[hasInvalidDescription]} descrError />)
      : (null);
};

export default DuplicatePartDescriptionsTooltip;
