import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiFormControl-root": {
        margin: theme.spacing(1),
        width: "-webkit-fill-available",
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: 400,
        fontFamily: "Forma DJR Micro !important",
      },
      "& .MuiInputBase-input.Mui-disabled": {
        color: "#212121",
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: 400,
        backgroundColor: "whiteSmoke",
        "-webkit-text-fill-color": "#212121"
      },
      "& .MuiInputBase-input": {
        color: "#212121",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "15px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "rgb(179, 178, 178)",
          borderRadius: "20px",
          border: "1px solid rgb(179, 178, 178)",
        },
  
      },
      "& .MuiInputLabel-root": {
        fontFamily: "Forma DJR Micro !important",
        marginTop: "3px",
        marginLeft: "3px",
        fontSize: "13px !important",
        color: "#404040",
      },
      "& 	.MuiFormControlLabel-root": {
        marginRight: "70px",
      },
      "& .Mui-checked": {
        color: "#0278AB"
      },
    },
    selectionType: {
      fontSize: "14px !important"
    },
    choiceSelection: {
      marginLeft: "9px !important",
    },
    numBoxHolder: {
      display: "inline-flex",
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    readonlyBox: {
      margin: "6px",
    },
    textarea: {
      resize: "vertical",
    },
    textFieldLabel: {
      fontWeight: "bold",
      fontSize: "smaller",
      color: "#232222",
      marginLeft: "7px",
    },
    paper: {
      height: 140,
      width: 100,
    },
    error: {
      color: "red",
      fontSize: "12px",
      margin: "10px 10px",
      display: "block",
    },
    descriptionStyle: {
      color: "red",
    },
    partDesc: {
      display: "flex",
      justifyContent: "space-between",
      width: "99%",
    },
    subtitle: {
      width: "100% !important",
      margin: "5px 0px 8px 13px",
      fontSize: "24px",
      lineHeight: "32px",
      fontStyle: "normal",
      fontWeight: "400 !important",
      color: "#212121",
      display: "flex",
      flexDirection: "row"
    },
    deleteIcon: {
      marginLeft: "6px",
      marginTop: "25px",
    },
    status: {
      border: "1px solid #1C7A17",
      background: "#1C7A17",
      borderRadius: "24px",
      padding: "4px 8px 4px 8px",
      fontFamily: "Forma DJR Micro !important",
      fontSize: "12px",
  
      lineHeight: "16px",
      color: "#FFFFFF",
      marginBottom: "5px",
    },
    disabledFilledInput: {
      borderRadius: "12px",
    },
    messageWidth: {
      width: "90% !important",
    },
    deleteIconButton: {
      marginLeft: "15px",
      marginTop: "15px",
      color: "#BE1313 !important"
    },
    addMsgButton: {
      marginLeft: "10px !important",
      padding: "10px 15px 10px 7px !important",
      borderRadius: "12px !important",
      marginTop: "7px !important",
    },
    deleteButton :{
      color: "#BE1313 !important"
    }
  }));