export const getProductModelByProductLine = `query
getProductModelByProductLine($argProductLine: String!) {
    getProductModelByProductLine(argProductLine: $argProductLine) {
        kmatID
        productName
        productLineID
        status
        fileLastModDate
    },
  }`;
