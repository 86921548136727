import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import { getBtpStatus } from 'actions/btpAction';
import ActionButtons from '../ActionButtons';
import { dataGridStyles, options, container } from "../DashboardHelpers";

const defineColumns = (activeTab) => [
    {
        headerName: 'executionArn',
        field: 'executionArn',
        headerClassName: 'dashboard-data-grid-headers',
        flex: 0.5,
        sortable: false,
        renderCell: () => (<Typography variant='subtitle2'> {activeTab}</Typography>),
        renderHeader: () => (<Typography variant='subtitle2'> Env </Typography>)
    },
    {
        headerName: 'Status',
        field: 'Status',
        headerClassName: 'dashboard-data-grid-headers',
        flex: 0.7,
        sortable: false,
        renderCell: (params) => {
            const statusColors = { 'RUNNING': '#D35400', 'SUCCEEDED': '#1E8449', 'FAILED': '#C0392B' };
            return (<Typography style={{ color: statusColors[params.row.status] || 'white' }} variant='subtitle2'>{params.row.status}</Typography>);
        },
        renderHeader: () => (<Typography variant='subtitle2'> Status </Typography>)
    },
    {
        headerName: 'Start Date',
        field: 'StartDate',
        headerClassName: 'dashboard-data-grid-headers',
        headerAlign: 'left',
        flex: 1.5,
        sortable: false,
        renderCell: (params) => <Typography variant='subtitle2'>{new Date(params.row.startDate).toLocaleDateString('en-US', options)}</Typography>,
        renderHeader: () => <Typography variant='subtitle2'>Start Date</Typography>
    },
    {
        headerName: 'End Date',
        field: 'EndDate',
        headerClassName: 'dashboard-data-grid-headers',
        headerAlign: 'left',
        flex: 1.5,
        sortable: false,
        renderCell: (params) => params.row.stopDate && <Typography variant='subtitle2'>{new Date(params.row.stopDate).toLocaleDateString('en-US', options)}</Typography>,
        renderHeader: () => <Typography variant='subtitle2'>End Date</Typography>
    },
];

function BtpEnvironment({ checkedRows, Api, activeTab }) {
    const dispatch = useDispatch();
    const { getBTPStatus } = useSelector(state => state.btps);

    useEffect(() => {
        dispatch(getBtpStatus(Api));
    }, [dispatch]);

    return (
        <Box sx={{ ...container, maxHeight: "185px", maxWidth: "1350px" }}>
            <ActionButtons title="Environment to Refresh" checkedRows={checkedRows} Api={Api} />
            {getBTPStatus?.executionDetail && (
                <DataGrid
                    columns={defineColumns(activeTab)}
                    rows={[getBTPStatus?.executionDetail]}
                    getRowId={(row) => row.executionArn}
                    rowHeight={48}
                    headerHeight={40}
                    className='dashboard-data-grid'
                    disableSelectionOnClick
                    getRowClassName={(params) => `${params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'}`}
                    headerClassName='dashboard-grid-headers'
                    sx={dataGridStyles}
                    disableColumnFilter
                    disableColumnMenu
                    hideFooter
                />
            )}
        </Box>
    );
}

export default withRouter(BtpEnvironment);