export const getUsers = `query {
    kbUsersWithRoles{
        users {
          firstName
          lastName
          email
          role
          roles{
            name
            id
          }
        }
        roles{
          id
          name
        }
      }
}`
