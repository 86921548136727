

export const CustomTabPanel = ({ children, value, index, ...rest }) => {
    const valueEnum = {
        production: 0,
        scit: 1,
        pilot: 2,
    }
    return (
        <div
            role="tabpanel"
            hidden={valueEnum[value] !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ height: '100%', width: '100%', maxWidth: '700px' }}
            {...rest}
        >
            {valueEnum[value] === index ? children : 'no items'}
        </div>
    );
}