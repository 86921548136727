export const pcxModels = `query {
    pcxModels {
        models {
            kmatID
            name
            productLineID
            productLineName
            isFavorite
        }
        productLines {
            id
            name
        }
    }
}`;
 
export const pcxFiles = `query PCXFiles( $kmatID: String!) {
    pcxFiles( kmatID: $kmatID ) {
        name
        kind
    }
}`;
 
export const pcxDownload = `query ($files: [PCXDownloadRequest!]!) {
    pcxDownload( files: $files ) {
        name
        content
    }
}`;

export const addFavorite = `mutation($kmatID: String!) {
    addFavorite(kmatID: $kmatID)
}`

export const removeFavorite = `mutation($kmatID: String!) {
    removeFavorite(kmatID: $kmatID)
}`

// PCXDownloadRequest: [{name, kind}]