import Cookie from "js-cookie";

const setCookie = (name, attr) => {
  Cookie.set(name, attr);
}

const getCookie = (name) => {
  return Cookie.get(name);
}

const clearCookie = (name, attr) => {
  Cookie.remove(name, attr);
}

const clearAllCookies = () => {
  Cookie.remove("userInfo");
  Cookie.remove("access_token");
  Cookie.remove("expiresAt");
  Cookie.remove("access_token", { path: "/", domain: ".hpicloud.net" });
  Cookie.remove("expires", { path: "/", domain: ".hpicloud.net" });
  Cookie.remove("access_token", { path: "/", domain: ".hp.com" });
  Cookie.remove("expires", { path: "/", domain: ".hp.com" });
  console.log("INFO cleared");
}

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
      const later = () => {
          clearTimeout(timeout);
          func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
  };
}

export {
  setCookie,
  getCookie,
  clearCookie,
  clearAllCookies
}