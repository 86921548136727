export const filterArrayByString =(mainArr, searchText, keys = null)=> {
    if (searchText === '') {
        return mainArr;
    }
    searchText = searchText.toLowerCase();
    return mainArr.filter(itemObj => {
        return searchInObj(itemObj, searchText, keys);
    });
};

const searchInObj =(itemObj, searchText, keys = null)=> {
    const props = keys ?? Object.keys(itemObj);
    for (const prop in itemObj) {
        // if (!props.includes(prop)) continue;
        if (!itemObj.hasOwnProperty(prop)) {
            continue;
        }

        const value = itemObj[prop];

        if (typeof value === 'string') {
            if (searchInString(value, searchText)) {
                return true;
            }
        }

        else if (Array.isArray(value)) {
            if (searchInArray(value, searchText)) {
                return true;
            }
        }

        if (typeof value === 'object') {
            if (searchInObj(value, searchText, props)) {
                return true;
            }
        }
    }
}

const searchInArray =(arr, searchText)=> {
    for (const value of arr) {
        if (typeof value === 'string') {
            if (searchInString(value, searchText)) {
                return true;
            }
        }

        if (typeof value === 'object') {
            if (searchInObj(value, searchText)) {
                return true;
            }
        }
    }
}

const searchInString =(value, searchText)=> {
    return value.toLowerCase().includes(searchText);
}
