import { REFRESH_FILES, CLEAR_FILES_FILTERS, FILTER_FILES, TOGGLE_IS_FAVORITE, } from 'constants/filesConstants';
import fileDownload from 'js-file-download';
import { GraphqlApi } from 'services/api';
import { u8arrFromBase64EncodedString } from 'utils/string-utils';
import { snackBar } from './modelActions';

export const refreshFiles = () => async (dispatch) => {
  try {
    const res = await GraphqlApi.query('pcxModels');
    dispatch({ type: REFRESH_FILES, payload: res?.data?.pcxModels })
  } catch (error) {
    dispatch({ type: REFRESH_FILES, payload: null })
    dispatch(snackBar(true, error.message, "error"))
  }
}

export const toggleIsFavorite = (kmatID, isFavorite) => async (dispatch) => {
  const queryString = isFavorite ? "removeFavorite" : "addFavorite";
  try {
    const { data } = await GraphqlApi.query(queryString, { kmatID });

    if (data?.[queryString] === null) {
      dispatch({ type: TOGGLE_IS_FAVORITE, payload: { kmatID } });
    }
  } catch (error) {
    dispatch(snackBar(true, error.message, "error"));
  }
};

export const handleFavoriteDownload = () => async (dispatch) => {
  try {
    const response = await GraphqlApi.query("favoriteDownload");
    const { name, content } = response?.data?.favoriteDownload;

    if (!name || !content) throw new Error("Failed to download favorite");

    const u8arr = u8arrFromBase64EncodedString(content);
    const file = new File([u8arr], name);

    fileDownload(file, name);
    dispatch(snackBar(true, "Zip file generated successfully", "success"));
  } catch (error) {
    dispatch(snackBar(true, error.message, "error"));
  }
};

export const clearFilesFilters = () => (dispatch) => {
  dispatch({ type: CLEAR_FILES_FILTERS })
}

export const filterFiles = (payload) => (dispatch) => {
  dispatch({ type: FILTER_FILES, payload })
}

