import React from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import { REGIONAL_TABS } from "constants/modelConstants";
import { Grid, Tab, Tabs } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { getTab } from "pages/Utils";

const useStyles = makeStyles((theme) => ({
  tabBorder: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  activeDot: {
    height: "20px",
    width: "20px",
    marginLeft: "5px",
  },
  dot: {
    height: "16px",
    width: "16px",
    marginLeft: "5px",
  },
}));

function RegionalStepperPanel({ onActiveStep }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeTab } = useSelector((state) => state.models);
  const syntaxCheckDone = useSelector(state => state.models.syntaxCheckDone);
  const syntaxCheck = useSelector(state => state.models.syntaxCheck);

  const handleSelectTab = (event, step) => {
    onActiveStep(step);
  };

  /**
   * Check any tab is having workspace error or not
   * @param {tab} string
   * @return {boolean} return true or false.
   */
  const isErrorOnValidateWorkSpace = (tab) => {
    const tabData = syntaxCheck[getTab(tab)];
    return  tabData?.hasError;
  };

  useEffect(() => {
    const hasError = REGIONAL_TABS.reduce((acc, tab) => {
      acc[tab.id] = syntaxCheckDone ? isErrorOnValidateWorkSpace(tab["id"]) : true;
      return acc;
    }, {});
    dispatch({ type: "SET_IS_ERROR_ON_VALIDATE", payload: hasError })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syntaxCheckDone, syntaxCheck]);

  return (
    <div style={{ marginBottom: "20px" }}>
      <Grid container className={classes.tabBorder}>
        <Tabs
          value={REGIONAL_TABS.findIndex(tab => tab.id === activeTab)}
          onChange={handleSelectTab}
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
        >
          {REGIONAL_TABS?.map(({ id, title }) => {
            const isError = isErrorOnValidateWorkSpace(id);
            return (
              <Tab
                key={title}
                iconPosition="end"
                icon={syntaxCheckDone && isError ? (
                  <ErrorIcon
                    className={activeTab === id ? classes.activeDot : classes.dot}
                  />
                ) : null
                }
                label={title}
              />
            );
          })}
        </Tabs>
      </Grid>
    </div>
  );
}

RegionalStepperPanel.propTypes = {
  onActiveStep: PropTypes.func.isRequired,
}

export default RegionalStepperPanel;
