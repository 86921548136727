import React from "react";
import { useSelector } from "react-redux";
import { Chip, Typography } from "@mui/material";
import "./EditModel.scss";
import { useStyles } from "./IndexHelpers";

const Label = (props) => {
    const classes = useStyles();
    const { activeCVGTab } = useSelector((state) => state.models);
    const { editTitle, activeNode, updatedSelectedData } = props;

    return (
        <div>
            <div className={classes.titleDescription}>
                <div className={classes.titleHeader}>
                    <Typography className={classes.editTitle}>{editTitle}</Typography>
                    {editTitle === "CVG Editor" && (
                        <span className={classes.cvgEditor}> -&nbsp;{activeNode[activeCVGTab]?.name}{" "}</span>
                    )}
                    {updatedSelectedData?.isHidden && editTitle === "Choice Details" && (
                        <Chip size="small" style={{ background: "#3f51b5" }} className={classes.subTitle} label="Hidden" />
                    )}
                    {updatedSelectedData?.isTemplate &&
                        (editTitle === "Choice Details" || editTitle === "Item Details") && (
                            <Chip size="small" style={{ background: "#3f51b5" }} className={classes.subTitle} label="Template" />
                        )}
                    {updatedSelectedData?.isManual &&
                        (editTitle === "Choice Details" || editTitle === "Item Details") && (
                            <Chip size="small" style={{ background: "#154360" }} className={classes.subTitle} label="Manual" />
                        )}
                    {updatedSelectedData?.region && editTitle === "Item Details" && (
                        <Chip size="small" style={{ background: "#2e7d32" }} className={classes.subTitle} label={updatedSelectedData.region} />
                    )}
                    {updatedSelectedData?.dataType === "NUM" && editTitle === "Choice Details" && (
                        <Chip size="small" style={{ background: "#1976d2" }} className={classes.subTitle} label="Numeric Choice" />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Label;
