import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { publishVersion, exportFiles, syntaxCheck, downloadExcelFile } from "../../actions/modelActions";
import { useHistory } from "react-router-dom";
import ConfirmationDialog from "components/ConfirmationDialog";
import fileDownload from "js-file-download";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Popover from "@mui/material/Popover";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { refreshDashboard } from 'actions/dashboardActions';
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import DescriptionIcon from "@mui/icons-material/Description";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SYNTAX_CHECK_DONE } from "constants/modelConstants";
import { snackBar } from "actions/modelActions";
import { useStyles, publishMethods } from "../../pages/EditModel/ModelHeader/ModelHeaderHelpers";
import useKeypress from "hooks/useKeypress";

function WorkSpaceButton(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentVersion, kmatId, kmat, dataType } = props;
  const lengths = useSelector((state) => state.models.syntaxCheck.lengths);
  const canSyntaxCheck = useSelector((state) => state.models.canSyntaxCheck);
  const publishingRights = useSelector((state) => state.models.syntaxCheck.publishingRights);
  const [syntaxChecking, setSyntaxChecking] = useState(false);
  const [isPublishing, setPublishing] = useState(false);
  const [syntaxCheckHasNoErrors, setSyntaxCheckHasNoErrors] = useState(false);
  const [isGeneratingFile, setGeneratingFile] = useState(false);
  const [openConfirmFilesPopup, setOpenConfirmFilesPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopover, setOpenPopover] = useState(null);
  const [publishMethod, setPublishMethod] = useState(null);
  const [openPublishDialog, setOpenPublishDialog] = useState(false);
  let isValidate = useRef(false);

  useEffect(() => {
    if (lengths) {
      if (Object.values(lengths).every(length => length === 0)) {
        isValidate.current && dispatch(snackBar(true, "KB Model Successfully Validated", "success"))
        setSyntaxCheckHasNoErrors(true);
      } else {
        isValidate.current && dispatch(snackBar(true, "KB Model Validation Failed", "error"))
        setSyntaxCheckHasNoErrors(false);
      }
    } else {
      setSyntaxCheckHasNoErrors(false);
    }
     isValidate.current = false
  }, [lengths]);

  // Function to handle the syntax check 
  const handleSyntaxCheck = () => {
    isValidate.current = true;
    setSyntaxChecking(true);
    dispatch({ type: SYNTAX_CHECK_DONE, payload: true });
    Promise.all([dispatch(syntaxCheck(kmatId, currentVersion)),])
      .then(() => { })
      .catch((err) => { dispatch(snackBar(true, err, "error")) })
      .finally(() => setSyntaxChecking(false));
  };

  // Function to publish the model
  const publish = () => {
    setPublishing(true);
    setOpenPublishDialog(false);
    const publishType = publishMethod === "Validate" ? null : publishMethod.toLowerCase();
    Promise.all([dispatch(publishVersion(kmatId, currentVersion, publishType))])
      .then(([res]) => {
        if ( res && (res.startValidation === null || res.publishDraft))
          history.push("/dashboard");
      })
      .catch((err) => {
        dispatch(snackBar(true, err, "error"));
      })
      .finally(() => {
        setPublishing(false);
        setOpenPopover(null);
      });
  };

  // Function to generate PCR & PCS files
  const generate = () => {
    setGeneratingFile(true);
    dispatch(exportFiles(kmatId, currentVersion, false))
      .then((files) => {
        files.forEach((file) => { fileDownload(file.content, `${file.name}`) })
        setGeneratingFile(false);
        dispatch(snackBar(true, "PCR & PCS Files generated successfully", "success"));
      })
      .catch((err) => {
        setGeneratingFile(false);
        dispatch(snackBar(true, err, "error"));
      });
  };

  const handleDownloadExcel = () => {
    dispatch(downloadExcelFile(kmatId, currentVersion, handlePopClose));
  };

  const onConfirmationGenerateFiles = () => {
    setOpenConfirmFilesPopup(false);
    handlePopClose();
    generate();
  };

  const closeConfirmFilesPopup = () => {
    setOpenConfirmFilesPopup(false)
    handlePopClose();
  }

  const handlePublishType = (option) => () => {
    setPublishMethod(option)
    setOpenPublishDialog(true);
  }

  const handlePublishDialogClose = () => {
    setOpenPublishDialog(false);
    setOpenPopover(null);
  };

  const handleClick = (event) => { dataType === 'template' ? setOpenConfirmFilesPopup(true) : setAnchorEl(event.currentTarget) }

  const handlePopClose = () => { setAnchorEl(null) };

  const handlePublishClose = () => { setOpenPopover(null) };

  const handlePublishMethod = (event) => { setOpenPopover(!openPopover ? event.currentTarget : null); };

  const publishDropdown = Object.keys(publishMethods).every((property) => !Boolean(publishingRights?.[property] || kmat?.[property]));

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const openPop = Boolean(openPopover);
  const idPop = openPop ? "simple-popover" : undefined;

  useKeypress("d", handleDownloadExcel, dataType === "model")

  return (
    <>
      <div className="pcr-toolbar-right">
        <nav className="pcr-toolbar-left-nav">
          {dataType !== "template" && (
            <Button
              id="pct_toolbar_validate_model"
              value="Validate"
              type="button"
              style={{ height: 43, marginRight: "10px", boxShadow: "none", padding: "8px 20px" }}
              className={!canSyntaxCheck || syntaxCheckHasNoErrors ? classes.checkIcon : "button slim secondary"}
              onClick={handleSyntaxCheck}
              disabled={!canSyntaxCheck}
              variant="contained"
            >
              {syntaxChecking ? "Syntax Checking..." : "Syntax Check"}
              {(syntaxCheckHasNoErrors || !canSyntaxCheck) && (<CheckCircleOutlineIcon className={classes.check} />)}
            </Button>)}

          {dataType !== "template" &&
            <Button
              id="pct_toolbar_publish_model"
              value="Publish"
              type="button"
              variant="contained"
              className={classes.publish}
              onClick={handlePublishMethod}
              disabled={publishDropdown}
            >
              {isPublishing ? "Publishing..." : "Publish"}
              {<KeyboardArrowDownIcon htmlColor={!publishDropdown ? "#ffffff" : "#8e918f"} style={{ marginLeft: "8px" }} />}
              {!publishDropdown && openPop &&
                <Popover
                  id={idPop}
                  open={openPop}
                  anchorEl={openPopover}
                  onClose={handlePublishClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}>
                  {Object.entries(publishMethods).map(([key, value], idx) => (
                    <MenuItem disabled={!Boolean(publishingRights?.[key] || kmat?.[key])} onClick={handlePublishType(value)} key={`${idx}-${key}`}>
                      {value}
                    </MenuItem>
                  )
                  )}
                </Popover>}
            </Button>}

          <Button
            variant="outlined"
            aria-describedby={id}
            onClick={handleClick}
            className={classes.moreIcon}
            size="medium"
          >
            {open ? (<DescriptionIcon htmlColor="#0278AB" />) : dataType === 'template' ?
              (<FileDownloadOutlinedIcon htmlColor="#0278AB" />) : (<MoreHorizIcon htmlColor="#0278AB" />)}
          </Button>
          {dataType === "model" && open && (
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              className={classes.popOver}
            >
              <ListItemButton
                id="pct_toolbar_publish_model"
                value="Create PCR File"
                type="button"
                variant="text"
                onClick={() => setOpenConfirmFilesPopup(true)}
              >
                <ListItemText>
                  {isGeneratingFile ? "Generating PCR & PCS Files..." : "Download PCR & PCS files"}
                </ListItemText>
              </ListItemButton>
              <ListItemButton
                id="pct_toolbar_download_excel_file"
                value="Download Excel file"
                type="button"
                variant="text"
                onClick={handleDownloadExcel}
              >
                <ListItemText>Download Excel file</ListItemText>
              </ListItemButton>
              <ListItemButton
                id="pcx-files"
                value="PCX Files"
                type="button"
                variant="text"
                onClick={() => history.push("/files/" + kmatId + "/production")}
              >
                <ListItemText>
                  {"PCX Files"}
                </ListItemText>
              </ListItemButton>
            </Popover>
          )}
        </nav>
      </div>

      {openConfirmFilesPopup &&
        <ConfirmationDialog
          open={openConfirmFilesPopup}
          title="Confirmation"
          content="Do you want to Generate PCR & PCS Files?"
          onClose={closeConfirmFilesPopup}
          onConfirm={onConfirmationGenerateFiles}
          caption="Generate"
          bgColor="#0278AB"
        />}

      {openPublishDialog &&
        <ConfirmationDialog
          open={openPublishDialog}
          title="Confirm Publish Method"
          content={<>You are going to publish "<b>{publishMethod}</b>" PCR/PCS files. Confirm if you want to proceed with publishing.</>}
          onClose={handlePublishDialogClose}
          onConfirm={publish}
          caption="Confirm"
          bgColor="#0278AB"
        />}
    </>
  );
}

export default WorkSpaceButton;
