import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Box, Button, CircularProgress, Grid, Paper } from "@mui/material";
import { cvgTabSelection, fetchData, tabSelection, updateCancelInit, resetTree, syntaxCheck } from "actions/modelActions";
import StepperPanel from "components/StepperPanel";
import StatusInfo from "components/StatusInfo";
import { STEP_NAMES, SYNTAX_CHECK_REQUIRED, SYNTAX_CHECK_DONE, CVGMGMT_TAB, PM_TAB, REGIONAL_TAB } from "constants/modelConstants";
import ChoiceEdit from "./ChoiceEdit/ChoiceEdit";
import ItemEdit from "./ItemEdit/ItemEdit";
import KmatBox from "./ModelHeader/KmatBox";
import ActionableButtons from "./ActionableButtons";
import MultiItemEdit from "./MultiItemEdit/MultiItemEdit";
import "./EditModel.scss";
import { useStyles } from "./IndexHelpers";
import { getTab } from "pages/Utils";
import { rememberProperty, undoProperty } from 'actions/undoActions';
import { updateSaveInit } from 'actions/modelActions';
import CVGEdit from "./CVGEdit/CVGEdit";
import CvgIndex from "./cvgIndex";
import Label from "./label";
import { StaticData } from "./Tabs/StaticData";
import ReactCheckboxTree from "components/ReactCheckboxTree/ReactCheckboxTree.js";
import CheckBoxTreeContainer from "components/ReactCheckboxTree/CheckBoxTreeContainer";
import { snackBar } from "actions/modelActions";
import useKeypress from "hooks/useKeypress";


const EditModel = (props) => {
	const { dataType } = props;
	const kmatId = props.match.params.kmatId;
	const version = props.match.params.version ? props.match.params.version : "1.0";
	const classes = useStyles();
	const dispatch = useDispatch();
	const models = useSelector((state) => state.models);
	const { loading, error, dataPM, currentVersion, kmat, activeCVGTab, activeTab, publishLoading, syntaxCheckRequired, saveInit, versions} = models;
	const activeDataSet = models[getTab(activeTab)];
	const activeCvgDataSet = models[getTab(activeCVGTab)];
	const datasetValues = activeDataSet && Object.values(activeDataSet);
	const cvgDatasetValues = activeCvgDataSet && Object.values(activeCvgDataSet);
	const selectedItem = useSelector((state) => state.models.selectedItem);
	const [isEfficientlyEditedInBulkMode, setIsEfficientlyEditedInBulkMode] = useState(false);
	const [toggleNavigation, setToggleNavigation] = useState(false);
	console.log('Value of toggle navigation ', toggleNavigation);
	const [isSaving, setIsSaving] = useState(false);
	const [cvgsFilter, setCvgsFilter] = useState("");
	const [checked, setChecked] = useState([]);
	console.log(checked);
	// const isViewEditBtnDisabled = !datasetValues?.some((item) => Object.values(item?.items || {})?.some((child) => child.isChecked));
	const isViewEditBtnDisabled = checked.length === 0;
	const initialActiveNodeState = {
		PM: null,
		Misc: null,
		CarePack: null,
		CustomService: null,
		Spares: null,
		Deploy: null,
		MandA: null,
		CVGMGMT: null,
		Regional: null,
	};
	const [activeNode, setActiveNode] = useState(initialActiveNodeState);
	const [activeCvgNode, setActiveCvgNode] = useState(initialActiveNodeState);
	const isBulkEditMode = Array.isArray(activeNode[activeTab]);

	const editTitle  = useMemo(() => {
		if (activeTab === CVGMGMT_TAB) return "CVG Editor";

		const activeNodeContent = activeNode[activeTab];

		if (!activeNodeContent) return null;
		if (Array.isArray(activeNode[activeTab])) return 'Bulk Edit';
		if (activeNodeContent.itemID) return 'Item Details';
		if (activeNodeContent.dataType === "SYS") return 'System Choice';

		return 'Choice Details';
	}, [activeNode, activeTab]);

	const onNodeSelect = useCallback((n) => {
		setActiveNode((prevState) => ({ ...prevState, [activeTab]: n }))
		if (n) {
		[n.choiceID, n.parentId].forEach(id => {
			if (id && id !== "0") {
			  dispatch(rememberProperty(id));
			}
		  });
		}
	},[activeTab])

	const onNodeCheck = useCallback(
		(nodes) => {
			setChecked(nodes)
		},[activeTab]
	)
	const currentActiveNode = activeNode[activeTab];
	const getFilteredItems = () => {
		const itemsData = datasetValues?.map(each => each?.items)?.reduce((acc, i) => acc.concat(i), [])
		const filteredItems = itemsData?.find(item => item[currentActiveNode?.itemID]) || {}
		return filteredItems[currentActiveNode?.itemID]
	}
	let updatedSelectedData = currentActiveNode?.itemID ? getFilteredItems() : datasetValues?.find((item) => item.choiceID === currentActiveNode?.choiceID)

	const createTreeItems = () => {
		if (!kmat) return;

		const isCVGMGMT = activeTab === CVGMGMT_TAB;
		if (!currentActiveNode && datasetValues?.length && !isCVGMGMT) {
			setActiveNode({ ...activeNode, [activeTab]: datasetValues[0] });
			dispatch(rememberProperty(datasetValues[0].choiceID));
		} else if (!currentActiveNode && cvgDatasetValues?.length && isCVGMGMT) {
			setActiveCvgNode({ ...activeCvgNode, [activeCVGTab]: cvgDatasetValues[0] });
			dispatch(rememberProperty(cvgDatasetValues[0].choiceID));
		} else if (!isCVGMGMT && (currentActiveNode?.choiceID || currentActiveNode?.itemID) && currentActiveNode?.tabCategory) {
			setActiveNode({ ...activeNode, [activeTab]: updatedSelectedData || currentActiveNode });
		}
	};

	const handleSaveClick = async () => {
		const tabData = models[getTab(activeTab)];
			if(isBulkEditMode){
				setActiveNode({ ...activeNode, [activeTab]: tabData[Object.keys(tabData)[0]] })
				dispatch(resetTree(toggleNavigation));
			}
		setIsSaving(true);
		dispatch(updateSaveInit(true));
		setIsSaving(false);
		setToggleNavigation(false);
		setIsEfficientlyEditedInBulkMode(false);
	};



	useEffect(() => {
		if (syntaxCheckRequired) {
			batch(() => {
				dispatch({ type: SYNTAX_CHECK_DONE, payload: true });
				dispatch({ type: SYNTAX_CHECK_REQUIRED, payload: false });
				if (dataType === "model")
					dispatch(syntaxCheck(kmatId, currentVersion));
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentVersion, dataType, dispatch, kmatId, syntaxCheckRequired]);

	const handleCancelClick = () => {
		const tab = activeTab === CVGMGMT_TAB ? activeCVGTab : activeTab;
		const itemID = activeNode[tab]?.itemID;
		const choiceID = itemID ? activeNode[tab].parentId : activeNode[tab]?.id;
		// executed when items are bulk edited
		if (!itemID && !choiceID && activeTab !== CVGMGMT_TAB) {
			const tabData = models[getTab(activeTab)];

			const bulkEditedChoices = Object.values(tabData)
				.filter((choice) => Object.values(choice.items).some((item) => checked.includes(item.itemID)))
				.map((choice) => choice.choiceID);

			setActiveNode({ ...activeNode, [activeTab]: tabData[Object.keys(tabData)[0]] })
			dispatch(undoProperty({ bulkEditedChoices }));
			dispatch(updateCancelInit(true));
			dispatch(resetTree(toggleNavigation));
			setToggleNavigation(false);
			setIsEfficientlyEditedInBulkMode(false);
			return
		}
		dispatch(undoProperty({ itemID, choiceID }));
	};

	const BulkEditData = useMemo(() => {
		return Object.values(activeDataSet || {}).map((choice) => {
		  const checkedItems = Object.values(choice.items).filter((childItem) => checked.includes(childItem.itemID));
		  return checkedItems.length > 0 && { ...choice, items: [...checkedItems] };
		}).filter(Boolean);
	  }, [activeDataSet, checked]);

	const handleViewEditItemsClick = () =>{
		setActiveNode({ ...activeNode, [activeTab]: [] });
		setToggleNavigation(true);
	};

	const handleActiveStep = (tabIndex) => {
		setToggleNavigation(false);
		dispatch(tabSelection(STEP_NAMES[tabIndex]));

		setActiveNode((prevActiveNode) => {
			const newActiveTab = STEP_NAMES[STEP_NAMES[tabIndex] === CVGMGMT_TAB ? 0 : tabIndex];
			const tabData = models[getTab(newActiveTab)];
			const selectedItemData =  prevActiveNode[newActiveTab];
			return ({ ...prevActiveNode, [newActiveTab]: selectedItemData || Object.values(tabData)?.[0] || null })
		});
		setCvgsFilter("")
		dispatch(cvgTabSelection(PM_TAB));
		dispatch(snackBar(false, '',));

	};

	// Selecting the first choice from 'PM' tab, when the model is fetched or the version is changed.
	// Selecting the first choice from 'activeCVGTab' tab, when the 'CVGMGMT' tab is selected.
	useLayoutEffect(() => {
		createTreeItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [kmat, activeCVGTab, currentVersion, activeDataSet, updatedSelectedData]);

	useEffect(() => {
		dispatch(fetchData(kmatId, version, dataType));	
		dispatch({ type: 'SYNTAX_CHECK_SUCCESS', payload:{}});
		setActiveNode(initialActiveNodeState);
	// version is excluded from the dependency array because we force model fetching in case of version change
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, kmatId, dataType, currentVersion]);

	useKeypress("b", handleViewEditItemsClick, !isViewEditBtnDisabled);

	const renderEditableContent = () => {
		if (activeTab === CVGMGMT_TAB && Object.entries(activeCvgNode[activeCVGTab]?.items ?? {}).length) {
            return <CVGEdit key={`displayEditCVG-${activeCvgNode[activeCVGTab]}${JSON.stringify(activeCvgNode[activeCVGTab])}`} choiceID={activeCvgNode[activeCVGTab]?.choiceID} activeCVGTab={activeCVGTab} cvgsFilter={cvgsFilter} />;
        }

		if (!activeNode[activeTab]) {
			return <div className={classes.noItem}>No data found!</div>;
		}

		if (Array.isArray(activeNode[activeTab])) {
			return <MultiItemEdit BulkEditData={BulkEditData} tabName={activeTab} setIsEfficientlyEditedInBulkMode={setIsEfficientlyEditedInBulkMode} checked={checked}/>;
		}

		if (activeNode[activeTab].itemID) {
			return (
				<ItemEdit
					key={`displayEdit-${activeTab}-${activeNode[activeTab].id}`}
					tabName={activeTab}
					data={activeNode[activeTab]}
				/>
			);
		}

		return (
			<ChoiceEdit
				key={`displayChoiceEdit-${activeTab}-${activeNode[activeTab].choiceID}`}
				data={activeNode[activeTab]}
				tabName={activeTab}
			/>
		);
	};

	if (loading || error || !dataPM) {
		return <StatusInfo
			error={error}
			loading={loading || !datasetValues?.length}
			loadingMessage={"Loading.... please wait"}
		/>
	};

	const isOnCVGTab = activeTab === CVGMGMT_TAB;
	const isOnRegionalTab = activeTab === REGIONAL_TAB;

	const areActionableButtonsVisible = (isOnCVGTab && Object.entries(activeCvgNode[activeCVGTab]?.items ?? {}).length > 0) || (!isOnCVGTab && activeNode[activeTab]?.dataType !== "SYS" && Object.entries(activeNode[activeTab]?.parentId ?? {}).length > 0) || isBulkEditMode;
	
	return (
		<div className={classes.root}>
			{publishLoading && (<Box className={classes.publishLoading}><CircularProgress color="primary" /></Box>)}
			<KmatBox kmat={kmat} dataType={dataType} currentVersion={currentVersion} versions = {versions} />
			<StepperPanel
				onActiveStep={handleActiveStep}
				handleCancelClick={handleCancelClick}
				isRestrictedStepChanging={isBulkEditMode && isEfficientlyEditedInBulkMode}
			/>
			{isOnRegionalTab
				? (<StaticData datasetValues={datasetValues} />)
				: (<Grid container spacing={1} className={classes.root2} style={{ display: "flex" }}>
					<Grid id="tree-container" item sm={12} md={4} style={{ display: toggleNavigation ? "none" : "flex", flexDirection: "column" }}>
						<Paper className={classes.paper}>
							{isOnCVGTab
								? <CvgIndex activeCvgNode={activeCvgNode} activeCVGTab={activeCVGTab} dataType={dataType} cvgDatasetValues={cvgDatasetValues} cvgsFilter={cvgsFilter} setActiveCvgNode={setActiveCvgNode} setCvgsFilter={setCvgsFilter} />
								: <>
									<div className={classes.treeview}>
										<CheckBoxTreeContainer dataType={dataType} activeDataSet={activeDataSet} activeTab={activeTab} onSelect={onNodeSelect} checked={onNodeCheck} currentVersion={currentVersion} kmatId = {kmat.kmatID}/>
									</div>
									<Button variant="contained" className = {classes.viewBtn} disabled={isViewEditBtnDisabled} fullWidth onClick={handleViewEditItemsClick}>
										View/Edit Selected Items
									</Button>
								</>
							}
						</Paper>
					</Grid>

					<Grid id="edit-container" item sm={toggleNavigation ? true : 12} md={toggleNavigation ? true : 8}>
						<Paper className={classes.paper} style={{ marginLeft: "15px" }}>
							<Label editTitle={editTitle} activeNode={activeNode} updatedSelectedData={activeNode[activeTab]} />
							{renderEditableContent()}
							<div className={classes.actionableButtons}>
								{areActionableButtonsVisible && (
									<ActionableButtons allowCtrlPlusS={!isBulkEditMode || isEfficientlyEditedInBulkMode} isSaving={isSaving} handleSaveClick={handleSaveClick} handleCancelClick={handleCancelClick} />
								)}
							</div>
						</Paper>
					</Grid>
				</Grid>)
			}
		</div>
	);
};

export default withRouter(EditModel);
