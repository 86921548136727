// mutation createWNewRoles{
//     createKbUser(input: {
//         firstName: "John",
//         lastName: "Doe",
//         email: "john.doe@hp.com",
//         role: "",
//         roles: ["admin", "regional", "standard", "plc"]
//     })
// }

export const createWNewRoles = `mutation($model : CreateKbUserRequest!){
    createKbUser(input : $model) {
       firstName
       lastName
       email
       role
       roles
      }
  }`
