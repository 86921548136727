import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Box, Checkbox, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { refreshBtps, filterBtps } from 'actions/btpAction';
import ActionButtons from '../ActionButtons';
import { container, dataGridStyles, renderTextfieldHeader } from "../DashboardHelpers";
import RoundedPagination from 'pages/Pagination';

const columnTypes = {
    kmatID: {
        type: 'text',
        name: 'kmatID',
        matchFields: ['key'],
    },
    productline: {
        type: 'text',
        name: 'productline',
        matchFields: ['productline'],
    },
    modelDesc: {
        type: 'text',
        name: 'modelDesc',
        matchFields: ['modelDesc'],
    },
};

const defineColumns = ({ filters, handleChange, handleCheckboxChange, setShowOnlyChecked, isRunning, checkedRows }) => [
    {
        headerName: 'checkBox',
        field: 'isChecked',
        headerClassName: 'checkbox-column-header',
        align: 'center',
        width: 70,
        sortable: false,
        renderCell: (params) => {
            const { key } = params.row;
            return (<Checkbox checked={checkedRows?.includes(key)} onChange={(e) => handleCheckboxChange(key, e.target.checked)} disabled={isRunning} />);
        },
        renderHeader: () => (<Checkbox onChange={(e) => setShowOnlyChecked(e.target.checked)} style={{ marginTop: "25px" }} />),
    },
    {
        headerName: 'KMAT ID',
        field: 'kmatID',
        headerClassName: 'dashboard-data-grid-headers',
        flex: 0.8,
        sortable: false,
        renderCell: (params) => <Typography variant='subtitle2'>{params.row.key}</Typography>,
        renderHeader: () => renderTextfieldHeader(columnTypes.kmatID, 'KMAT ID', filters, handleChange),
    },
    {
        headerName: 'Product name',
        field: 'modelDesc',
        headerClassName: 'dashboard-data-grid-headers',
        headerAlign: 'left',
        flex: 1.5,
        sortable: false,
        renderCell: (params) => <Typography variant='subtitle2'>{params.row.modelDesc}</Typography>,
        renderHeader: () => renderTextfieldHeader(columnTypes.modelDesc, 'Product Name', filters, handleChange),
    },
    {
        headerName: 'Product Line',
        field: 'productline',
        headerClassName: 'dashboard-data-grid-headers',
        flex: 1.5,
        sortable: false,
        renderCell: (params) => <Typography variant='subtitle2'>{params.row.productline}</Typography>,
        renderHeader: () => renderTextfieldHeader(columnTypes.productline, 'Product Line', filters, handleChange),
    },
]


function BtpDashboard({ checkedRows, onCheckboxChange, Api }) {
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10);
    const { filteredBtps, filters, isLoading, getBTPStatus } = useSelector(state => state.btps);
    const [showOnlyChecked, setShowOnlyChecked] = useState(false);
    const sortedBtps = [...filteredBtps].sort((a, b) => a.key.localeCompare(b.key));

    useEffect(() => {
        dispatch(refreshBtps(Api));
    }, [dispatch]);

    const handleCheckboxChange = useCallback((key, checked) => {
        const updatedCheckedRows = checked ? [...checkedRows, key] : checkedRows.filter(rowKey => rowKey !== key);
        onCheckboxChange(updatedCheckedRows);
    }, [checkedRows, onCheckboxChange]);

    const handleChange = useCallback((col, value) => {
        dispatch(filterBtps({ ...col, searchText: value }))
    }, [dispatch]);

    return (
        <Box sx={{ ...container, maxWidth: "1350px" }}>
            <ActionButtons title="BTP" Api={Api} />
            <DataGrid
                columns={defineColumns({ dispatch, filters, handleChange, handleCheckboxChange, setShowOnlyChecked, checkedRows, isRunning: getBTPStatus?.isRunning })}
                rows={sortedBtps.filter(row => showOnlyChecked ? checkedRows?.includes(row.key) : true)}
                getRowId={(row) => row.key}
                loading={isLoading}
                rowHeight={48}
                headerHeight={80}
                className='dashboard-data-grid'
                disableSelectionOnClick
                getRowClassName={(params) => `${params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'} `}
                headerClassName='dashboard-grid-headers'
                initialState={{ pagination: { paginationModel: { page: 0, pageSize: 15 } } }}
                components={{ Pagination: RoundedPagination }}
                sx={dataGridStyles}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 50]}
                disableColumnFilter
                disableColumnMenu
            />
        </Box>
    )
}

export default withRouter(BtpDashboard);
