import { useState } from "react";

//Material UI
import { Button, Popover } from "@mui/material";
import { makeStyles } from "@mui/styles";

//Material UI icons
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";

import AddNewChoiceItemDialog from "components/AddNewChoiceItemDialog/AddNewChoiceItemDialog";
import DisabledDeleteOptionForChoice from "components/MuiTreeView/DisabledDeleteOptionForChoice";
import ChoiceDelete from "components/MuiTreeView/ChoiceDelete";

const useStyles = makeStyles((theme) => ({
    addIcon: {
        display: "flex",
        borderRadius: "10px",
        "&:hover": {
            backgroundColor: "#e9e9e9",
        },
    },
    deltBtn: {
        borderRadius: "10px !important",
        color: "red !important",
        justifyContent: "flex-start !important",
        "&:hover": {
            backgroundColor: "#e9e9e9",
        },
    },
}));

export default function CheckboxTreePopover(props) {

    const classes = useStyles();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const open = Boolean(props.anchorEl);

    let selectedNode = props.selectedNode;
    let nodesFromApi = props.nodesFromApi;
    let dataType = props.dataType;

    const handleDialogOpen = () => {
        setDialogOpen(true);
        props.setAnchorEl(null);
    };

    const handleDeleteOpen = () => {
        setDeleteDialogOpen(true);
        props.setAnchorEl(null);
    }

    const handleDeleteClose = () => {
        setDeleteDialogOpen(false);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
        props.setAnchorEl(null);
    };

    const onHandleCb = (item) => {
        item && props.announceSelection(item);
    }

    return (
        <>
            <Popover
                open={open}
                anchorEl={props.anchorEl}
                onClose={props.handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {selectedNode?.current?.dataType !== "NUM" &&
                    <Button
                        className={classes.addIcon}
                        onClick={handleDialogOpen}
                        startIcon={<AddIcon />}
                    >
                        Add a new item
                    </Button>
                }
                {(selectedNode?.current?.dataType !== "SYS" && dataType !== "regional") &&
                    <Button
                        fullWidth
                        className={classes.deltBtn}
                        startIcon={<DeleteIcon />}
                        onClick={handleDeleteOpen}
                    >
                        Delete
                    </Button>
                }
            </Popover>

            {dialogOpen && (
                <AddNewChoiceItemDialog
                    dialogOpen
                    activeNode={selectedNode?.current}
                    handleDialogClose={handleDialogClose}
                    onHandleCb={(item) => onHandleCb(item)}
                    dataType={props.dataType}
                    kmatId={props.kmatId}
                    currentVersion={props.currentVersion}
                    activeTab={selectedNode?.current?.tabCategory}
                />
            )}

            {deleteDialogOpen && (
                selectedNode?.current?.items && Object.keys(selectedNode?.current?.items).length > 0 ? (
                    <DisabledDeleteOptionForChoice
                        choiceID={selectedNode?.current?.choiceID}
                        setDeleteDialogOpen={setDeleteDialogOpen} />
                ) : (
                    <ChoiceDelete
                        choiceID={selectedNode?.current?.choiceID}
                        tabCategory={selectedNode?.current?.tabCategory}
                        currentVersion={props.currentVersion}
                        kmatId={props.kmatId}
                        nodes={nodesFromApi}
                        handleeDeleteClose={handleDeleteClose}
                        onHandleCb={(item) => onHandleCb(item)}
                    />
                )
            )}

        </>
    )
}