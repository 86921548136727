import React from 'react';
import Pagination from '@mui/material/Pagination';
import { useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { gridPageCountSelector, gridPageSelector } from '@mui/x-data-grid';


const RoundedPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            sx={(theme) => ({ padding: theme.spacing(1.5, 0) })}
            shape="rounded"
            className="data-grid-pagination"
            color="primary"
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    )
}

export default RoundedPagination;
