import React, { useState, memo } from "react"
import { useDispatch, useSelector } from "react-redux";

import './ReactCheckboxTree.css';

//Material UI
import { IconButton } from "@mui/material"

//Material UI icons
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import ItemsDeleteDialog from "components/MuiTreeView/ItemsDeleteDialog";
import EditDot from "pages/EditModel/MultiItemEdit/EditDot";
import { snackBar } from "actions/modelActions";

export default memo(function CheckboxTreeLabel(props) {

    const dispatch = useDispatch();
    const [isDeleteClicked, setIsDeleteClicked] = useState(false);
    const editedNodes = useSelector((state) => state.models.editedNodes);

    let node = props.node;
    let count = props.count;
    let choiceData = props.choiceData;

    let id = node.choiceID || node.itemID;
    let label = node.name;
    let edited = editedNodes?.includes(id);
    let isError = node.isError;
    let status = node.status ? JSON.parse(node.status)?.status : "";
    let statusChip =
        status === "updated"
            ? "status-chip status-chip-updated"
            : status === "new"
                ? "status-chip status-chip-new"
                : status === "obsolete"
                    ? "status-chip status-chip-obsolete"
                    : "";
    let description = node.description
    let partNumber = node.partNumber

    const onMoreIconClick = (e) => {
        props.onMoreIconClick(e, id)
    }

    const onDeleteItem = (e) => {
        setIsDeleteClicked(true);
        e.stopPropagation();
    }

    const handleCancel = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch(snackBar(false, ""));
    };

    const onHandleCb = (item) => {
        props.announceSelection(item)
    }

    return (
        <>
            <div id={id} key={id} className="label-outer-container">&nbsp;
                {partNumber ? (
                    <label htmlFor={id} title={label} className="label-container">
                        <span className="boldTextItem">{partNumber}</span> {description}
                    </label>
                ) : (
                    <label htmlFor={id} title={label} className="label-container">
                        {label}
                    </label>
                )}
                {count !== 0 && count !== '' && count !== undefined && <span className='badge-container count-icon'>{count}</span>}
                {isError !== undefined && isError !== false && <span className='badge-container error-icon'>!</span>}
                {status ? <span className={statusChip}>{status}</span> : ''}
                {edited !== undefined && edited !== false && <EditDot />}
            </div>
            <div id="parentOfMoreIcon">
                <div id='moreIcon' className="moreIcon">
                    <IconButton id={id} onClick={(e) => count !== undefined ? onMoreIconClick(e) : onDeleteItem(e)}>
                        {count !== undefined ? <MoreHorizIcon id={id} sx={{ fontSize: 17 }}></MoreHorizIcon> : <DeleteIcon id={id} sx={{ fontSize: 17 }} ></DeleteIcon>}
                    </IconButton>
                </div>
            </div>

            {isDeleteClicked && <ItemsDeleteDialog itemID={id} node={node} choice={choiceData} setIsDeleteClicked={setIsDeleteClicked} onHandleCb={(item) => onHandleCb(item)} currentVersion={props.currentVersion} kmatId={props.kmatId} dataType={props.dataType} />}
        </>
    )
})
