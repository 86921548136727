import { setCookie, getCookie, clearCookie } from "utils/function-utils";
import { GraphqlApi } from 'services/api';
import { useHistory } from "react-router-dom";

export const rolesMap = {
    admin: {
        defaultRoute: "/admin/users",
        accessible: ['admin']
    },
    standard: {
        defaultRoute: "/dashboard",
        accessible: ['dashboard']
    },
    regional: {
        defaultRoute: "/regional",
        accessible: ['regional']
    },
    pcx_files: {
        defaultRoute: "/files",
        accessible: ['files']
    },
    plc: {
        defaultRoute: "/plc",
        accessible: ['plc']
    },
    btp: {
        defaultRoute: "/btp",
        accessible: ['btp']
    }
};

export const rerouteCachedPath = (auth, history, location) => {
    const isPathLogin = location === '/' || location === '/login';
    if (!auth && !isPathLogin) {
        setCookie('rememberRedirect', location);
        return false;
    }

    const pathCookie = getCookie('rememberRedirect');
    if (auth && pathCookie) {
        if (!pathCookie) return false;

        const { pathname } = JSON.parse(pathCookie);
        const page = pathname.split('/')[1];
        if (page && (auth.role === 'admin' || rolesMap[auth.role]?.accessible.includes(page))) {
           // clearCookie('rememberRedirect');
            history.push(pathname);
            return true;
        }
        return false;
    }

    return false;
}

export const rerouteFromRoot = (user, history, search='') => {
    if (search) {
        const route = search.split("=")[1];
        history.push(route);
        return;
    }

    if (user) {
        //clearCookie('rememberRedirect');
        const path = rolesMap[user.role]?.defaultRoute || '/dashboard';
        history.push(path);
        return;
    }
}

export const useRerouteRole = () => {
    return async (userRole) => {
        const path = rolesMap[userRole]?.defaultRoute || '/dashboard';
        await GraphqlApi.query("switchRole", {
            roleID: userRole
          });
        window.location.pathname = path;
    }
}

export const useReroute = () => {
    const history = useHistory();

    return (userRole) => {
       const resetPathCookie= getCookie('rememberRedirect');    
        const path = resetPathCookie ? JSON.parse(resetPathCookie).pathname :  rolesMap[userRole]?.defaultRoute || '/dashboard';
        history.push(path);
    }
}