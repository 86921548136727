import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { filterArrayByString } from 'utils';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { DataGrid } from '@mui/x-data-grid';
import "./UserAccessManagement.css";
import RoundedPagination from 'pages/Pagination';
import { getUsers } from "actions/userActions";
import { DeleteUserDialog } from './DeleteUserDialog';
import { AddDialog } from './AddUserDialog';
import { UpdateUserDialog } from './UpdateUserDialog';
import { dataGridStyles } from 'pages/DashboardHelpers';
import { getColumns } from './columnsConfig';
import { CustomSearch, searchFieldProps } from 'pages/EditModel/IndexHelpers';

function UserAccessManagement() {
  const dispatch = useDispatch();
  const { allUsers, roleList } = useSelector((state) => state.user);
  const [searchText, setSearchText] = useState('');
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [pageSize, setPageSize] = useState(8);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [chosenUser, setChosenUser] = useState({});
  const [remodeledRoleList, setRemodeledRoleList] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    setRemodeledRoleList(roleList)
  }, [roleList]);

  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
  }

  const SEARCH_KEYS = ["firstName", "lastName", "email", "role"];
  const filteredUser = searchText.length === 0 ? allUsers : filterArrayByString(allUsers, searchText, SEARCH_KEYS)

  const updateKbUser = (user) => {
    setChosenUser(user);
    setOpenUpdateDialog(true);
  }

  const deleteKbUser = (user) => {
    setChosenUser(user);
    setOpenDeleteDialog(true);
  }

  const handleClose = () => {
    setOpenAddDialog(false);
    setOpenDeleteDialog(false);
    setOpenUpdateDialog(false);
    setSelectedValue([]);
    setChosenUser({});
  }

  const handleClearSearch = () => { setSearchText('') };

  const columns = getColumns(updateKbUser, deleteKbUser)

  return (
    <div className='container'>
      <Typography variant='h5'> User Access Management </Typography>

      <Box className='admin-actions-box'>
        <Typography variant='h6'> Admin actions </Typography>
        <Box className='create-new-user-box'>
          <IconButton color="primary" className='icon-button' disableRipple >
            <PersonAddAltIcon sx={{ fontSize: "27px" }} />
          </IconButton>
          <div className='create-user-text-box'>
            <Typography sx={{ fontSize: "19px" }}> Create new user </Typography>
            <Typography sx={{ color: '#b8b8b8', fontSize: "13px" }}> Add new users to KB-IDE </Typography>
          </div>
          <div className='add-user-button'>
            <Button variant="text" onClick={() => setOpenAddDialog(!openAddDialog)} >Add user</Button>
          </div>
        </Box>
      </Box>

      <Box className="users-box">
        <div className='title-and-search-box'>
          <Typography variant='h6' sx={{ marginTop: "12px" }}>  KB-IDE Users </Typography>
          <div>
            <CustomSearch {...searchFieldProps}
              placeholder="Search.."
              className='searchBar'
              value={searchText}
              handleClearSearch={handleClearSearch}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <DataGrid
          columns={columns}
          rows={filteredUser}
          getRowId={(row) => row.email}
          rowHeight={70}
          className='users-data-grid'
          components={{ Pagination: RoundedPagination, }}
          getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'}
          headerClassName='users-data-grid-headers'
          initialState={{ pagination: { paginationModel: { page: 0, pageSize: 10 } }, }}
          sx={dataGridStyles}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          density='compact'
          disableColumnFilter
          disableColumnMenu
        />

        {openAddDialog &&
          <AddDialog
            open={openAddDialog}
            onClose={handleClose}
            handleChange={handleChange}
            remodeledRoleList={remodeledRoleList}
            selectedValue={selectedValue}
          />}

        {openUpdateDialog &&
          <UpdateUserDialog
            open={openUpdateDialog}
            onClose={handleClose}
            chosenUser={chosenUser}
            remodeledRoleList={remodeledRoleList}
            handleChange={handleChange}
            selectedValue={selectedValue}
          />}

        {openDeleteDialog &&
          <DeleteUserDialog
            keepMounted
            open={openDeleteDialog}
            onClose={handleClose}
            chosenUser={chosenUser}
          />}

      </Box>
    </div>
  );
}

export default withRouter(UserAccessManagement);
