import React, { useEffect, useCallback, useState } from "react";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";
import { debounce, getCookie, setCookie } from "utils/function-utils";
import { rolesMap, rerouteCachedPath } from "./Utils/RerouteUtil";

const ProtectedRoute = ({ component: Component, auth, ...rest }) => {
  const location = useLocation();
  const history = useHistory();
  const {dataType} = rest
  const [loading, setLoading] = useState(true);
  setCookie('rememberRedirect', JSON.stringify(location));
  const debouncedRerouteCachedPath = useCallback(() => debounce(rerouteCachedPath, 100), []);

  useEffect(() => {
    if(auth) setLoading(false);
    debouncedRerouteCachedPath(auth, history, location);
  }, [auth, location, debouncedRerouteCachedPath]);

  const pathCookie = getCookie('rememberRedirect');
  const originalRedirect = pathCookie && JSON.parse(pathCookie);
  const page = originalRedirect?.pathname?.split('/')[1];
  const isUserRoleValid = auth?.roles?.some(role => rolesMap[role.id]?.accessible.includes(page));
  const defaultRoute = auth && rolesMap[auth?.role]?.defaultRoute;
  const isAuth = auth && isUserRoleValid;

  return (
    <>
    {!loading && (
        <Route
          {...rest}
          render={(props) => {
            if (dataType) {
              return auth ? <Component {...props} dataType={dataType} /> : <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
            } else {
              return isAuth ? <Component {...props} /> : <Redirect to={defaultRoute ? defaultRoute : "/"} />;
            }
          }}
        />
      )}
    </>
  )
};

export default ProtectedRoute;
