import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { useState } from 'react';
import ImportModelDialog from './ImportModelDialog';
import UpdateModelDialog from './UpdateModelDialog';
import { useDispatch } from 'react-redux';
import { refreshDashboard } from 'actions/dashboardActions';
import { publishVersion } from 'actions/modelActions';
import { snackBar } from 'actions/modelActions';
import { importModelColumns } from './columnsConfig';
import ConfirmationDialog from 'components/ConfirmationDialog';

export const DashboardPopup = ({ id, role, row, isBtnMenuOpen, setIsBtnMenuOpen }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { kmatID, actions } = row;
    const [dialogOpen, setDialogOpen] = useState(null);
    const publishMethodData = actions.publish.kind.charAt(0).toUpperCase() + actions.publish.kind.slice(1);
    const publishMethod = publishMethodData === "Scit" ? publishMethodData.toUpperCase() : publishMethodData;

    const handleDialogOpen = (dialogType) => { setDialogOpen(dialogType) };

    const handleDialogClose = () => { setDialogOpen(null) };

    const handleNavigation = (path) => { history.push(path) };

    const handlePublish = () => {
        dispatch(publishVersion(kmatID, actions.publish.version, actions.publish.kind))
            .then(() => {
                setIsBtnMenuOpen({ anchorEl: null, state: false });
                setDialogOpen(false)
                dispatch(refreshDashboard());
            })
            .catch((err) => {
                dispatch(snackBar(true, err.message));
            });
    };

    const renderActionsDialog = () => {
        switch (dialogOpen) {
            case 'import':
                return (
                    <ImportModelDialog
                        rowDetails={row}
                        open={true}
                        onClose={handleDialogClose}
                        importModelColumns={importModelColumns}
                    />
                );
            case 'update':
                return (
                    <UpdateModelDialog
                        rowDetails={row}
                        open={true}
                        onClose={handleDialogClose}
                        setIsBtnMenuOpen={setIsBtnMenuOpen}
                    />
                );
            case 'publish':
                return (
                    <ConfirmationDialog
                        open={true}
                        title="Confirm Publish Method"
                        content={<>You are going to publish "<b>{publishMethod}</b>" PCR/PCS files. Confirm if you want to proceed with publishing.</>}
                        onClose={handleDialogClose}
                        onConfirm={handlePublish}
                        caption="Confirm"
                        bgColor="#0278AB"
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Popover
            id={id}
            open={isBtnMenuOpen.state}
            anchorEl={isBtnMenuOpen.el}
            onClose={() => setIsBtnMenuOpen({ anchorEl: null, state: false })}
            className="popOver"
            elevation={3}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
            <div>
                <Button fullWidth variant="text" className="btnStyles" disabled={actions.import.disabled} onClick={() => handleDialogOpen('import')}>
                    Import PM files
                </Button>
                <Button fullWidth variant="text" className="btnStyles" disabled={actions.productionDraft.disabled} onClick={() => handleNavigation(`/model/${kmatID}/${actions.productionDraft.version}`)}>
                    Production Draft
                </Button>
                <Button fullWidth variant="text" className="btnStyles" disabled={actions.lastDraft.disabled} onClick={() => handleNavigation(`/model/${kmatID}/${actions.lastDraft.version}`)}>
                    Last Draft
                </Button>
                <Button fullWidth variant="text" className="btnStyles" disabled={actions.pcxFiles.disabled} onClick={() => handleNavigation(`/files/${kmatID}/production`)}>
                    PCX File
                </Button>
                <Button fullWidth variant="text" className="btnStyles" disabled={actions.publish.disabled} onClick={() => handleDialogOpen('publish')}>
                    Publish
                </Button>
                {role === "admin" &&
                    <Button fullWidth variant="text" className="btnStyles" disabled={actions.update.disabled} onClick={() => handleDialogOpen('update')}>
                        Update
                    </Button>
                }
            </div>
            {renderActionsDialog()}
        </Popover>
    );
};