import { CLEAR_DASHBOARD_FILTERS, FILTER_DASHBOARD, REFRESH_DASHBOARD } from 'constants/dashboardConstants';
import { applyFilters } from 'pages/Utils/FilterUtil';

const initDashboard = {
    productLines: [],
    productModels: [],
    users: [],
    statuses: [],
    role: '',
    filters: {},
    filteredProducts: [],
    isLoading: true
}

export const dashboardReducer = (state = initDashboard, { type, payload }) => {
    switch (type) {

        case REFRESH_DASHBOARD: {
            const newData = payload || initDashboard;
            const newState = {
                ...newData,
                filters: state.filters,
                isLoading: false
            };

            const itemsToFilter = payload?.productModels?.length ? payload.productModels : [];
            const filteredProducts = applyFilters(Object.values(state.filters), itemsToFilter)

            return {
                ...newState,
                filteredProducts
            };
        }
        
        case CLEAR_DASHBOARD_FILTERS: {
            return {
                ...state,
                filters: {},
                filteredProducts: state.productModels
            };
        }

        case FILTER_DASHBOARD: {
            const { name, searchText, matchFields, type } = payload;
            const filters = {
                ...state.filters,
                [name]: {
                    value: searchText,
                    type,
                    matchFields
                }
            }
            
            const filteredProducts = applyFilters(Object.values(filters), state.productModels)

            return {
                ...state,
                filters,
                filteredProducts
            };
        }

        default: return state;
    }
}

export default dashboardReducer;