import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    list: {
        padding: "2px 0 0px 2px",
    },
    CancelOutlined: {
        color: "#f50057",
    },
    childUl: {
        listStyleType: "square",
        listStyleImage: "none",
        paddingLeft: "15px",
    },
    ul: {
        listStyleType: "square",
        listStyleImage: "none",
        paddingLeft: "60px",
        width: "100%",
        maxWidth: "500px",
        wordBreak: "break-word",
        overflow :"scroll",
        overflowX :"hidden",
        height :"150px",
        "&::-webkit-scrollbar": {
            width: "15px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "rgb(179, 178, 178)",
            borderRadius: "20px",
            border: "1px solid rgb(179, 178, 178)",
          },
    },
    descrError: {
        margin: "2px 0px 5px 11px !important",
        display: "flex !important",
        flexDirection: "row !important"
    },
    RemoveIcon: {
        border: ".025px solid #BE1313",
        borderRadius: "90px",
        backgroundColor: "#BE1313",
    },
    textField: {
        fontSize: "17px",
        color: "#2C3E50",
        paddingBottom: "1px",
        paddingRight: "5px",
        paddingLeft: "2px",
    },
    viewMoreButton: {
        color: "#BE1313 !important",
        marginTop: "2px",
        cursor: "pointer",
      },
    container: {
        border: "1px solid #F5B7B1",
        backgroundColor: "#F5B7B1",
        borderRadius: "10px",
        marginBottom: "15px",
        paddingBottom: "10px",
        marginTop: "10px",
    },
    gridItem: {
        display: "flex",
        flexDirection: "row",
        marginTop: "10px !important",
        marginLeft: "5px !important",
    },
    closeButton: {
        backgroundColor: "#EBF5FB !important",
        color: "#3498DB !important ",
    },
}));

export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "10px"
};
