import { memo, useState } from 'react';

import '../ReactCheckboxTree/ReactCheckboxTree.css'
import CheckBoxTreeTopBar from './CheckBoxTreeTopBar'
import ReactCheckboxTree from './ReactCheckboxTree'

export default memo (function CheckBoxTreeContainer(props) {

    const [isAllExpanded, setIsAllExpanded] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');

    const onExpandCollapse = (isAllExpanded) => {
        setIsAllExpanded(isAllExpanded);
    }

    const onSearch = (text) => {
        setSearchKeyword(text);
    }

    const treeContainerSelect = (n) => {
        props.onSelect(n);
    }

    return (
        <div className='tree-container'>
            <CheckBoxTreeTopBar dataType={props.dataType} activeTab={props.activeTab} onExpandCollapseClick={onExpandCollapse} onSearch={onSearch} onNewAddition={treeContainerSelect} kmatId ={props.kmatId} currentVersion = {props.currentVersion}/>
            <hr className='horizontal-ruler' />
            <ReactCheckboxTree dataType={props.dataType} activeDataSet={props.activeDataSet} activeTab={props.activeTab} onSelect={treeContainerSelect} onExpandCollapseClick={isAllExpanded} onSearch={searchKeyword} checked={props.checked} reset={props.reset} currentVersion = {props.currentVersion} kmatId = {props.kmatId}/>
        </div>
    )
})