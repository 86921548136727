import React from 'react';
import { Grid } from "@mui/material";
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useStyles, style } from './ErrorToolTipHelpers';


function ErrorToolTip({ data }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const renderInvalidExpressions = (expressions) => {
        return (
            <ul className={classes.childUl}>
                {
                    Array.isArray(expressions) && expressions.map((invalid) => (
                        <>
                            {invalid.invalidExpression && <li className={classes.list}>Expression: {invalid.invalidExpression}</li>}
                            {invalid.invalidCVGs && <li className={classes.list}>Invalid CVG: {invalid.invalidCVGs.join(', ')}</li>}
                            {invalid.duplicateCVGs && <li className={classes.list}>Duplicate CVG: {invalid.duplicateCVGs.join(', ')}</li>}
                        </>
                    ))
                }
            </ul>
        )
    }

    const getErrors = ({ key, value }) => {
        const errorsMap = {
            invalidCondition: <li key={key} className={classes.list}>Invalid Condtion: {value}</li>,
            duplicateDescription: <li key={key} className={classes.list}>Duplicate Descriptions in Item Part Numbers: {value}</li>,
            invalidChoiceIds: <li key={key} className={classes.list}>Invalid Choice Ids: {value}</li>,
            duplicateChoiceids: <li key={key} className={classes.list}>Duplicate Choice Ids: {value}</li>,
            invalidCVG: <li key={key} className={classes.list}>Invalid CVG: {value}</li>,
            duplicateCVGs: <li key={key} className={classes.list}>Duplicate CVGs: {value}</li>,
            invalidExpressions: <li key={key} className={classes.list}>Invalid Expression List: {renderInvalidExpressions(value)} </li>,
            invalidDescription: <li key={key} className={classes.list}>Invalid Description: Description can't be more than 30 chars</li>,
        }

        return errorsMap[key]
    }


    return (
        <Grid className={classes.descrError}>
            <RemoveRoundedIcon className={classes.RemoveIcon} />&nbsp;
            <Typography className={classes.textField}>Syntax error </Typography>
            <Typography className={classes.viewMoreButton} onClick={handleOpen} >View More.</Typography>

            {open &&
                <Modal open={open} onClose={handleClose}>
                    <Box sx={style}>
                        <Typography variant="h5" component="h2"> Validation Failed</Typography>
                        <Grid className={classes.container}>
                            <Box className={classes.gridItem}>
                                <CancelOutlined className={classes.CancelOutlined} />&nbsp;<Typography>Error details</Typography>
                            </Box>
                            <Box >
                                <ul className={classes.ul}>{data.map((invalid) => getErrors(invalid))}</ul>
                            </Box>
                        </Grid>
                        <Button variant="contained" className={classes.closeButton} onClick={handleClose}>
                            Close
                        </Button>
                    </Box>
                </Modal>}
        </Grid>

    );
}

export default ErrorToolTip;