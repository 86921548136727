import { REFRESH_BTPS, CLEAR_BTPS_FILTERS, FILTER_BTPS, GET_BTPS } from 'constants/btpConstants';
import { snackBar } from './modelActions';

export const refreshBtps = (Api) => async (dispatch) => {
    try {
        const res = await Api.query('getBtpCacheInfo');
        dispatch({ type: REFRESH_BTPS, payload: res?.data })
    } catch (error) {
        dispatch({ type: REFRESH_BTPS, payload: null })
        dispatch(snackBar(true, error.message, 'error'));
    }
}

export const getBtpStatus = (Api) => async (dispatch) => {
    try {
        const res = await Api.query('getBTPStatus');
        dispatch({ type: GET_BTPS, payload: res?.data })
    } catch (error) {
        dispatch({ type: GET_BTPS, payload: null })
        dispatch(snackBar(true, error.message, 'error'));
    }
}

export const triggerBtp = (checkedRows, Api) => async (dispatch) => {
    try {
        const response = await Api.query('triggerBTP', { kmatList: checkedRows });
        dispatch(refreshBtps(Api));
        dispatch(getBtpStatus(Api));
        dispatch(snackBar(true, response.data?.triggerBTP?.message, 'success'));
    } catch (error) {
        dispatch(snackBar(true, error.message, 'error'));
    }
};

export const clearBtpsFilters = () => (dispatch) => {
    dispatch({ type: CLEAR_BTPS_FILTERS })
}

export const filterBtps = (payload) => (dispatch) => {
    dispatch({ type: FILTER_BTPS, payload })
}
