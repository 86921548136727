import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { deleteUser } from 'actions/userActions';


export const DeleteUserDialog = (props) => {
  const { onClose, open, chosenUser } = props;
  const dispatch = useDispatch();

  const handleDeleteUser = () => {
    dispatch(deleteUser(chosenUser));
    onClose();
  };

  return (
    <Dialog className='custom-dialog' open={open} onClose={onClose}>
      <DialogTitle variant='h6'> {"Delete User Details"}</DialogTitle>
      <DialogContent sx={{ fontSize: "19px", marginBottom: "15px" }} dividers>
        You are about to delete the following user: <br />
        <Typography color={'error'} sx={{ fontSize: "17px !important", margin: "10px 0px 0px 20px" }}> {chosenUser.firstName + ' ' + chosenUser.lastName} </Typography>
        <Typography color={'inherit'} sx={{ fontSize: "17px !important", marginLeft: "20px" }}> {chosenUser.email} </Typography> <br />
        Are you sure ? This process can't be undone.
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose} className='cancel-button'> Cancel </Button>
        <Button onClick={handleDeleteUser} className='delete-button' > Delete </Button>
      </DialogActions>
    </Dialog>
  );
}
