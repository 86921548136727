import React, { useRef, useState } from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { style, useRowStyles } from "./MultiItemEditHelpers";
import { DEFAULT_CONDITION, DEFAULT_CONDITION_BULK_EDIT, FILTER_CONDITION, FILTER_CONDITION_BULK_EDIT, REPLACE_DEFAULT_CONDITION, REPLACE_FILTER_CONDITION, modalTitle } from "constants/modelConstants";

export const RowActionableButtons = ({ handleDataUpdate, choiceID, itemKey, setItemKey }) => {
    const classes = useRowStyles();
    const findTextFieldRef = useRef(null);
    const replaceTextFieldRef = useRef(null);
    const saveButtonRef = useRef(null);
    const [itemFilter, setItemFilter] = useState("");
    const [textToReplace, setTextToReplace] = useState("");

    const handleClose = () => setItemKey(null);

    const handleOnChange = (event, setValue) => {
        const newValue = event.target.value.toUpperCase();
        setValue(newValue);
        if (newValue.endsWith('\n')) {
            if (itemFilter || itemFilter === '') {
                handleSave();
            } else {
                handleClose();
            }
        }
    };

    const handleSave = () => {
        handleDataUpdate((prevState) => prevState.map(choice => {
            if (choice.choiceID !== choiceID)
                return choice;

            return {
                ...choice, items: choice.items.map(item => {

                    if (itemKey === DEFAULT_CONDITION_BULK_EDIT)
                        return { ...item, [DEFAULT_CONDITION]: itemFilter, edited: true };

                    if (itemKey === FILTER_CONDITION_BULK_EDIT)
                        return { ...item, [FILTER_CONDITION]: itemFilter, edited: true };

                    if ( itemKey === REPLACE_DEFAULT_CONDITION && item[DEFAULT_CONDITION].includes(textToReplace) ) {
                            if (!(textToReplace === "" && item[DEFAULT_CONDITION] !== "")) 
                            return { ...item, [DEFAULT_CONDITION]: item[DEFAULT_CONDITION].replaceAll(textToReplace, itemFilter), edited: true };
                        }
                    if ( itemKey === REPLACE_FILTER_CONDITION && item[FILTER_CONDITION].includes(textToReplace) ) {
                            if (!(textToReplace === "" && item[FILTER_CONDITION] !== "")) 
                            return { ...item, [FILTER_CONDITION]: item[FILTER_CONDITION].replaceAll(textToReplace, itemFilter), edited: true }; 
                        }

                    return item;
                })
            }
        }));
        handleClose();
    };

    return (
        <Modal open={Boolean(itemKey)} onClose={handleClose}>
            <Box sx={style}>
                <Typography variant="h5" component="h2"> {modalTitle[itemKey]}</Typography>
                {itemKey === DEFAULT_CONDITION_BULK_EDIT || itemKey === FILTER_CONDITION_BULK_EDIT ? (
                    <TextField
                        autoFocus
                        fullWidth
                        variant="filled"
                        placeholder="Condition"
                        label="Condition"
                        multiline
                        minRows={1}
                        value={itemFilter}
                        className={classes.filledTextField}
                        InputProps={{ disableUnderline: true }}
                        onChange={(event) => handleOnChange(event, setItemFilter)}
                    />
                ) : (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                            autoFocus
                            inputRef={findTextFieldRef}
                            fullWidth
                            variant="filled"
                            placeholder={`${itemKey === REPLACE_FILTER_CONDITION ? 'Filter' : 'Default'} condition to replace`}
                            label={`${itemKey === REPLACE_FILTER_CONDITION ? 'Filter' : 'Default'} condition to replace`}
                            multiline
                            minRows={1}
                            className={classes.filledTextField}
                            value={textToReplace}
                            InputProps={{ disableUnderline: true }}
                            onChange={(event) => handleOnChange(event, setTextToReplace)}
                        />
                        <TextField
                            inputRef={replaceTextFieldRef}
                            fullWidth
                            variant="filled"
                            placeholder={`Replacament ${itemKey === REPLACE_FILTER_CONDITION ? 'Filter' : 'Default'} condition`}
                            label={`Replacament ${itemKey === REPLACE_FILTER_CONDITION ? 'Filter' : 'Default'} condition`}
                            multiline
                            minRows={1}
                            className={classes.filledTextField}
                            value={itemFilter}
                            InputProps={{ disableUnderline: true }}
                            onChange={(event) => handleOnChange(event, setItemFilter)}
                        />
                    </div>
                )}
                <Box style={{ textAlign: 'end', marginTop: '10px' }}>
                    <Button variant="outlined" onClick={handleClose} style={{ borderRadius: "10px" }}>Cancel</Button>
                    <Button ref={saveButtonRef} variant="contained" onClick={handleSave} className={classes.saveButton}>Update</Button>
                </Box>
            </Box>
        </Modal>
    );
};
