export const getBtpCacheInfo = `query {
    getBtpCacheInfo{
            objectType
            key
            value
            modelDesc
            productline
          }
  }`;

export const getBTPStatus = `query {
    getBTPStatus{
        isRunning
        executionDetail {
            name
            status
            startDate
            stopDate
            executionArn
      }
    }
  }`;

export const triggerBTP = `mutation ($kmatList: [String!]!) {
    triggerBTP(filter: { kmatList: $kmatList }) {
      isSuccess
      message
    }
  }`;
