import { importModel } from "./importModel"
import { getNewModels } from "./getNewModels"
import { syntaxCheck } from "./syntaxCheck"
import { syntaxCheckRegional } from "./syntaxCheckRegional"
import { createDraft } from "./createDraft"
import { getWorkSpaceVersions } from "./getWorkSpaceVersions"
import { renewToken } from "./renewToken";
import { getProductModelByProductLine } from "./getProductModelByProductLine";
import { exportData } from "./exportData";
import { exportRegionalData } from "./exportRegionalData";
import { getLastModVer } from "./getLastModVer";
import { publishDraft } from "./publishDraft";
import { publishRegion } from "./publishRegion";
import { updateItems } from "./updateItems";
import { updateRegionalItem } from "./updateRegionalItem";
import { healthStatus } from "./healthStatus";
import { getModelChoiceIdVer } from "./getModelChoiceIdVer"
import { getModelChoiceIdLastVer } from "./getModelChoiceIdLastVer"
import { updateChoices } from "./updateChoices"
import { createChoiceNew } from "./createChoice"
import { createItem } from "./createItem"
import { createRegionalItem } from "./createRegionalItem"
import { getUsers } from './getUsers'
import { createKbUser } from './createKbUser'
import { deleteKbUser } from './deleteKbUser'
import { updateKbUser } from './updateKbUser'
import { dashboard } from './dashboard'
import { fetchModel } from './fetchModel'
import { updateModel } from './updateModel'
import { importModelV2 } from './importModelV2'
import { templates } from './templates'
import { fetchTemplate } from './fetchTemplate'
import { fetchRegional } from './fetchRegional'
import { regionals } from './regionals'
import { kbUser } from './kbUser'
import { choiceSequence } from "./choiceSequence"
import { updateChoiceSequence } from "./updateChoiceSequence"
import { deleteItem } from "./deleteitem"
import { deleteRegionalItem } from "./deleteRegionalItem"
import { deleteChoice } from "./deleteChoice"
import { createWNewRoles } from "./createWNewRoles"
import { switchRole } from "./switchRole"
import { addFavorite, pcxDownload, pcxFiles, pcxModels, removeFavorite } from "./PCX"
import { getBtpCacheInfo, getBTPStatus, triggerBTP } from "./BTP"
import { downloadExcelFile } from "./downloadExcelFile"
import { favoriteDownload } from "./favoriteDownload"
import { startValidation } from "./startValidation"

export const queries = {
  addFavorite,
  choiceSequence,
  createChoiceNew,
  createDraft,
  createItem,
  createKbUser,
  createRegionalItem,
  createWNewRoles,
  dashboard,
  deleteChoice,
  deleteItem,
  deleteKbUser,
  deleteRegionalItem,
  downloadExcelFile,
  exportData,
  exportRegionalData,
  favoriteDownload,
  fetchModel,
  fetchRegional,
  fetchTemplate,
  getLastModVer,
  getModelChoiceIdLastVer,
  getModelChoiceIdVer,
  getNewModels,
  getProductModelByProductLine,
  getUsers,
  getWorkSpaceVersions,
  healthStatus,
  importModel,
  importModelV2,
  kbUser,
  pcxDownload,
  pcxFiles,
  pcxModels,
  getBtpCacheInfo,
  getBTPStatus,
  triggerBTP,
  publishDraft,
  publishRegion,
  regionals,
  removeFavorite,
  renewToken,
  switchRole,
  templates,
  updateChoices,
  updateChoiceSequence,
  updateItems,
  updateKbUser,
  updateModel,
  updateRegionalItem,
  startValidation,
  syntaxCheck,
  syntaxCheckRegional
};
