import { useSelector } from 'react-redux';
import { Grid, TextField } from '@mui/material';
import { updateChoiceItemFields } from 'actions/modelActions';
import useGlobalStore from 'hooks/useGlobalStore';
import { getTab } from 'pages/Utils';
import { useMemo } from 'react';


export const Comments = ({ itemID, choiceID, tabName }) => {
  const choiceData = useSelector(state => state.models)[getTab(tabName)][choiceID];
  const choiceDataString = JSON.stringify(choiceData);
  const getCommentsSource = useMemo(() => {
    const choiceDataObject = JSON.parse(choiceDataString);
    const fromStore = itemID ? choiceDataObject.items[itemID] : choiceDataObject;
    return fromStore?.comments?.length ? fromStore : ''
  }, [choiceDataString, itemID]);
  const [itemData, setItemData] = useGlobalStore(getCommentsSource, updateChoiceItemFields, { choiceID, itemID, tabCategory: tabName });

  const handleOnChange = (event, field) => {
    let newVal = event.target.value;

    setItemData((prevData) => ({
      ...prevData,
      [field]: newVal,
      edited: true
    }));
  };

  return (
    <Grid>
      <TextField
        variant="filled"
        multiline
        fullWidth
        minRows={25}
        margin="dense"
        InputProps={{ disableUnderline: true }}
        id="comments"
        onChange={(e) => handleOnChange(e, 'comments')}
        value={itemData?.comments || ""}
      />
    </Grid>
  )
}

export default Comments;