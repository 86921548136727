import { GraphqlApi } from "services/api";
import { batch } from "react-redux";
import {
  CREATE_WORKSPACE_REQUEST,
  CREATE_WORKSPACE_SUCCESS,
  CREATE_WORKSPACE_FAIL,
  LASTWORKSPACE_FETCH_SUCCESS,
  PUBLISH_VERSION_REQUEST,
  PUBLISH_VERSION_SUCCESS,
  PUBLISH_VERSION_FAIL,
  TAB_SELECTION,
  CANCEL_INIT,
  CVG_TAB_SELECTION,
  RESET_TREE,
  PUBLISH_REGION_REQUEST,
  PUBLISH_REGION_SUCCESS,
  PUBLISH_REGION_FAIL,
  SAVE_INIT,
  FETCH_REQUEST,
  FETCH_FAIL,
  ADD_CHOICE,
  ADD_ITEM,
} from "constants/modelConstants";
import { getChoicesMutData, getItemsMutData, handleData } from './modelActionsUtils';
import { clearModelState, rememberProperty } from './undoActions';
import { CONTENT_KEY, deriveVcItemId, formatChoiceName, formatChoiceText, formatItemName, formatItemText } from "reducers/modelUtils";
import { SHOW_GENERIC_SNACKBAR } from 'constants/modelConstants';
import { isSystemChoice } from "../reducers/modelUtils";
import { u8arrFromBase64EncodedString } from "utils/string-utils";
import fileDownload from "js-file-download";


export const snackBar = (showGenericSnackbar, genericSnackbarMessage, snackbarSeverity = 'error') => async (dispatch) => {
  dispatch({
      type: SHOW_GENERIC_SNACKBAR, payload: {
          showGenericSnackbar,
          genericSnackbarMessage,
          snackbarSeverity
      },
  });
};

export const updateWorkspaceVerion = (kmatId, version) => (dispatch) => {
  dispatch({ type: LASTWORKSPACE_FETCH_SUCCESS, payload: { data: version, kmat: kmatId }, });
};

/**
 * Function to create new workspace version
 * @param {kmatId} string
 * @param {version} string
 */
export const createWs = (kmatId, version, history) => async (dispatch) => {
  try {
    const createWS = await GraphqlApi.query("createDraft", {
      model: { kmatID: kmatId, modVer: version },
    })

    if (createWS?.data.createDraft) {
      dispatch({ type: CREATE_WORKSPACE_REQUEST });
      batch(() => {
        dispatch({ type: CREATE_WORKSPACE_SUCCESS, payload: { data: createWS.data.createDraft.modVer, kmat: kmatId } });
        dispatch(clearModelState());
        dispatch(fetchData(kmatId, createWS.data.createDraft.modVer, "model"));
      });
      history.push({ pathname: "/model/" + kmatId + "/" + createWS.data.createDraft.modVer })
    }
  } catch (error) {
    dispatch(snackBar(true, error.message));
    dispatch({ type: CREATE_WORKSPACE_FAIL, payload: error.message });
  }
};

/**
 * Function to validate workspace for all condition
 * @param {kmatId} string
 * @param {version} string
 */
export const syntaxCheck = (kmatID, modVer) => async (dispatch) => {
  try {
    const queryName = modVer ? "syntaxCheck" : "syntaxCheckRegional";
    const { data } = await GraphqlApi.query(queryName, { kmatID, modVer });
    dispatch({ type: 'SYNTAX_CHECK_SUCCESS', payload: data[queryName]});
  } catch (error) {
    dispatch(snackBar(true, 'Data has not been validated: ' + error.message));
  }
};

/**
 * Function to generate PCR/PCS file
 * @param {kmatId} string
 * @param {version} string
 * @param {isRegional} boolean
 */
export const exportFiles = (kmatID, currentVersion, isRegional) => async (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      let queryName = 'exportRegionalData';
      let filter = { kmatID };
      if (!isRegional) {
        queryName = 'exportData';
        filter.modVer = currentVersion;
      }
      const exportData = await GraphqlApi.query(queryName, {filter});
      
      //it implies that an error occurred during the execution of the exportData function.
      if (exportData instanceof Error) {
        throw exportData;
      }
      const exportDataResponse = isRegional ? "exportRegional" : "export";
      if (Object.keys(exportData.data[exportDataResponse]).length === 0) {
        reject();
      } else {
        resolve(exportData.data[exportDataResponse]);
      }
    } catch (error) {
      dispatch(snackBar(true, error.message));
      reject(error.message);
    }
  });
};

export const downloadExcelFile = (kmatId, currentVersion, handlePopClose) => async (dispatch) => {
  try {
    const response = await GraphqlApi.query('downloadExcelFile', { filter: { kmatID: kmatId, modVer: currentVersion } });
    const { name, content } = response?.data?.exportExcel;

    if (!name || !content) {
      throw new Error(`Failed to download excel file for model ${kmatId}, version ${currentVersion}`);
    }

    const u8arr = u8arrFromBase64EncodedString(content);
    const file = new File([u8arr], name);

    fileDownload(file, name);

    dispatch(snackBar(true, 'Excel file generated successfully', 'success'));
  } catch (error) {
    dispatch(snackBar(true, error.message, 'error'));
  } finally {
    handlePopClose();
  }
};

export const tabSelection = (activeTab) => (dispatch) =>
  dispatch({ type: TAB_SELECTION, payload: activeTab, });

export const cvgTabSelection = (activeCVGTab) => (dispatch) =>
  dispatch({ type: CVG_TAB_SELECTION, payload: activeCVGTab });

export const updateCancelInit = (bool) => async (dispatch) => {
  dispatch({ type: CANCEL_INIT, payload: bool });
}

export const resetTree = (bool) => (dispatch) => {
  dispatch({ type: RESET_TREE, payload: bool });
}

export const updateSaveInit = (bool) => async (dispatch) => {
  dispatch({ type: SAVE_INIT, payload: bool });
}

export const saveSuccess = () => async (dispatch, getState) => {
  dispatch(updateSaveInit(false))
  const { currentVersion, kmatId } = getState().models;

  try {
    await saveModels(dispatch, getState, kmatId, currentVersion)
  } catch (error) {
    dispatch(snackBar(true, error.message));
  }
}

export const saveModels = async (dispatch, getState, kmatID, modVer,) => {
  try {
    const { undo, dataType, ...model } = getState().models;
    const isRegional = dataType === "regional";
    const changedItems = undo.editedProps;

    const { items, choices } = changedItems?.reduce((acc, change) => {
      const [tab, choiceID] = change.split("::")
      const current = model[tab][choiceID]

      const choice = getChoicesMutData(kmatID, modVer, current);
      const items = getItemsMutData(kmatID, modVer, Object.values(current.items));

      return { items: [...acc.items, ...items], choices: choice ? [...acc.choices, choice] : acc.choices }
    },
      { items: [], choices: [] }
    )

    if (!choices?.length && !items?.length) {
      throw new Error("There are no changes to save")
    }
    let updatedChoices, updatedItems;

    if (choices.length && !isRegional) {
      const { data } = await GraphqlApi.query(`updateChoices`, { model: { kmatID, choices } });
      updatedChoices = data?.updateChoices;
    }

    if (items.length) {
      const updateItemQuery = isRegional ? "updateRegionalItem" : "updateItems";
      const { data } = await GraphqlApi.query(updateItemQuery, { model: { kmatID, items } });
      if (isRegional) {
        updatedItems = data?.updateRegionalItems;
      } else {
        updatedItems = data?.updateItems;
      }
    }

    batch(() => {
      dispatch({ type: 'SAVE_SUCCESS', payload: { updatedChoices, updatedItems } });
      dispatch({ type: 'CLEAR_UNDO_STATE' });
      let formattedDataType = dataType.charAt(0).toUpperCase() + dataType.slice(1).toLowerCase();
      dispatch(snackBar(true, `KB ${formattedDataType} Saved Successfully`, "success"));
    })
  } catch (error) {
    dispatch({ type: 'WORKSPACE_CHOICE_SAVE_FAIL', payload: error.message });
    dispatch(snackBar(true, error.message, "error"));
  }
};

export const createChoice = (formData, props) => async (dispatch) => {
  try {
      const { choiceId, choiceDescription, choiceDataType, isHidden } = formData;
      const choiceDetails = {
          kmatID: props.kmatId,
          modVer: props.currentVersion,
          choiceID: choiceId,
          description: choiceDescription,
          dataType: choiceDataType,
          isHidden: isHidden,
          tabCategory: props.activeTab,
      };

      const { data, errors } = await GraphqlApi.query("createChoiceNew", { model: { ...choiceDetails } });
      if (errors) throw new Error(errors[0]?.message);

      const { createChoice } = data;
      const formatedChoice = {
          ...createChoice,
          selectionType: 'Single',
          isTemplate: createChoice.isTemplate,
          isManual: createChoice.isManual,
          parentId: '0',
          id: createChoice.choiceID,
          isRequired: 'false',
          name: formatChoiceName(createChoice),
          [CONTENT_KEY]: formatChoiceText(createChoice, props.dataType),
          edited: false,
          allowedCVGs: ""
      };
      batch(() => {
          dispatch({ type: ADD_CHOICE, payload: { ...formatedChoice, items: {} } });
          props.onSelect(formatedChoice);
          const type = props.dataType === "template" ? 'Template choice' : 'Choice';
          dispatch(snackBar(true, `${type} ${formatedChoice.choiceID} was successfully created.`, "success"));
          props.handleDialogClose();
      });

      return formatedChoice;
  } catch (error) {
      dispatch(snackBar(true, error.message, "error"));
  }
};

export const createItem = (queryName, formData, props) => async (dispatch) => {
  try {
      const { partNumber, valueId, sortString, partDescription, materialType } = formData;
      let choiceItemDetails = {
          kmatID: props?.kmatId,
          modVer: props?.currentVersion,
          choiceID: props.activeNode?.choiceID,
          description: partDescription,
          partNumber: partNumber,
          valueID: valueId,
          sortString: sortString,
          materialType: materialType,
          tabCategory: props.activeTab,
      };
      const { data, errors } = await GraphqlApi.query(queryName, { model: { ...choiceItemDetails } });
      if (errors) {
          throw new Error(errors[0]?.message);
      }

      const item = data[queryName];
      const formatedItem = {
          ...item,
          itemID: item.choiceItemID,
          partDescription:  isSystemChoice(props.activeNode?.dataType) ? "" : item.description,
          isTemplate: item.isTemplate,
          isManual: item.isManual,
          id: item.choiceItemID,
          parentId: item.choiceID,
          name: formatItemName(item),
          [CONTENT_KEY]: formatItemText(item),
          vcItemId: deriveVcItemId(item),
          edited: false,
          cvgs : [],
          isSystemChoice: isSystemChoice(props.activeNode?.dataType)
      };

      batch(() => {
          dispatch({ type: ADD_ITEM, payload: formatedItem });
          props.onHandleCb(formatedItem);
          dispatch(snackBar(true, `Part Number ${formatedItem.partNumber} was successfully created.`, "success"));
          props.handleDialogClose();
      });

      return formatedItem;
  } catch (error) {
      dispatch(snackBar(true, error.message, "error"));
  }
};

export const deleteChoice = async ({ kmatID, modVer, choiceID, tabCategory }) => {
  try {
    const { errors, data } = await GraphqlApi.query("deleteChoice", {
      model: { kmatID, modVer, choiceID, tabCategory },
    });

    if (errors)
      throw new Error(errors[0]?.message);

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export const deleteItem = async ({ kmatID, modVer, itemID, query }) => {
  try {
    const { data, errors } = await GraphqlApi.query(query, {
      model: { kmatID, modVer, choiceItemID: itemID }
    })

    if (errors) {
      throw new Error(errors[0]?.message);
    }
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export const fetchData = (kmatID, modVer, dataType) => async (dispatch) => {
  const queryType = {
    'model': 'fetchModel',
    'template': 'fetchTemplate',
    'regional': 'fetchRegional'
  }[dataType];

  dispatch({ type: FETCH_REQUEST });

  try {
    if (!modVer) throw new Error("Missing version");
    if (!dataType) throw new Error("Missing data type");
    if (!queryType) throw new Error("Invalid data type");

    const { data } = await GraphqlApi.query(queryType, { kmatID, modVer });
    const fetchedData = data[queryType];
    const { kmatID: argKmatID, modVer: argModVer } = fetchedData.model;

    batch(() => {
      dispatch({
        type: LASTWORKSPACE_FETCH_SUCCESS,
        payload: { data: argModVer, kmat: argKmatID },
      });

      handleData(dispatch, fetchedData, dataType)
    });
  } catch (error) {
    dispatch(snackBar(true, error.message));
    dispatch({ type: FETCH_FAIL, payload: error.message });
  }
};

/**
* Function to publish the kmat version && change the kmat status to 'In validation'
* @param {string} kmatId
* @param {string} version - the kmat version we want to publish
* @param {'scit' | 'production' | 'pilot'} publishMethod
* @returns { publishDraft: { kmatID: string, modVer: string} | undefined} the response of publishDraft mutation or undefined
* @returns { startValidation: null | undefined} the response of startValidation mutation or undefined
*/
export const publishVersion = (kmatId, version, publishMethod) => async (dispatch) => {
  dispatch({ type: PUBLISH_VERSION_REQUEST });
  try {
    let queryName = 'startValidation';
    let model = { kmatID: kmatId, modVer: version };
    if (publishMethod) {
      queryName = 'publishDraft';
      model = { model: { kmatID: kmatId, modVer: version, kind: publishMethod } };
    }

    const { data } = await GraphqlApi.query(queryName, model);

    const payload = publishMethod ? { data: data.publishDraft.modVer, clearValidation: null } : { data: version, clearValidation: null };

    dispatch({ type: PUBLISH_VERSION_SUCCESS, payload: { ...payload, kmat: kmatId } });
    return data;
  } catch (error) {
    dispatch(snackBar(true, error.message));
    dispatch({ type: PUBLISH_VERSION_FAIL, payload: error.message });
  }
};

export const publishRegional = (kmatId, tabName) => async (dispatch) => {
  dispatch({ type: PUBLISH_REGION_REQUEST });
  try {
    const { data } = await GraphqlApi.query("publishRegion", {
      model: { kmatID: kmatId, region: tabName }
    });
    dispatch({ type: PUBLISH_REGION_SUCCESS, payload: { data: data.publishRegion, clearValidation: null } });
  } catch (error) {
    dispatch(snackBar(true, error.message));
    dispatch({ type: PUBLISH_REGION_FAIL, payload: error.message });
  }
};

export const updateChoiceItemFields = (payload) => (dispatch, getState) => {
  const { choiceID } = payload[1];
  const { saveInit, cancelInit } = getState()?.models || { saveInit: false, cancelInit: false };
  if (!cancelInit) {
    dispatch(rememberProperty(choiceID));
    dispatch({ type: "UPDATE_ITEM_FIELDS", payload });
  } else {
    dispatch(updateCancelInit(false));
  }
  if (saveInit) dispatch(saveSuccess());
}
