import { REFRESH_TEMPLATES, CLEAR_TEMPLATES_FILTERS, FILTER_TEMPLATES } from 'constants/templatesConstants';
import { GraphqlApi } from 'services/api';
import { snackBar } from './modelActions';

export const refreshTemplates = () => async (dispatch) => {
    try {
        const res = await GraphqlApi.query('templates');
        dispatch({ type: REFRESH_TEMPLATES, payload: res?.data?.templates })
    } catch (error) {
        dispatch({ type: REFRESH_TEMPLATES, payload: null })
        dispatch(snackBar(true, error.message, "error"));
    }
}

export const clearTemplatesFilters = () => (dispatch) => {
    dispatch({ type: CLEAR_TEMPLATES_FILTERS })
}

export const filterTemplates = (payload) => (dispatch) => {
    dispatch({ type: FILTER_TEMPLATES, payload })
}
