import React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from "@mui/material/Typography";
import { useDispatch } from 'react-redux';
import { createUser } from 'actions/userActions';
import { getInputProps } from 'pages/DashboardHelpers';


export const AddDialog = ({ open, onClose, handleChange, selectedValue, remodeledRoleList }) => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const createNewKbUser = () => {
    dispatch(createUser(firstName, lastName, email, selectedValue));
    onClose();
  };


  return (
    <>
      <Dialog open={open} onClose={onClose} className='custom-dialog'>
        <DialogTitle variant='h6'> {"Add New User"}</DialogTitle>
        <DialogContent>
          <Typography variant="h6" className='sub-header'> User Details </Typography>
          <TextField
            fullWidth
            variant="outlined"
            autoFocus={true}
            InputProps={getInputProps()}
            placeholder="First Name"
            value={firstName}
            onChange={(e) => { setFirstName(e.target.value) }}
          />
          <TextField
            fullWidth
            variant="outlined"
            InputProps={getInputProps()}
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => { setLastName(e.target.value) }}
          />
          <TextField
            fullWidth
            variant="outlined"
            InputProps={getInputProps()}
            placeholder="Email Address"
            value={email}
            onChange={(e) => { setEmail(e.target.value) }}
          />
          <Typography variant="h6" className='sub-header'> Assign Role </Typography>
          <FormControl fullWidth>
            <Select
              styles={{ control: (provided) => ({ ...provided, padding: '6px', borderRadius: "12px" }) }}
              placeholder="User Role"
              value={remodeledRoleList?.filter(role => selectedValue.includes(role.value))}
              options={remodeledRoleList}
              onChange={handleChange}
              menuPosition="fixed"
              isMulti
              isClearable={false}
            />
          </FormControl>
        </DialogContent>
        <DialogActions className='dialog-actions'>
          <Button variant="outlined" className='cancel-button' onClick={onClose}> Cancel </Button>
          <Button variant="outlined" onClick={(e) => createNewKbUser(e)} className='add-Button'>
            +&nbsp; Add User
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};