import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Accordion, AccordionDetails, AccordionSummary, TableContainer, Paper, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import PriorityHighTwoToneIcon from '@mui/icons-material/PriorityHighTwoTone';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import EditDot from "./EditDot";
import { useRowStyles, StyledTableCell, StyledTableRow } from "./MultiItemEditHelpers";
import { getTab } from "pages/Utils";
import { DEFAULT_CONDITION, FILTER_CONDITION, DEFAULT_CONDITION_BULK_EDIT, FILTER_CONDITION_BULK_EDIT, REPLACE_DEFAULT_CONDITION, REPLACE_FILTER_CONDITION } from "constants/modelConstants";
import { debounce } from "utils/function-utils";
import { RowActionableButtons } from "./RowAction";


const Row = (props) => {
  const { row, handleDataUpdate, tabName } = props;
  const classes = useRowStyles();
  const itemsWithErrors = useSelector(state => state.models.syntaxCheck[getTab(tabName)][row.choiceID]?.items);
  const showIcons = row.items.some((item) => !item.isSystemChoice);

  const [itemKey, setItemKey] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const containerRef = useRef(null);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollHeight - scrollTop <= clientHeight + (row?.items?.length || 0)) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const debouncedHandleScroll = useRef(debounce(handleScroll, 100)).current;

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      container.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [currentPage]);

  const handleEditCondition = (event, itemID) => {
    handleDataUpdate((prevState) => prevState.map(choice => {
      if (choice.choiceID !== row.choiceID)
        return choice;

      const { name, value } = event.target;
      return {
        ...choice, items: choice.items.map(item => {
          if (item.itemID === itemID)
            return { ...item, [name]: value, edited: true };

          return item;
        })
      }
    }));
  };

  return (
    <>
      <TableRow >
        <TableCell className={classes.root}>
          <Accordion style={{ boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<KeyboardArrowDownIcon />}
              aria-controls={`choice id ${row.choiceID} content`}
              id={row.choiceID}
            >
              {row.name}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <TableContainer component={Paper} style={{ maxHeight: "650px", overflowY: "auto" }} onScroll={handleScroll} ref={containerRef}>
                <Table aria-label="customized table">
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <StyledTableCell width={5} />
                      <StyledTableCell width={50}>Part#</StyledTableCell>
                      <StyledTableCell width={65}>Value Id</StyledTableCell>
                      <StyledTableCell width={100}>Description</StyledTableCell>
                      <StyledTableCell width={300}>Filter Condition &nbsp;
                        <IconButton onClick={() => setItemKey(FILTER_CONDITION_BULK_EDIT)} ><EditTwoToneIcon color="secondary" /></IconButton>&nbsp;
                        <IconButton onClick={() => setItemKey(REPLACE_FILTER_CONDITION)} > <FindReplaceIcon color="primary" /></IconButton>
                      </StyledTableCell>
                      <StyledTableCell width={300}>Default Condition &nbsp;
                        {showIcons && (
                          <>
                            <IconButton onClick={() => setItemKey(DEFAULT_CONDITION_BULK_EDIT)}> <EditTwoToneIcon color="secondary" /> </IconButton>&nbsp;
                            <IconButton onClick={() => setItemKey(REPLACE_DEFAULT_CONDITION)}><FindReplaceIcon color="primary" /> </IconButton>
                          </>
                        )}
                      </StyledTableCell>
                      <StyledTableCell width={100}>CVG</StyledTableCell>
                      <StyledTableCell width={50}>Counter CVG</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.items?.map((item, index) => {
                      if (index < currentPage * 10) {
                        return (
                          <StyledTableRow key={item.id}>
                            <StyledTableCell align="center" >{itemsWithErrors?.[item.itemID] && <PriorityHighTwoToneIcon className={classes.priorityIcon} />}</StyledTableCell>
                            <StyledTableCell>{item.partNumber}{item.edited && <EditDot />}</StyledTableCell>
                            <StyledTableCell>{item.isSystemChoice ? null : item.valueID || null}</StyledTableCell>
                            <StyledTableCell>{item.description}</StyledTableCell>
                            <StyledTableCell className={classes.textField} align="left">
                              <TextField
                                name={FILTER_CONDITION}
                                variant="outlined"
                                fullWidth
                                value={item.filterCondition}
                                multiline
                                minRows={1}
                                maxRows={1}
                                onChange={(event) => handleEditCondition(event, item.itemID)}
                                className={classes.outlinedTextField}
                                InputProps={{ classes: { input: classes.textarea } }}
                              />
                            </StyledTableCell>
                            <StyledTableCell className={classes.textField} align="left">
                              <TextField
                                disabled={item.isSystemChoice}
                                name={DEFAULT_CONDITION}
                                variant="outlined"
                                fullWidth
                                value={item.defaultCondition}
                                multiline
                                minRows={1}
                                maxRows={1}
                                onChange={(event) => handleEditCondition(event, item.itemID)}
                                className={classes.outlinedTextField}
                                InputProps={{ classes: { input: classes.textarea } }}
                              />
                            </StyledTableCell>
                            <StyledTableCell>{item.cvg}</StyledTableCell>
                            <StyledTableCell>{item.counterCVG}</StyledTableCell>
                          </StyledTableRow>
                        );
                      }
                      return null;
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </TableCell>
      </TableRow>

      {itemKey && (
        <RowActionableButtons
          itemKey={itemKey}
          handleDataUpdate={handleDataUpdate}
          choiceID={row?.choiceID}
          setItemKey={setItemKey}
        />
      )}
    </>
  );
};

export default Row;