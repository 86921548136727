import { Box } from "@mui/material";
import { TextField, FormControl, Grid, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ITEMEDIT_LABELS } from "constants/modelConstants";
import { useStyles } from "./styles";
import ErrorToolTip from "pages/Common/ErrorToolTip";

const selectionTypes = {
    "T": "True",
    "F": "False",
    "TF": "Both",
    "R": "Required",
};

export const PreSelectedItem = ({ condition, errorData, type, index, handleEditComponent, handleDelete, tabName, isSystemChoice }) => {
    const classes = useStyles();

    return (
        <Box key={`item-${index}`}>
            <Grid container style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
                <Grid item xs={6}>
                    <TextField
                        value={condition}
                        onChange={(e) => handleEditComponent(e.target.value, 'condition', index)}
                        multiline
                        id="DefaultComponentCondition"
                        variant="filled"
                        margin="dense"
                        label={ITEMEDIT_LABELS[tabName]["DefaultComponentCondition"]}
                        placeholder="DefaultComponentCondition"
                        InputProps={{
                            classes: { input: classes.textarea },
                            disableUnderline: true
                        }}
                        disabled={isSystemChoice}
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormControl>

                        <TextField
                            value={type}
                            onChange={e => handleEditComponent(e.target.value, 'type', index)}
                            id="DefaultComponentType"
                            select
                            variant="filled"
                            margin="dense"
                            label={ITEMEDIT_LABELS[tabName]["DefaultComponentType"]}
                            InputProps={{ disableUnderline: true }}
                            disabled={isSystemChoice}

                        >
                            {Object.keys(selectionTypes).map(key => (
                                <MenuItem key={key} value={key}>
                                    {selectionTypes[key]}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    {(condition && type) && <DeleteIcon className={classes.deleteButton} style={{ cursor: 'pointer' }} onClick={() => handleDelete(index)} />}
                </Grid>
                {errorData && <Grid item xs={12}>
                    <ErrorToolTip data={errorData} />
                </Grid>}
            </Grid>
        </Box>
    );
};
