export const rememberProperty = (payload) => (dispatch) => {
    dispatch({type: 'REMEMBER_PROPERTY', payload})
}

export const undoProperty = (payload) => (dispatch) => {
    dispatch({type: 'UNDO_PROPERTY', payload})
}

export const clearModelState = () => (dispatch) => {
    dispatch({ type: 'CLEAR_MODEL_STATE' });
  };  