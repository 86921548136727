export const createItem = `mutation($model : CreateItemRequest!){
    createItem(input : $model) {
        kmatID,
        modVer,
        choiceItemID,
        choiceID,
        description,
        partNumber,
        valueID,
        tabCategory,
        isTemplate,
        isManual,
      }
  }`
