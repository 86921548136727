import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  accountMenu: {
    fontFamily: "Forma DJR Micro !important",
    "& .MuiPopover-paper": {
      borderRadius: "12px !important",
      width: "320px !important",
    },
    "& .MuiList-root": {
      fontFamily: "Forma DJR Micro !important",
    },
    "& .MuiMenu-list": {
      padding: "0px !important",
    },
    "& .MuiListItemText-multiline ": {
      marginLeft: "10px !important",
    },
    "& .Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      backgroundColor: " #dce8ed !important",
      color: "#0076AD",
      fontFamily: "Forma DJR Micro !important",
      height: "37px !important",
    },
  },
  appbar: {
    gridArea: "header",
    position: "fixed",
    height: "55px",
    backgroundColor: "white",
    boxShadow: "0 0px 1px",
    color: "black",
  },
  topbarFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    flexDirection: "row",
    height: "100%",
    marginLeft: "148px",
  },
  menuHeaderRight: {
    marginRight: "20px",
    marginTop: "7px",
    float: "right",
  },
  menuUserSettings: {
    cursor: "default",
    backgroundColor: "#F8F8F8",
    paddingInline: "10px",
    borderTop: "1px solid lightgrey",
    borderBottom: "1px solid lightgrey",
    fontSize: "12px",
  },
  profileName: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "10px",
  },
  profile: {
    fontStyle: "normal !important",
    fontWeight: 400,
    fontSize: "16px !important",
    lineHeight: "20px !important",
    marginRight: "5px",
    marginTop: "3px",
    marginBottom: "-5px",
  },
  role: {
    fontStyle: "normal !important",
    fontWeight: 400,
    fontSize: "12px !important",
    lineHeight: "20px !important",
  },
  menuAvathar: {
    color: "#396f89",
    backgroundColor: "#dce8ed",
    cursor: "pointer",
    width: 35,
    height: 35,
    fontSize: "12px",
    marginBottom: "8px",
  },
  menuProfileName: {
    width: 30,
    height: 30,
    fontSize: "12px",
    fontWeight: "bold",
    marginLeft: "10px",
    color: "#396f89",
    backgroundColor: "#dce8ed",
  },
}));
