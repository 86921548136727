import React, { useEffect } from "react";
import Cookies from 'js-cookie';
import { withRouter, useHistory } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import { receiveAccessToken } from "actions/userActions";
import { useReroute } from 'pages/Utils/RerouteUtil';
import { Box } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';


const LoginOk = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userInfo, kbUser, loading, error } = useSelector((state) => state.user);
  const accessToken = Cookies.get("access_token");
  const expires = Cookies.get("expires");
  const reroute = useReroute();

  useEffect(() => {
    dispatch(receiveAccessToken(accessToken, expires))
  }, [accessToken, expires, dispatch, history]);

  useEffect(() => {
    if (userInfo?.role && kbUser?.role && !loading) {
      reroute(kbUser.role)
    }
  }, [userInfo, kbUser, reroute, loading]);

  useEffect(() => {
    if (error) {
      history.push('/login', { loginError: error })
    }
  }, [error, history])

  return (
    <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></Box>
  );
};

export default withRouter(LoginOk);
