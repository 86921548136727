export const updateItems = `mutation($model : UpdateItemsRequest!){
    updateItems(input : $model) {
        kmatID
        modVer
        itemID
        partDescription
        filterCondition
        defaultCondition
        counterCvg
        cvg
        defaultComponents
        messages
        comments
        obsoleteOn
        isManual
        isTemplate
        tabCategory
      }
  }`
