import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, batch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { createItem } from "actions/modelActions";
import { getInputProps, materialTypeSelect, getDefaultMaterialType } from "pages/DashboardHelpers";
import { ADD_ITEM } from "constants/modelConstants";
import { isSystemChoice } from "reducers/modelUtils";

const AddNewChoiceItemDialog = (props) => {
  const dispatch = useDispatch();
  const defaultMaterialType = getDefaultMaterialType(props.activeNode?.choiceID, props.activeNode?.tabCategory);
  const [formData, setFormData] = useState({ partNumber: "", valueId: "", sortString: "", partDescription: "", materialType: defaultMaterialType });

  useEffect(() => {
    checkIfDisabled();
  }, [formData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleBlur = () => {
    if (!props.activeNode.choiceID.includes("NOCHOICE_"))
      setFormData((prevData) => ({
        ...prevData, valueId: prevData.partNumber.replace(/av$|av#|e$/gi, ""),
      }));
  };

  const checkIfDisabled = useCallback(() => {
    const { partNumber, valueId, sortString, partDescription } = formData;
    let isDisabled = false;
    if (props.activeNode.choiceID.includes("NOCHOICE_S")) {
      isDisabled = !(partNumber && partDescription && sortString);
    } else if (props.activeNode.choiceID.includes("NOCHOICE_")) {
      isDisabled = !(partNumber && partDescription);
    } else {
      isDisabled = !(partDescription && partNumber && valueId);
    }
    return isDisabled;
  }, [formData, props.activeNode.choiceID]);

  const handleCreateChoiceItem = (e) => {
    e.preventDefault();
    const query = props?.dataType === "regional" ? "createRegionalItem" : "createItem";
    dispatch(createItem(query, formData, props));
  };

  return (
    <Dialog className='custom-dialog' open={props.dialogOpen} onClose={(e) => props.handleDialogClose(e)} >
      <DialogTitle >Add New Item</DialogTitle>
      <DialogContent>
        <Typography gutterBottom variant="h6">  Choice</Typography>
        <TextField
          fullWidth
          variant="outlined"
          InputProps={getInputProps()}
          value={props.activeNode?.name}
        />
        <Typography gutterBottom variant="h6"> Item Details </Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={formData.partNumber}
          placeholder="Part Number"
          autoFocus={true}
          InputProps={getInputProps()}
          onBlur={handleBlur}
          onChange={handleInputChange}
          name="partNumber"
        />
        <TextField
          fullWidth
          InputProps={getInputProps()}
          variant="outlined"
          placeholder="Value ID"
          value={formData.valueId}
          onChange={handleInputChange}
          disabled={isSystemChoice(props.activeNode?.dataType)}
          name="valueId"
        />
        {props.activeNode.choiceID.includes("NOCHOICE_S") &&
          <TextField
            fullWidth
            variant="outlined"
            InputProps={getInputProps()}
            placeholder="Sort String"
            value={formData.sortString}
            onChange={handleInputChange}
            name="sortString"
          />}
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Part Description"
          value={formData.partDescription}
          InputProps={getInputProps()}
          onChange={handleInputChange}
          name="partDescription"
        />
        <Typography gutterBottom variant="h6"> Material Type </Typography>
        <FormControl fullWidth >
          <Select
            sx={materialTypeSelect}
            value={formData.materialType}
            onChange={handleInputChange}
            fullWidth
            name="materialType"
          >
            <MenuItem value={"LF"}>LF - Hardware</MenuItem>
            <MenuItem value={"XD"}>XD - Services</MenuItem>
            <MenuItem value={"S3"}>S3 - Software</MenuItem>
            <MenuItem value={"S3_C"}>S3_C - Composite Label</MenuItem>
            <MenuItem value={"S3_S"}>S3_S - Shipping Label</MenuItem>
            <MenuItem value={"S3_L"}>S3_L - Label</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions className='dialog-actions'>
        <Button sx={{ borderRadius: "8px" }} variant="outlined" onClick={props.handleDialogClose}>
          Cancel
        </Button>
        <Button sx={{ borderRadius: "8px" }} variant="contained"disabled={checkIfDisabled()} onClick={handleCreateChoiceItem}>
         + &nbsp; Add Item
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewChoiceItemDialog;
