import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Abstraction to handle updating redux store with the values from a component's state.
 * Updates happen on unmount or save for better perfomance and less redux overhead
 * @param {any} initialState Use like you would useState
 * @param {function} reduxAction the redux action function associated with this state
 * @param {{}} params additional params to pass to the redux action
 * @returns {any, function} current state and function to update the state, same as useState. Always pass a function to update the state
 */
export const useGlobalStore = (initialState, reduxAction = null, params = {}) => {
    if (!reduxAction) {
        throw new Error("Redux action function needs to be provided to useGlobalStore");
    }

    const { saveInit, undoState } = useSelector(state => state.models)
    const [state, setState] = useState(initialState);

    const memoizedParams = useMemo(
        () => params,
        // this has to run on change to the object values only,
        // since it is recreated on every render and react uses referential equality.
        // If this causes issues for the saveInit effect,
        // consider adding the property you want to be tracked to the dependency array.
        // However adding the whole params object like this [params] would make useMemo useless in this case.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [params.choiceID]
    );
    const stateRef = useRef(state);
    const dispatch = useDispatch();

    useEffect(() => {
        if (undoState) {
            setState(() => {
                dispatch({ type: "CANCEL_STATE_CLEAR" })
                return initialState
            });
        }
    }, [undoState, setState, dispatch, initialState])

    useEffect(() => {
        setState(() => initialState)
        stateRef.current = initialState;
    }, [initialState]);

    const edited = initialState?.edited ?? false;
    useEffect(() => { 
        if (!edited) {
            setState(prevState => {
                if (prevState && prevState.edited) {
                    return ({ ...prevState, edited: false });
                }
                return prevState;
            });
        }
    }, [edited])

    const updateState = useCallback((cb) => {
        if( typeof cb === 'function') return setState(currentState => cb(currentState));
        setState(cb)
    }, [setState]);

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const dispatchImmediate = useCallback(
        (...newState) => dispatch(reduxAction(newState)),
        [reduxAction, dispatch]
    );

    useEffect(() => {
        if (saveInit) {
            dispatchImmediate(stateRef.current, memoizedParams);
        }
    }, [saveInit, dispatchImmediate, memoizedParams])

    useEffect(() => {
        return () => {
            dispatchImmediate(stateRef.current, params)
            setState(null)
            stateRef.current = null
        }
        // this has to run on mount/unmount only, don't pass dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [state, updateState];
};

export default useGlobalStore;
