import {
    REFRESH_TEMPLATES,
    CLEAR_TEMPLATES_FILTERS,
    FILTER_TEMPLATES,
} from 'constants/templatesConstants';
import { applyFilters } from 'pages/Utils/FilterUtil';

const initTemplates = {
    templates: [],
    filters: {},
    filteredTemplates: [],
    isLoading: true
}

export const templatesReducer = (state = initTemplates, { type, payload }) => {
    switch (type) {
        case REFRESH_TEMPLATES: {
            const newData = {templates: payload} || initTemplates;
            const newState = {
                ...newData,
                filters: state.filters,
                isLoading: false
            };

            const itemsToFilter = payload?.length ? payload : [];
            const filteredTemplates = applyFilters(Object.values(state.filters), itemsToFilter)

            return {
                ...newState,
                filteredTemplates
            };
        }

        case CLEAR_TEMPLATES_FILTERS: {
            return {
                ...state,
                filters: {},
                filteredTemplates: state.templates
            };
        }

        case FILTER_TEMPLATES: {
            const { name, searchText, matchFields, type } = payload;
            const filters = {
                ...state.filters,
                [name]: {
                    value: searchText,
                    type,
                    matchFields
                }
            }
            
            const filteredTemplates = applyFilters(Object.values(filters), state.templates)

            return {
                ...state,
                filters,
                filteredTemplates
            };
        }

        default: return state;
    }
}

export default templatesReducer;