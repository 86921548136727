import { REFRESH_BTPS, CLEAR_BTPS_FILTERS, FILTER_BTPS, GET_BTPS } from 'constants/btpConstants';
import { applyFilters } from 'pages/Utils/FilterUtil';

const initBtps = {
    getBtpCacheInfo: [],
    filters: {},
    filteredBtps: [],
    isLoading: true,
    getBTPStatus: []
}

export const btpReducer = (state = initBtps, { type, payload }) => {
    switch (type) {
        case REFRESH_BTPS: {
            const newData = payload || initBtps;
            const filteredBtps = applyFilters(Object.values(state.filters), newData.getBtpCacheInfo)

            return {
                ...state,
                ...newData,
                isLoading: false,
                filteredBtps
            };
        }

        case GET_BTPS: {
            const newState = {
                ...state,
                getBTPStatus: (payload && payload.getBTPStatus) || state.getBTPStatus,
                isLoading: false
            };
            return newState;
        }

        case CLEAR_BTPS_FILTERS: {
            return {
                ...state,
                filters: {},
                filteredBtps: state.getBtpCacheInfo
            };
        }

        case FILTER_BTPS: {
            const { name, searchText, matchFields, type } = payload;
            const filters = {
                ...state.filters,
                [name]: { value: searchText, type, matchFields }
            }
            const filteredBtps = applyFilters(Object.values(filters), state.getBtpCacheInfo)
            return {
                ...state,
                filters,
                filteredBtps
            };
        }

        default: return state;
    }
}

export default btpReducer;
