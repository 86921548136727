import React, { useRef, useState, useEffect, useMemo, useCallback, memo } from 'react';
import { useSelector,useDispatch } from 'react-redux';

//react-checkbox-tree
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import '../ReactCheckboxTree/ReactCheckboxTree.css'

import { getTab } from 'pages/Utils';

//Material UI icons
import AddIcon from '@mui/icons-material/Add';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import CheckboxTreeLabel from './CheckboxTreeLabel';
import CheckboxTreePopover from './CheckboxTreePopover';
import useKeypress from 'hooks/useKeypress';

function ReactCheckboxTree(props) {

    const dispatch = useDispatch();

    const syntaxCheck = useSelector((state) => state.models.syntaxCheck[getTab(props.activeTab)]);
    const newlyCreatedChoiceOrItem = useSelector((state) => state.models.newlyCreatedChoiceOrItem);
    const isResetTree = useSelector((state) => state.models.isResetTree);
    const expandActions = useSelector((state) => state.models.expandActions);
    const selectedItem = useSelector((state) => state.models.selectedItem);

    const selectedNode = useRef();
    const elementIndex = useRef(0);
    const elementsList = useRef();

    const [nodes, setNodes] = useState([]);
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [anchorEl, setAnchorEl] = useState(0);

    let nodesFromApi = {
        name: props.activeTab,
        items: props.activeDataSet ? [...Object.values(props.activeDataSet)] : [],
    }

    useEffect(() => {
        removeHighlightNode();
        setChecked([]);
        props.checked([]);
        selectedNode.current = null;
        elementIndex.current = null;
    }, [isResetTree, props.activeTab]);

    useEffect(() => {
        setExpanded(expandActions?.[props.activeTab] || []);
    }, [ props.activeTab,nodes]);

    useEffect(() => {
        let activeElementsCount = document.querySelectorAll('.active-color').length;
        let selectionId = selectedItem?.[props.activeTab] || selectedNode?.current?.choiceID || selectedNode?.current?.itemID || nodes[0]?.id;
        activeElementsCount === 0 && props.onSearch === '' ? highlightNode(selectionId) : highlightNode('');
        elementsList.current = Array.from(document.getElementsByClassName('rct-node-clickable'));
    }, [nodes, expanded])

    useEffect(() => {
        if (Object.keys(syntaxCheck).length !== 0 && nodesFromApi?.items?.length > 0) {
            nodesFromApi.items = nodesFromApi.items.map(item => {
                return getUpdatedData(item);
            })
        }
    }, [syntaxCheck, nodesFromApi.items])

    useEffect(() => {
        onSearch(props.onSearch);
    }, [syntaxCheck, props.onSearch, props.activeDataSet])

    useEffect(() => {
        onExpandCollapse(props.onExpandCollapseClick);
    }, [props.onExpandCollapseClick])

    useKeypress("ArrowUp", () => handleKeyPress(-1), nodes?.length);
    useKeypress("ArrowDown", () => handleKeyPress(1), nodes?.length);

    useEffect(() => {
        //Try to implement this with nodes as dependency
        let timer = setTimeout(() => {
            if (newlyCreatedChoiceOrItem?.choiceID) {
                let isNewPNExist = Boolean(newlyCreatedChoiceOrItem?.partNumber);

                //if new item is added, set its parent to expanded state.
                if (!expanded.includes(newlyCreatedChoiceOrItem?.choiceID))
                    setExpanded([...expanded, newlyCreatedChoiceOrItem?.choiceID]);

                let itemId = isNewPNExist ? (newlyCreatedChoiceOrItem?.partNumber + '-' + newlyCreatedChoiceOrItem.choiceID + '-' + newlyCreatedChoiceOrItem?.tabCategory).toUpperCase() : '';
                let elementId = itemId || newlyCreatedChoiceOrItem?.choiceID;
                highlightNode(elementId);
                dispatch({ type: "SELECTED_ITEM", payload: elementId });
                dispatch({ type: "REMEMBER_TREE_EXPANSION", payload: [newlyCreatedChoiceOrItem?.choiceID] });
            }
        })
        return () => clearTimeout(timer);
    }, [newlyCreatedChoiceOrItem?.choiceID, newlyCreatedChoiceOrItem.partNumber])

    //Refactor
    function getUpdatedData(data) {
        let updatedItems = data.items && Object.keys(data.items).length > 0 ? data.items : {};
        let hasChoiceError = false;
        let choiceInvalidConditions = syntaxCheck[data.choiceID];

        if (props.dataType === "model") {
            hasChoiceError = Boolean(choiceInvalidConditions)
        }

        if (data.items && Object.keys(data.items).length > 0) {
            for (const [key, child] of Object.entries(updatedItems)) {
                const hasItemError = Boolean(choiceInvalidConditions?.items[child.itemID]);
                if (hasItemError) {
                    hasChoiceError = true;
                }
                updatedItems[key] = { ...child, isError: hasItemError }
            }
        }

        data =
        {
            ...data,
            isError: hasChoiceError,
            items: updatedItems,
        };
        return data;
    };

    const focusNode = (container) => {
        container.tabIndex = 0;
        container.focus();
    }

    const handleKeyPress = useCallback((navigate) => {
        const newIndex = elementIndex.current + navigate;
        if (newIndex >= 0 && newIndex < elementsList.current.length) {
          elementIndex.current = newIndex;
          highlightNextElement(elementsList.current[newIndex]);
        }
      }, []);

    const highlightNextElement = (element) => {
        if (element !== undefined ) focusNode(element);
    }

    const convertDataToTreeNodes = (nodes) => {
        let treeNodes = [];
        nodes?.items?.map((node) => {
            let tn = convertDataToTreeNode(node);
            treeNodes.push(tn);
        })
        return treeNodes;
    }

    const convertDataToTreeNode = (node, parentNode) => {
        let count = node.items && Object.keys(node.items).length;
        // let hasParentText = Boolean(count) || Boolean(parentNode) ? '*' : ''; // appending a * sign for identifying the real child nodes
        let text = (node.choiceID || node.itemID);
        let treeNode = {
            id: node.choiceID || node.itemID,
            value: text,
            key: node.choiceID || node.itemID,
            disabled: !(Boolean(count) || Boolean(parentNode)),
            className: props.dataType === "regional" ? "hide-checkboxes" : "",
            label: <CheckboxTreeLabel node={node} count={count} onMoreIconClick={onMoreIconClick} choiceData={parentNode} announceSelection={announceSelection} currentVersion = {props.currentVersion} kmatId = {props.kmatId} dataType = {props.dataType}/>
        };
        if (count) {
            treeNode.children = []
            for (const [key, value] of Object.entries(node.items)) {
                treeNode.children.push(convertDataToTreeNode(value, node));
            }
        }
        return treeNode;
    }

    const onCheck = (value, targetNode) => {
        if (!targetNode?.disabled) {
            onClick(targetNode.value);
            setChecked(value);
            props.checked(value);
        }
    };

    const onExpand = (value) => {
        dispatch({type: "REMEMBER_TREE_EXPANSION", payload: value});
        setExpanded(value);
    };

    const onClick = (node) => {
        let foundNode;
        for (let i = 0; i < nodesFromApi.items.length; i++) {
            if (nodesFromApi.items[i].choiceID === node || nodesFromApi.items[i].choiceID === node.value) {
                foundNode = { ...nodesFromApi.items[i] }
                break;
            } else if (nodesFromApi.items[i].items && Object.keys(nodesFromApi.items[i].items).length > 0) {
                for (const [key, value] of Object.entries(nodesFromApi.items[i].items)) {
                    if (value.itemID === node || value.itemID === node.value) {
                        foundNode = { ...value }
                        break;
                    }
                }
            }
        }
        selectedNode.current = foundNode;
        announceSelection(foundNode);
    }

    function announceSelection(node) {
        highlightNode( node.choiceID || node.itemID);
        props.onSelect(node)
    }

    function onExpandCollapse(isAllExpanded = props.onExpandCollapseClick) {
        //find nodes with children and add them into the expandedArray;
        let listOfToBeExpandedNodes = [];
        if (isAllExpanded) {
            listOfToBeExpandedNodes = nodes?.map((node) => node.children ? node.value : '');
        }
        setExpanded(listOfToBeExpandedNodes);
    }

    function onSearch(value) {
        let filteredNodesFromApi = { ...nodesFromApi };     
        if (value !== '') {
            filteredNodesFromApi.items = [];
            nodesFromApi.items.forEach((node) => {
                let newNode = { ...node, items: { ...node.items } };

                let matchesParent = newNode.text?.toLowerCase().includes(value.toLowerCase());
                let matchingChildren = findMatchingChildren(newNode.items, value);

                if (matchesParent && Object.keys(newNode.items).length === 0) {
                    filteredNodesFromApi.items.push(newNode);
                } else if (Object.keys(matchingChildren).length > 0) {
                    newNode.items = matchingChildren;
                    filteredNodesFromApi.items.push(newNode);
                } else if (matchesParent) {
                    newNode.items = {};
                    filteredNodesFromApi.items.push(newNode);
                }
            });
        }
        let nodes = convertDataToTreeNodes(filteredNodesFromApi);
        setNodes(nodes);
    }

    function findMatchingChildren(items, value) {
        let matchingChildren = {};
        Object.entries(items).forEach(([key, entry]) => {
            if (entry.text?.toLowerCase().includes(value.toLowerCase())) {
                matchingChildren[key] = entry;
            }
        });
        return matchingChildren;
    }

 

    const onMoreIconClick = (e, id) => {
        selectedNode.current = nodesFromApi.items.filter((nodeFromApi) => nodeFromApi.choiceID === id || nodeFromApi.itemID === id)[0];

        // Adding the active-color class for the selected element
        announceSelection(selectedNode.current);
        setAnchorEl(e.currentTarget.closest('#parentOfMoreIcon'));
        e.stopPropagation();
    }

    const removeHighlightNode = () => {
        let activeElements = document.querySelectorAll('.active-color');
        activeElements.forEach(element => element.classList.remove('active-color'));
    }

    function highlightNode(id) {
        if (id) {
            removeHighlightNode();
            let el = document.getElementById(id)?.parentElement;
            el?.classList.add('active-color');

            // Setting the index of the selected node in the elementIndex
            let parentOfel = el?.closest('.rct-node-clickable');
            let index = elementsList.current?.findIndex((element) => element === parentOfel);
            elementIndex.current = index > -1 ? index  : elementIndex.current;
            dispatch({type: "SELECTED_ITEM", payload:id});
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <div id="checkbox-tree-view">
            <CheckboxTree
                id={'tr'}
                checked={checked}
                expanded={expanded}
                nodes={nodes}
                checkModel='all'
                onCheck={onCheck}
                onExpand={onExpand}
                showNodeIcon={false}
                showNodeTitles={true}
                nativeCheckboxes={false}
                expandOnClick={true}
                onClick={onClick}
                icons={{
                    check: <CheckBoxIcon className='checked-color' />,
                    uncheck: <CheckBoxOutlineBlankIcon className='unchecked-color' />,
                    halfCheck: <IndeterminateCheckBoxIcon className='checked-color' />,
                    expandClose: <KeyboardArrowRightIcon />,
                    expandOpen: <KeyboardArrowDownIcon />,
                    expandAll: <AddIcon />,
                    collapseAll: <RemoveIcon />,
                    parentClose: <span className="rct-icon rct-icon-parent-close" />,
                    parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                    leaf: <span className="rct-icon rct-icon-leaf" />,
                }}
            />
            <CheckboxTreePopover nodesFromApi={nodesFromApi} selectedNode={selectedNode} dataType={props.dataType} anchorEl={anchorEl} setAnchorEl={setAnchorEl} handleClose={handleClose} announceSelection={announceSelection} currentVersion = {props.currentVersion} kmatId = {props.kmatId}></CheckboxTreePopover>
        </div>
    );
}

export default memo( ReactCheckboxTree);
