import React from 'react';

/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 * @param {boolean} shouldEnable - flag to enable/disable the key press handling
 */
const useKeypress = (key, action, shouldEnable) => {
  
  React.useEffect(() => {
    const handleKeyDown = (e) => {
      if (!shouldEnable) {
        if ((e.ctrlKey || e.metaKey ) && (String.fromCharCode(e.which).toLowerCase() === key) && e.shiftKey)
          e.preventDefault();
        return;
      }

      const charCode = String.fromCharCode(e.which).toLowerCase();
      const isCtrlShiftKey = (e.ctrlKey || e.metaKey) && e.shiftKey && charCode === key;
      const isOtherKey = (key === "b" || key === "s") && (e.ctrlKey || e.metaKey) && charCode === key;
      if (isCtrlShiftKey || isOtherKey || (key === "Escape" && e.key === "Escape")) {
        e.preventDefault();
        action();
      } else if (e.ctrlKey && e.key === "ArrowUp" && key === "ArrowUp") {
        e.preventDefault();
        action(-1);
      } else if (e.ctrlKey && e.key === "ArrowDown" && key === "ArrowDown") {
        e.preventDefault();
        action(1);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => { document.removeEventListener('keydown', handleKeyDown) }
  }, [shouldEnable, key, action]);
}

export default useKeypress;